import React,{useState} from "react";
import ReactDOM from "react-dom";
import { msalConfig } from "./authconfig";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { RootProvider } from "providers/RootProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.enableAccountStorageEvents();

const Root = () => {
  const [account, setAccount] = useState(null);
  const [username,setuserName]=useState()
  msalInstance.addEventCallback((event) => {
   
     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      setAccount(account);
      setuserName(account.username)
    }
  });

  return (
    <React.StrictMode>
      <RootProvider>
        <Router>
          <App pca={msalInstance} account={account} username={username}/>
        </Router>
      </RootProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

reportWebVitals();
