import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetPivotData(decimals = 4) {
  const path = `demand_forecast/get_summary_pivot`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
      // heirarchy: ["Channel_Split"],
    })
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const xAxis = [];
  const xAxisfy = [];
  const colDef = [];
  const rowData = [];
  let numberLeast = NaN;
  let highestNumber = NaN;
  let finalNumber;
  let numberLeastfy = NaN;
  let highestNumberfy = NaN;
  let finalNumberfy;
  if (Array.isArray(data) && data.length) {
    data.forEach((dataItem) => {
      rowData.push(dataItem);
    });
  }
  if (data && Array.isArray(data) && data.length) {
    const temp = [];
    const tempfy = [];
    Object.keys(data[0]).forEach((item) => {
      if (item.startsWith("MQ-")) {
        temp.push(item);
        const sortedValue = temp.sort();
        const leastNumber = sortedValue[0];
        const firstNumber = sortedValue[sortedValue.length - 1];
        numberLeast = leastNumber.split("-")[1];
        highestNumber = firstNumber.split("-")[1];
        finalNumber = highestNumber.split("gr")[0];
      }
      if (item.startsWith("FY-")) {
        tempfy.push(item);
        const sortedValuefy = tempfy.sort();
        const leastNumberfy = sortedValuefy[0];
        const firstNumberfy = sortedValuefy[sortedValuefy.length - 1];
        numberLeastfy = leastNumberfy.split("-")[1];
        highestNumberfy = firstNumberfy.split("-")[1];
        finalNumberfy = highestNumberfy.split("gr")[0];
      }
    });

    for (let i = numberLeast; i <= finalNumber; i++) {
      ["MQ", "JQ", "SQ", "DQ"].forEach((a) => {
        xAxis.push(a + "-" + i);
      });
    }
    for (let i = numberLeastfy; i <= finalNumberfy; i++) {
      ["FY"].forEach((a) => {
        xAxisfy.push(a + "-" + i);
      });
    }
  }

  if (Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((header) => {
      if (
        header !== "index" &&
        header !== "channel" &&
        header !== "Description" &&
        header !== "Sub_Category" &&
        header !== "Brand_Variant" &&
        header !== "Modified_Brand"&&
        header !== "Channel_Split"
      ) {
        colDef.push({
          field: header,
          minWidth: 150,
          cellStyle: getStyles,
          headerTooltip: header,
          valueFormatter: (params) => getFormatValue(params, decimals),
        });
      }
    });
  }

  const series = [];
  const legend = [];
  const seriesfy = [];
  const selectedfy = {};
  const selected = {};
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      if (item["channel"].length === 1) {
        legend.push(item["channel"][0]);
        selected[`${item["channel"][0]}`] = false;
        selected[`${item["channel"][0]} gr`] = false;
        const seriesDataGr = [];
        xAxis.forEach((axisItem) => {
          seriesDataGr.push(`${item[`${axisItem}gr`] ?? 0}`);
        });
        const seriesData = [];
        xAxis.forEach((axisItem) => {
          seriesData.push(`${item[axisItem] ?? 0}`);
        });

        series.push({
          name: item["channel"][0],
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: seriesData,
        });
        series.push({
          name: `${item["channel"][0]} gr`,
          type: "line",
          smooth: true,
          data: seriesDataGr.map((f) => f.split("%")[0]),
          yAxisIndex: 1,
        });
      }
    });
  }
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      if (item["channel"].length === 1) {
        legend.push(item["channel"][0]);
        selectedfy[`${item["channel"][0]}`] = false;
        selectedfy[`${item["channel"][0]} gr`] = false;
        const seriesDataGrfy = [];
        xAxisfy.forEach((axisItem) => {
          seriesDataGrfy.push(`${item[`${axisItem}gr`] ?? 0}`);
        });

        const seriesDatafy = [];
        xAxisfy.forEach((axisItem) => {
          seriesDatafy.push(`${item[axisItem] ?? 0}`);
        });

        seriesfy.push({
          name: item["channel"][0],
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: seriesDatafy,
        });
        seriesfy.push({
          name: `${item["channel"][0]} gr`,
          type: "line",
          smooth: true,
          data: seriesDataGrfy.map((f) => f.split("%")[0]),
          yAxisIndex: 1,
        });
      }
    });
  }

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      // selected: {
      //   ...selected,
      //   Total: true,
      //   "Total gr": true,
      // },
      right: "6%",
      top: "5%,",
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: series,
  };

  const optionsfy = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      selected: {
        ...selectedfy,
        Total: true,
        "Total gr": true,
      },
      right: "6%",
      top: "5%,",
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxisfy,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: seriesfy,
  };

  return {
    isLoading,
    rowData,
    colDef,
    mutate,
    options,
    optionsfy,
  };
}
