import { useQuery } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFileUploadedList(payload) {
  const demandForecastUploadedFile = [];
  const path = "demand_forecast/get_user_data";

  const { data, refetch, error, isError } = useQuery(
    ["getsynopsis", payload],
    () => postApi(path, payload),
    { enabled: true }
  );
  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      demandForecastUploadedFile.push(item);
    });
  }
  return {
    refetch,
    demandForecastUploadedFile,
  };
}
