import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import { QueryClientProvider, QueryClient } from "react-query";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { theme } from "styles/theme";
import NotifyBar from "components/NotifyBar";
import NotifyDismiss from "components/NotifyDismiss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const RootProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <NotifyBar maxSnack={3} action={(key) => <NotifyDismiss id={key} />}>
          {children}
        </NotifyBar>
      </ThemeProvider>
    </StyledEngineProvider>
  </QueryClientProvider>
);
