import { Route, Routes, Navigate } from "react-router-dom";

import SummaryDashboard from "views/Summary/SummaryDashboard";
import DemandForecastDashboard from "views/DemandForecast/DemandForecastDashboard";
import MtSopDashboard from "views/MTS&OP/MtSopDashboard";
import OutQuarterDashboard from "views/OutQuarter/OutQuarterDashboard";
import PerformanceDashboard from "views/ForecastVersioningPerformanceSummary/PerformanceDashboard";
import FaReportDashboard from "views/FaReport/FaReportDashboard";
import AdoptionDashboard from "views/Adoption/AdoptionDashboard";
import FaLossDashboard from "views/FaLoss/FaLossDashboard";
import AccuracyDashboard from "views/Accuracy/AccuracyDashboard";
import ForecastAdjustment from "views/ForecastAdjustment/ForecastAdjustment";

import ScenarioSimulationDashboardMainPage from "views/ScenarioSimulation/components/ScenarioSimulationDashboardMainPage";

const AppRoutes = (username) => {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={<Navigate to="/demandForecast/dashboard" />}
      />

      <Route path={`/demandForecast/sopAnalysis`} element={<SummaryDashboard />} />
      <Route
        path={`/demandForecast/outQuarter`}
        element={<OutQuarterDashboard />}
      />

      <Route index path={`/mts&op`} element={<MtSopDashboard />} />
      <Route
        index
        path={`/scenarioSimulation`}
        element={<ScenarioSimulationDashboardMainPage username={username} />}
      />
      <Route
        index
        path={`/demandForecast/dashboard`}
        element={<DemandForecastDashboard />}
      />

      <Route
        index
        path={`/forecastVersioning/V1Report`}
        element={<FaReportDashboard />}
      />

      <Route
        index
        path={`/forecastVersioning/FaLossTree`}
        element={<FaLossDashboard />}
      />

      <Route
        index
        path={`/forecastVersioning/Adoption`}
        element={<AdoptionDashboard />}
      />

      <Route
        index
        path={`/forecastVersioning/PerformanceSummary`}
        element={<PerformanceDashboard />}
      />

      <Route
        index
        path={`/forecastVersioning/FaFb`}
        element={<AccuracyDashboard />}
      />
     
      <Route
        index
        path={`/forecast_adjustment`}
        element={<ForecastAdjustment />}
      />
    </Routes>
  );
};

export default AppRoutes;
