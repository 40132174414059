import React, { useEffect, useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useLocation, Link } from "react-router-dom";
import { Tabs, Tab, Box, Tooltip } from "@mui/material";
import { StyledLink, Line } from "./AppHeader.styles";
import Logo from "images/hulLogo.jpg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SignOutButton } from "ui-component/SignoutButton";
import useGetUserDetails from "services/DemandForecast/useGetUserDetails";
import { useTheme } from "@emotion/react";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import { ColorModeContext } from "App";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";

const routes = [
  {
    label: "MT S&OP",
    route: "/mts&op",
    key: "mts&op",
    enable: true,
  },
  {
    label: "Scenario Simulation",
    route: "/scenarioSimulation",
    key: "scenarioSimulation",
    enable: true,
  },
  {
    label: "Forecast Adjustment",
    route: "/forecast_adjustment",
    key: "forecast_adjustment",
    enable: true,
  } 
  
];

const AppHeader = ({ startTime }) => {
  const { pathname } = useLocation();
  const [anchorDemandForecast, setAnchorDemandForecast] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [selected, setSelected] = React.useState("");
  const open1 = Boolean(anchorEl1);

  const [anchorSales, setAnchorSales] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const { mutate } = useGetUserDetails();

  const theme = useTheme();

  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    const interval = setInterval(() => {
      mutate({
        starttime: startTime,
        // endtime: new Date().toLocaleTimeString(navigator.language, {
        //   hour: "2-digit",
        //   minute: "2-digit",
        //   hour12: false,
        // }),
        name: localStorage.getItem("username"),
        date: new Date().toLocaleDateString(),
      });
    }, 500000); // 5 minutes in milliseconds

    return () => clearInterval(interval);
  }, []);

  const handleOpenMenu = (event, anchor) => {
    if (anchor === "demandForecast") {
      setAnchorDemandForecast(event.currentTarget);
    } else if (anchor === "sales") {
      setAnchorSales(event.currentTarget);
    }
  };

  return (
    <AppBar component="nav">
      <Toolbar>
        <Box sx={{ paddingRight: 2, display: "flex" }}>
          <img src={Logo} width="130" height={45} alt="pmi-logo" />
        </Box>

        <Typography
          variant="h8"
          component="div"
          sx={{ flexGrow: 3, display: { xs: "none", sm: "block" } }}
        >
          <Link
            to="/demandForecast/dashboard"
            style={{
              color: "#FFF",
              textDecoration: "none",
              fontSize: "large",
            }}
          >
            S&OP Dashboard
          </Link>
        </Typography>

        <Button onClick={(event) => handleOpenMenu(event, "demandForecast")}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Tab
              label={"Demand Forecast"}
              sx={{
                fontSize: "12px",
                color: "#ffffff",
                textTransform: "none",
                opacity: pathname.startsWith("/demandForecast") ? 1 : 0.8,
                fontWeight: pathname.startsWith("/demandForecast")
                  ? "bold"
                  : "400",
              }}
            />
            {pathname.startsWith("/demandForecast") && <Line />}
          </Box>
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorDemandForecast}
          open={anchorDemandForecast}
          onClose={() => setAnchorDemandForecast(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <StyledLink
            to={`/demandForecast/sopAnalysis`}
            key={`/demandForecast/sopAnalysis`}
            onClick={() => setSelected("sopAnalysis")}
          >
            <MenuItem
              onClick={() => setAnchorDemandForecast(null)}
              style={{ backgroundColor: selected === `sopAnalysis` && "#ADD8E6" }}
            >
              <Typography variant="body2" color="textPrimary">
                SOP Analysis
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/demandForecast/dashboard`}
            key={`/demandForecast/dashboard`}
            onClick={() => setSelected("planning")}
          >
            <MenuItem
              onClick={() => setAnchorDemandForecast(null)}
              style={{ backgroundColor: selected === `planning` && "#ADD8E6" }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                Planning Dashboard
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/demandForecast/outQuarter`}
            key={`/demandForecast/outQuarter`}
            onClick={() => setSelected("outQuarter")}
          >
            <MenuItem
              onClick={() => setAnchorDemandForecast(null)}
              style={{
                backgroundColor: selected === `outQuarter` && "#ADD8E6",
              }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                Out Quarter
              </Typography>
            </MenuItem>
          </StyledLink>
        </Menu>
        <Button onClick={(event) => setAnchorSales(event.currentTarget)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Tab
              label={"Sales & Forecast Analytics"}
              sx={{
                fontSize: "12px",
                color: "#ffffff",
                textTransform: "none",
                opacity: pathname.startsWith("/forecastVersioning") ? 1 : 0.8,
                fontWeight: pathname.startsWith("/forecastVersioning")
                  ? "bold"
                  : "400",
              }}
            />
            {pathname.startsWith("/forecastVersioning") && <Line />}
          </Box>
        </Button>
        <Tabs value={pathname}>
          {routes.map((item) =>
            item.enable ? (
              <StyledLink to={item.route} key={item.key}>
                <Tab
                  label={item.label}
                  value={item.route}
                  sx={{
                    fontSize: "12px",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity: pathname === item.route ? 1 : 0.8,
                    fontWeight: pathname === item.route ? "bold" : "400",
                  }}
                />
                {pathname === item.route && <Line />}
              </StyledLink>
            ) : (
              <Tab
                label={item.label}
                value={item.route}
                sx={{
                  fontSize: "12px",
                  color: "#ffffff",
                  textTransform: "none",
                  opacity: pathname === item.route ? 1 : 0.8,
                  fontWeight: pathname === item.route ? "bold" : "400",
                }}
                disabled={true}
              />
            )
          )}
        </Tabs>

        <Menu
          id="basic-menu"
          anchorEl={anchorSales}
          open={anchorSales}
          onClose={() => setAnchorSales(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <StyledLink
            to={`/forecastVersioning/PerformanceSummary`}
            key={`/forecastVersioning/PerformanceSummary`}
            onClick={() => setSelected("summary")}
          >
            <MenuItem
              onClick={() => setAnchorSales(null)}
              style={{ backgroundColor: selected === `summary` && "#ADD8E6" }}
            >
              <Typography variant="body2" color="textPrimary">
                Performance Summary
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/forecastVersioning/V1Report`}
            key={`/forecastVersioning/V1Report`}
            onClick={() => setSelected("V1Report")}
          >
            <MenuItem
              onClick={() => setAnchorSales(null)}
              style={{
                backgroundColor: selected === `V1Report` && "#ADD8E6",
              }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                V1 Report
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/forecastVersioning/FaLossTree`}
            key={`/forecastVersioning/FaLossTree`}
            onClick={() => setSelected("FaLossTree")}
          >
            <MenuItem
              onClick={() => setAnchorSales(null)}
              style={{
                backgroundColor: selected === `FaLossTree` && "#ADD8E6",
              }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                FA Loss Tree
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/forecastVersioning/Adoption`}
            key={`/forecastVersioning/Adoption`}
            onClick={() => setSelected("Adoption")}
          >
            <MenuItem
              onClick={() => setAnchorSales(null)}
              style={{ backgroundColor: selected === `Adoption` && "#ADD8E6" }}
              // sx={{
              //   backgroundColor:
              //     selected === `Adoption` &&
              //     theme.palette.action.selectedOption,
              // }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                Falcon Adoption
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/forecastVersioning/FaFb`}
            key={`/forecastVersioning/FaFb`}
            onClick={() => setSelected("FaFb")}
          >
            <MenuItem
              onClick={() => setAnchorSales(null)}
              style={{
                backgroundColor: selected === `FaFb` && "#ADD8E6",
              }}
            >
              {" "}
              <Typography variant="body2" color="textPrimary">
                FA FB
              </Typography>
            </MenuItem>
          </StyledLink>
        </Menu>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <StyledLink to={`/statistics/growth`} key={`/statistics/growth`}>
            <MenuItem onClick={handleClose1}>
              <Typography variant="body2" color="textPrimary">
                Growth Analysis
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink to={`/statistics/bp`} key={`/statistics/bp`}>
            <MenuItem onClick={handleClose1}>
              {" "}
              <Typography variant="body2" color="textPrimary">
                BP Analysis
              </Typography>
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/statistics/businessGroup`}
            key={`/statistics/businessGroup`}
          >
            <MenuItem onClick={handleClose1}>
              {" "}
              <Typography variant="body2" color="textPrimary">
                Business Group Analysis
              </Typography>
            </MenuItem>
          </StyledLink>
        </Menu>

        {/* <ButtonGroup
            setValue={colorMode.toggleColorMode}
            value={theme.palette.mode==="dark"?"dark":"light"}
            options={[
              { label: <Brightness7Icon style={{display:"flex",color:"#fff"}} fontSize="small"/>, value: "light" },
              { label: <Brightness4Icon style={{display:"flex"}} fontSize="small"/>, value: "dark" },
            ]}
            primaryColor={"#31b2dc"}
            // primaryColor={"#01CFFE"}
            // primaryColor={"#2d2d2d"}
            width={55}
            height={30}
        /> */}

        <Tooltip
          title={
            theme.palette.mode === "dark"
              ? "Switch to light mode"
              : "Switch to dark mode"
          }
        >
          <IconButton
            sx={{ ml: 1 }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Tooltip>

        <SignOutButton />
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
