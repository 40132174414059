import { useMutation } from "react-query";
import { useEffect } from "react";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";

export default function useGetFeaturesData() {
  const path = "demand_forecast/download_Feature_importance_table";

  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

const featuresData = data.map(item => {
    const newVariable = item.variable.replace(",", ".");
    return {
      ...item,
      variable: newVariable
    };
  });

  return {
    featuresData,
    featuresDataLoading: isLoading,
    featuresDataMutate: mutate
  };
}
