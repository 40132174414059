import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function usePendingBasePackTable() {
  const path = "/scenario_simulation/getScenarioStatus";
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();
  let pendingDetails = [];
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  if (data && Array.isArray(data) && data.length) {
    pendingDetails = data;
  }
  return {
    IsLoadingCreate: isLoading,
    mutatePendingScenarioTable: mutate,
    pendingDetails,
    isError,
  };
}
