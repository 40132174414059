/* eslint-disable react-hooks/exhaustive-deps */
import useGetDemandForecast from "services/DemandForecast/useGetDemandForecast";
import { color } from "../mappings";
import { alpha } from "@mui/system";
import { useEffect } from "react";
import { useCallback } from "react";
import { useTheme } from "@emotion/react";

const lineStyle = {
  shadowColor: "#dfe8ed",
  shadowBlur: 1,
  shadowOffsetX: 0,
  shadowOffsetY: 6,
};

export default function useDemandForecast(
  values,
  timePeriod,
  primary,
  secondary,
  enableAPIs,
  decimals
) {
  const { data, xAxis, demandForecastLoading, yAxis, mutate, invalidateData } =
    useGetDemandForecast({
      key: timePeriod,
    });

  const theme = useTheme();

  const onApply = useCallback(
    (primary, secondary) => {
      if (values.fileName.length !== 0) {
        mutate({
          filename: values.fileName,
          key: timePeriod,
          data_query: [
            ...primary.map((f) => f.value),
            ...secondary.map((f) => f.value),
          ],
          ...values.filters,
        });
      }
    },
    [enableAPIs, mutate, timePeriod]
  );

  useEffect(() => {
    onApply(primary, secondary);
  }, [onApply]);

  const series = [];
  const legend = [];

  primary.forEach(({ label, value }, index) => {
    legend.push(label);
    series.push({
      name: label,
      type: "line",
      color: color[index],
      lineStyle: {
        ...lineStyle,
        shadowColor: alpha(color[index], 0.2),
      },
      data: yAxis[value],
      smooth: true,
    });
  });

  secondary.forEach(({ label, value }, index) => {
    legend.push(label);
    series.push({
      name: label,
      type: "line",
      color: color[5 + index],
      lineStyle: {
        ...lineStyle,
        shadowColor: alpha(color[5 + index], 0.2),
      },
      data: yAxis[value],
      yAxisIndex: 1,
      smooth: true,
    });
  });

  const accuracyOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      // valueFormatter: (value) => value.toFixed(decimals),
    },
    legend: {
      data: legend,
      right: "6%",
      top: "5%,",
      textStyle: {
        color: theme.palette.text.primary,
      },
    },
    toolbox: {
      show: true,
      itemSize: 20,
      feature: {
        magicType: { type: ["line", "bar"] },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: series,
  };

  return {
    accuracyOption,
    data,
    demandForecastLoading,
    onApply,
    invalidateData,
  };
}
