import { getFormatValue, getStyles } from "utils/constants";
const createColumn = (fieldName, headerName, decimals) => ({
  field: fieldName,
  headerName: headerName,
  ...columnsName(decimals),
});
const createColumn_LKLGrowth = (
  fieldName_LKL,
  headerName_LKL,
  decimals_LKL
) => ({
  field: fieldName_LKL,
  headerName: headerName_LKL,
  ...columnsName(decimals_LKL),
});
const createColumn_L2L = (L2L_fieldName, L2L_headerName, L2L_decimals) => ({
  field: L2L_fieldName,
  headerName: L2L_headerName,
  ...columnsName(L2L_decimals),
});
const L3Y = (Y3_fieldName, Y3_headerName, Y3_decimals) => ({
  field: Y3_fieldName,
  headerName: Y3_headerName,
  ...columnsName(Y3_decimals),
});
const Y4L = (LY4_fieldName, LY4_headerName, LY4_decimals) => ({
  field: LY4_fieldName,
  headerName: LY4_headerName,
  ...columnsName(LY4_decimals),
});
const columnsName = (decimals) => ({
  width: 140,
  cellStyle: getStyles,
  valueFormatter: (params) => `${getFormatValue(params, decimals)}`,
});
export const colDefDefSeasonality = (decimals, season, filename) => {
  if (filename === "ICD"){
    return [
      { headerName: "Year", field: "index", width: 140, pinned: true },
      {
        headerName: "Sales/Plan",
        children: [
          createColumn("MQ", "MQ", decimals),
          createColumn("JQ", "JQ", decimals),
          createColumn("SQ", "SQ", decimals),
          createColumn("DQ", "DQ", decimals),
        ],
      },
      {
        headerName: "Season Plan",
        children: [
          createColumn(season === "Summer" ? "FH" : "SH", "FY", decimals),
          createColumn("Season", "Season", decimals),
          createColumn(
            season === "Summer" ? "JQ_CAGR_2" : "DQ_CAGR_2",
            season === "Summer" ? "JQ" : "DQ",
            decimals
          ),
          season === "Winter" ? createColumn("SQ_season", "SQ", decimals): createColumn("MQ_season", "MQ", decimals),
        ],
      },
      {
        headerName: "LKL Growth",
        children: [
          createColumn_LKLGrowth("FYLKL", "FY", decimals + "%"),
          createColumn_LKLGrowth("SeasonLKL", "Season", decimals + "%"),
          createColumn_LKLGrowth(
            season === "Summer" ? "JQ_season" : "DQ_season",
            season === "Summer" ? "JQ" : "DQ",
            decimals + "%"
          ),
          season === "Winter" ? createColumn_LKLGrowth("SQLKL", "SQ", decimals + "%"):createColumn_LKLGrowth("MQLKL", "MQ", decimals + "%"),
        ],
      },
    ];
  }
  else{ return [
    { headerName: "Year", field: "index", width: 140, pinned: true },
    {
      headerName: "Sales/Plan",
      children: [
        createColumn("MQ", "MQ", decimals),
        createColumn("JQ", "JQ", decimals),
        createColumn("SQ", "SQ", decimals),
        createColumn("DQ", "DQ", decimals),
      ],
    },
    {
      headerName: "Season Plan",
      children: [
        createColumn("FY", "FY", decimals),
        createColumn("Season", "Season", decimals),
        createColumn(
          season === "Summer" ? "JQ_CAGR_2" : "DQ_CAGR_2",
          season === "Summer" ? "JQ" : "DQ",
          decimals
        ),
        createColumn("SQ_season", "SQ", decimals),
        createColumn("MQ_season", "MQ", decimals),
      ],
    },
    {
      headerName: "LKL Growth",
      children: [
        createColumn_LKLGrowth("FYLKL", "FY", decimals + "%"),
        createColumn_LKLGrowth("SeasonLKL", "Season", decimals + "%"),
        createColumn_LKLGrowth(
          season === "Summer" ? "MQ_season" : "DQ_season",
          season === "Summer" ? "JQ" : "DQ",
          decimals + "%"
        ),
        createColumn_LKLGrowth("SQLKL", "SQ", decimals + "%"),
        createColumn_LKLGrowth("MQLKL", "MQ", decimals + "%"),
      ],
    },
  ];
}
};
export const colDefSeasonality2CaGR = (decimals, season, filename) => {
  if(filename==="ICD"){
    return [
      { headerName: "Year", field: "index", width: 100, pinned: true },
      {
        headerName: "L2Y CAGR in %",
        children: [
          createColumn_L2L("2Year_CAGR", "FY", decimals + "%"),
          createColumn_L2L("CAGR_2", "Season", decimals + "%"),
          createColumn_L2L(
            season === "Summer" ? "JQ_CAGR_2" : "DQ_CAGR_2",
            season === "Summer" ? "JQ" : "DQ",
            decimals + "%"
          ),
          createColumn_L2L(season==="Summer"?"MQ_CAGR_2":"SQ_CAGR_2", season==="Summer"?"MQ":"SQ", decimals + "%"),
        ],
      },
    ];
  }else{
  return [
    { headerName: "Year", field: "index", width: 100, pinned: true },
    {
      headerName: "L2Y CAGR in %",
      children: [
        createColumn_L2L("2Year_CAGR", "FY", decimals + "%"),
        createColumn_L2L("CAGR_2", "Season", decimals + "%"),
        createColumn_L2L(
          season === "Summer" ? "JQ_CAGR_2" : "DQ_CAGR_2",
          season === "Summer" ? "JQ" : "DQ",
          decimals + "%"
        ),
        createColumn_L2L("SQ_CAGR_2", "SQ", decimals + "%"),
        createColumn_L2L("MQ_CAGR_2", "MQ", decimals + "%"),
      ],
    },
  ];
}
};
export const colDefSeasonality3CaGR = (decimals, season,filename) => {
  if(filename==="ICD"){
    return [
      { headerName: "Year", field: "index", width: 100, pinned: true },
      {
        headerName: "L3Y CAGR in %",
        children: [
          L3Y("3Year_CAGR", "FY", decimals + "%"),
          L3Y("CAGR_3", "Season", decimals + "%"),
          L3Y(
            season === "Summer" ? "JQ_CAGR_3" : "DQ_CAGR_3",
            season === "Summer" ? "JQ" : "DQ",
            decimals + "%"
          ),
          L3Y(season==="Summer"?"MQ_CAGR_3":"SQ_CAGR_3", season==="Summer"?"MQ":"SQ", decimals + "%"),
        ],
      },
    ];
  }else{
  return [
    { headerName: "Year", field: "index", width: 100, pinned: true },
    {
      headerName: "L3Y CAGR in %",
      children: [
        L3Y("3Year_CAGR", "FY", decimals + "%"),
        L3Y("CAGR_3", "Season", decimals + "%"),
        L3Y(
          season === "Summer" ? "JQ_CAGR_3" : "DQ_CAGR_3",
          season === "Summer" ? "JQ" : "DQ",
          decimals + "%"
        ),
        L3Y("SQ_CAGR_3", "SQ", decimals + "%"),
        L3Y("MQ_CAGR_3", "MQ", decimals + "%"),
      ],
    },
  ];
}
};
export const colDefSeasonality4CaGR = (decimals, season,filename) => {
  if(filename==="ICD"){
    return [
      { headerName: "Year", field: "index", width: 100, pinned: true },
      {
        headerName: "L4Y CAGR in %",
        children: [
          Y4L("4Year_CAGR", "FY", decimals + "%"),
          Y4L("CAGR_4", "Season", decimals + "%"),
          Y4L(
            season === "Summer" ? "JQ_CAGR_4" : "DQ_CAGR_4",
            season === "Summer" ? "JQ" : "DQ",
            decimals + "%"
          ),
          Y4L(season==="Summer"?"MQ_CAGR_4":"SQ_CAGR_4", season==="Summer"?"MQ":"SQ", decimals + "%"),
        ],
      },
    ];
  }else{
  return [
    { headerName: "Year", field: "index", width: 100, pinned: true },
    {
      headerName: "L4Y CAGR in %",
      children: [
        Y4L("4Year_CAGR", "FY", decimals + "%"),
        Y4L("CAGR_4", "Season", decimals + "%"),
        Y4L(
          season === "Summer" ? "JQ_CAGR_4" : "DQ_CAGR_4",
          season === "Summer" ? "JQ" : "DQ",
          decimals + "%"
        ),
        Y4L("SQ_CAGR_4", "SQ", decimals + "%"),
        Y4L("MQ_CAGR_4", "MQ", decimals + "%"),
      ],
    },
  ];
}
};
