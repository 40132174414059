/* eslint-disable react-hooks/exhaustive-deps */
import { Field, useFormikContext } from "formik";
import { Grid, Typography } from "@mui/material";
import { Title } from "components/NavBar/NavBar.styles";
import { Stack } from "@mui/system";
import { filterData } from "../../formData";
import useGetSummaryFilters from "services/DemandForecast/useGetSummaryFilters";

import SelectField from "components/form-fields/SelectField";
import React, { useEffect } from "react";
import useGetSnopTableNames from "services/DemandForecast/useGetSnopTableNames";
import NavBar from "components/NavBar";
import { useTheme } from "@emotion/react";
import AutocompleteField from "components/form-fields/AutocompleteField";
import useGetSavedName from "services/saveRecordDetails/useGetSavedName";

const SummaryFilter = ({ onApply, enableAPIs, onReset, onSave,setEnableAPIs }) => {
  const { values, setFieldValue } = useFormikContext();
  const { snopTables } = useGetSnopTableNames();
  const { data, mutate, isLoading } = useGetSummaryFilters();
  const { names, mutateSave } = useGetSavedName();
  const theme = useTheme()

  useEffect(() => {
    mutateSave({
      username: localStorage.getItem("username"),
      pagename: "channel_brand",
    });
  }, [enableAPIs, mutateSave]);
 
  useEffect(() => {
    if ( values.filename) {
    mutate({
      category: values.filename,
      filters: values.filters,
    });
  }
    setEnableAPIs(false);
  }, [values.filename, mutate, enableAPIs]);

  useEffect(() => {
    if (values.filename) {
      setFieldValue("summaryFilterData", filterData);
    }
  }, [values.filename]);

  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      onSave={onSave}
      filterData={values.summaryFilterData ?? []}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"savedName"}
              label={"Saved Name"}
              options={names ?? []}
              fullWidth
              component={AutocompleteField}
            />
            <Typography sx={{ textAlign: "center", width:"inherit", color:theme.palette.text.primary }}>Or</Typography>
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container item flex={1}>
            <Field
              name={"filename"}
              label={"File Name"}
              component={SelectField}
              options={snopTables ?? []}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container item flex={1}>
            <Field
              name="nm"
              label={"Quarter"}
              component={SelectField}
              options={["MQ", "JQ", "SQ", "DQ"]}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container item flex={1}>
            <Field
              name="ratio"
              label={"Ratio"}
              component={SelectField}
              options={["Prev_Qtr", "Skip_Qtr", "Seq_Prev_Qtr"]}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};

export default SummaryFilter;
