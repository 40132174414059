import { Title } from "components/NavBar/NavBar.styles";
import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Field, useFormikContext } from "formik";

import SelectField from "components/form-fields/SelectField";
import { filtersData } from "./formData";
import useGetOutQuarterFilter from "services/outQuarter/outQuarterFilter";

import NavBar from "components/NavBar";
import useGetOutquarterFilename from "services/outQuarter/useGetFileName";

const OutQuarterFilter = ({
  onApply,
  enableAPIs,
  onReset,
  onSave,
  setEnableAPIs,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { data, isLoading, mutate } = useGetOutQuarterFilter();

  const { fileName } = useGetOutquarterFilename();
  useEffect(() => {
    if (values.filename) {
      // if (enableAPIs) {
      mutate({
        category: values.filename, //make it has filename
        filters: values.filters,
      });
    }
    setEnableAPIs(false);
  }, [enableAPIs, mutate, values.filename]);

  useEffect(() => {
    if(values.filename){
      setFieldValue("filterData",filtersData);
    }
  }, [values.filename]);

  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      onSave={onSave}
      filterData={values.filterData ?? []}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"filename"}
              label={"File Name"}
              component={SelectField}
              options={fileName}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};
export default OutQuarterFilter;
