import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Card,
  Button,
  CardContent,
  Typography,
} from "@mui/material";

import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import useGetBPandCluster from "../hooks/useGetBPandCluster";
import { Field, useFormikContext } from "formik";
import "./StepTwo.css";
import Skeleton from "@mui/material/Skeleton";

export default function StepTwo() {
  const {
    mutateGetBPandCluster,
    bpDetails,
    clusterDetails,
    customerDetails,
    isSuccess,
  } = useGetBPandCluster();
  const { values, setFieldValue } = useFormikContext();


  const years = Array.from(
    { length: 2064 - 2008 + 1 },
    (_, index) => 2008 + index
  );
  const toYears = Array.from(
    { length: 2064 - values.startDate + 1 },
    (_, index) => values.startDate + index
  );
  let tdpMonth = Array.from({ length: 36 }, (_, index) => {
    const month = index + 1;
    return month < 10 ? `0${month}` : `${month}`;
  });  
  useEffect(() => {
    mutateGetBPandCluster({
      category_name: values.createscenarioCategoryName,
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Scenario Name: {values.createscenarioName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Category Name: {values.createscenarioCategoryName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Features: {values.features.join(", ")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{ maxWidth: 750, maxHeight: 450, mt: 1, overflowY: "scroll" }}
        >
          <CardContent>
            <Grid
              sx={{ width: 700 }}
              container
              spacing={2}
              justifyItems="center"
            >
              <Grid item md={12}>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  <Typography variant="body2">Select Basepack</Typography>
                </Grid>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  {isSuccess ? (
                    <Field
                      label="Basepack Description"
                      multiple
                      fullWidth
                      name="bpOptions"
                      options={bpDetails}
                      noBorders={false}
                      variant="outlined"
                      component={MultiSelectDropdownF}
                    />
                  ) : (
                    <Skeleton height={60} />
                  )}
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  <Typography variant="body2">Select Cluster</Typography>
                </Grid>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  {isSuccess ? (
                    <Field
                      label="Cluster"
                      multiple
                      fullWidth
                      name="cluster"
                      options={clusterDetails}
                      noBorders={false}
                      variant="outlined"
                      component={MultiSelectDropdownF}
                      // displayDropdown={optionsLoading}
                    />
                  ) : (
                    <Skeleton height={60} />
                  )}
                </Grid>
              </Grid>
              {values?.createscenarioCategoryName
                ?.split("_")[0]
                .includes("MT") && (
                <Grid item md={12}>
                  <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                    <Typography variant="body2">
                      Select Anaplan Customer
                    </Typography>
                  </Grid>
                  <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                    {isSuccess ? (
                      <Field
                        label="Anaplan Customer"
                        multiple
                        fullWidth
                        name="anaplan customer"
                        options={customerDetails}
                        noBorders={false}
                        variant="outlined"
                        component={MultiSelectDropdownF}
                        // displayDropdown={optionsLoading}
                      />
                    ) : (
                      <Skeleton height={60} />
                    )}
                  </Grid>
                </Grid>
              )}
              {/* <Grid container direction="column" spacing={2}> */}
              <Grid item md={12}>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  <Typography variant="body2">Select TDP year from</Typography>
                </Grid>
                <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                  {isSuccess ? (
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <select
                          value={values.startDate}
                          onChange={(e) => {
                            setFieldValue(
                              "startDate",
                              parseInt(e.target.value)
                            );
                          }}
                          className="year-select"
                          placeholder="Select TDP year"
                        >
                          <option value="" disabled selected>
                            Select TDP year
                          </option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Grid>
                      <Grid item md={6}>
                        <select
                          value={values.startTdp}
                          onChange={(e) => {
                            setFieldValue("startTdp", e.target.value);
                          }}
                          className="year-select"
                        >
                          <option value="" disabled selected>
                            Select TDP month
                          </option>
                          {tdpMonth.map((month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </Grid>
                    </Grid>
                  ) : (
                    <Skeleton height={60} />
                  )}
                </Grid>

                <Grid item md={12}>
                  <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                    <Typography variant="body2">Select TDP year to</Typography>
                  </Grid>
                  <Grid item md={12} sx={{ mt: 2, ml: 1 }}>
                    {isSuccess ? (
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <select
                            value={values.endDate}
                            onChange={(e) => {
                              setFieldValue(
                                "endDate",
                                parseInt(e.target.value)
                              );
                            }}
                            className="year-select"
                          >
                            <option value="" disabled selected>
                            Select TDP year
                          </option>
                            {toYears.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid item md={6}>
                          <select
                            value={values.endTdp}
                            onChange={(e) => {
                              setFieldValue("endTdp", e.target.value);
                            }}
                            className="year-select"
                          >
                            <option value="" disabled selected>
                            Select TDP month
                          </option>
                            {tdpMonth.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </Grid>
                      </Grid>
                    ) : (
                      <Skeleton height={60} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
}
