import React from "react";
import { Typography, Grid } from "@mui/material";
import {
  LoginSideWrapper,
  LoginWrapper,
  LoginFormWrapper,
} from "./Login.style";
import { SignInButton } from "ui-component/SignInButton";
import images from "images/login-image1.jpg";
export default function Login(props) {
  return (
    <LoginWrapper>
      <LoginSideWrapper>
        <img
          src={images}
          style={{ width: "100%", height: "100%" }}
          alt="login-page"
        />
      </LoginSideWrapper>
      <LoginFormWrapper>
        <Grid container sx={{ width: "60%" }}>
          <SignInButton />
        </Grid>
      </LoginFormWrapper>
    </LoginWrapper>
  );
}
