import MUIAutocomplete from "@mui/material/Autocomplete";
import { Checkbox,TextField,Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTheme } from "@emotion/react";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Autocomplete({ label, ...rest }) {
  const { options, size = "small", limitTags = 2 } = rest;
  const theme = useTheme()

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        sx={{ marginRight: 1,
        '&.Mui-checked':{
          color:theme.palette.button.text
        } }}
        checked={selected}
      />
      <Tooltip title={option?.label}>
        <div>
          {option?.label}
        </div>
      </Tooltip>
    </li>
  );

  const isOptionEqualToValue = (option, value) => option.value === value.value;

  return (
    <MUIAutocomplete
      options={options}
      size={size}
      limitTags={limitTags}
      disableCloseOnSelect
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      {...rest}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
