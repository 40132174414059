import { Box } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'

export default function Loader() {
  const theme = useTheme()
  return (
    <Box   style={{ backgroundColor: theme.palette.background.paper, height:"400px",width:"100%", }}>
    <h4 style={{textAlign:"center",padding: "70px 0", color:theme.palette.text.primary}}>Loading...</h4>
    </Box>
  )
}
