/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import {
  Grid,
  Stack,
  Typography,
  Checkbox,
  TextField,
  Tooltip,
} from "@mui/material";
import { Title } from "components/NavBar/NavBar.styles";
import {
  ecom_filter,
  ecom_sm_filter,
  gt_filter,
  gt_filter_Segmentation,
  mt_filter,
  initialValues,
  ecom_filter_2,
  ecom_filter_Depot,
  gt_filter_initial,
  mt_filter_initial,
  gt_filter_initial_segemntation,
  ecom,
} from "./formData";
import useGetFileName from "services/DemandForecast/useGetFileName";
import useGetDemandForecastFilter from "services/DemandForecast/useGetDemandForecastFilter";
import useGetSavedName from "services/saveRecordDetails/useGetSavedName";
import { useTheme } from "@emotion/react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NavBar from "components/NavBar";
import AutocompleteField from "components/form-fields/AutocompleteField";
import Autocomplete from "@mui/material/Autocomplete";

const DemandForecastFilter = ({ onApply, enableAPIs, onReset, onSave }) => {
  const [channelFilter, setChannelFilter] = useState([]);
  const { fileName } = useGetFileName();
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const { data, isLoading, mutate } = useGetDemandForecastFilter();
  const { names, mutateSave } = useGetSavedName();

  useEffect(() => {
    if (values.fileName) {
      mutate({
        filename: values.fileName,
        filters: values.filters,
      });
    }
  }, [mutate, values.fileName]);

  useEffect(() => {
    mutateSave({
      username: localStorage.getItem("username"),
      pagename: "demand forecast",
    });
  }, [mutateSave]);

  useEffect(() => {
    if (values.fileName === "GT_Personal_Wash"|| values.fileName === "GT_Haircare" || values.fileName === "GT_Oral" || values.fileName === "GT_Coffee" || values.fileName === "GT_HHC"||values.fileName === "GT_CPD_Pop_Food"||values.fileName === "GT_Tea"||values.fileName === "GT_MFW"||values.fileName === "GT_PFW") {
      setChannelFilter(gt_filter_Segmentation);
    } else if (
      values.fileName &&
      (values.fileName.includes("GT") || values.fileName.includes("OH"))
    ) {
      setChannelFilter(gt_filter);
    } else if (values.fileName && values.fileName.includes("MT")) {
      setChannelFilter(mt_filter);
    } else if (values.fileName && values.fileName.includes("Ecom")) {
      if (values.fileName.includes("Cluster")) {
        setChannelFilter(ecom_filter);
      } else {
        setChannelFilter(ecom_filter_Depot);
      }
    }
  }, [values.fileName]);

  const handleChange = (event, value) => {
    if (value) {
      setFieldValue("fileNameChanged", "changed");
      setFieldValue("fileName", value.value);

      if (value.value === "GT_Personal_Wash") {
        setFieldValue("timePeriod", "QTR_BM");
        setFieldValue("filters", gt_filter_initial_segemntation);
      } else if (
        value.value &&
        (value.value.startsWith("GT") || value.value.startsWith("OH"))
      ) {
        setFieldValue("timePeriod", "QTR_BM");
        setFieldValue("filters", gt_filter_initial);
      } else if (value.value && value.value.startsWith("MT")) {
        setFieldValue("timePeriod", "MOC");
        setFieldValue("filters", mt_filter_initial);
      } else if (value.value && value.value.startsWith("Ecom")) {
        setFieldValue("timePeriod", "BM");
        setFieldValue("filters", ecom);
      }
    }
  };

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        sx={{ marginRight: 1, "&.Mui-checked": { color: "#2036C7" } }}
        checked={selected}
      />
      <Tooltip title={option?.label}>
        <div>{option?.label}</div>
      </Tooltip>
    </li>
  );

  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      onSave={onSave}
      filterData={channelFilter ?? []}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"savedName"}
              label={"Saved Name"}
              options={names}
              isOptionEqualToValue={(option, value) => option.value === value}
              fullWidth
              component={AutocompleteField}
            />
            <Typography
              sx={{
                textAlign: "center",
                width: "inherit",
                color: theme.palette.text.primary,
              }}
            >
              Or
            </Typography>
            <Autocomplete
              value={fileName.find((f) => f.value === values.fileName) || null}
              onChange={handleChange}
              options={fileName}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderOption={renderOption}
              style={{ width: 500 }}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="File Name"
                  placeholder="File Name"
                />
              )}
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};

export default DemandForecastFilter;
