import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import AlarmIcon from "@mui/icons-material/Alarm";
import {
  CategoryOutlined,
  LocalGroceryStore,
  Public,
} from "@mui/icons-material";
import { getFormatValue, getStyles } from "utils/constants";

export const initialValues = {
  filters: {
    BP: [],
    Cluster: [],
    Channel: [],
    Basepack: [],
    Category: [],
    Time: [],
  },
  filename: "",
  mode: "4.QTR_BM_National",
  openDrawer: true,
};

export const filtersData = [
  {
    title: "Product",
    icon: CategoryOutlined,
    filters: [
      {
        label: "BP",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "BP Description",
        name: "Basepack",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: Public,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStore,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Time",
        name: "Time",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const colDef = (decimal = 4) => [
  {
    headerName: "Level of Forecast",
    field: "Level of Forecast",
    minWidth: 100,
    cellStyle: getStyles,
    headerTooltip: "QGP",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: "Category",
    minWidth: 120,
    cellStyle: getStyles,
    headerTooltip: "Category",
  },
  {
    headerName: "BP Code",
    field: "BP",
    minWidth: 70,
    cellStyle: getStyles,
  },
  {
    field: "Basepack",
    minWidth: 310,
    cellStyle: getStyles,
    headerTooltip: "BP Description",
  },
  {
    field: "Cluster",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Cluster",
  },
  {
    field: "Channel",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Channel",
  },
  {
    field: "Key",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Key",
  },
  {
    field: "Time",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Time",
  },
  {
    field: "Key_Match",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Key_Match",
  },
  {
    field: "Forecast at given level",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Forecast at given level",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "Planner_Update_Forecast_RPM",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Planner_Update_Forecast_RPM",
    editable: true,
    multiple: true,
  },
  {
    field: "HUL Forecast",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "HUL Forecast",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
];

export const outputColdef = (decimal, mode) => [
  {
    headerName: "QTR",
    field: "QTR",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "QTR",
    hide: mode === "1.Book_Month_Cluster" || mode === "3.Book_Month_National",
  },
  {
    headerName: "Book_Month",
    field: "Book_Month",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Book_Month",
    hide: mode === "4.QTR_BM_National" || mode === "2.QTR_BM_Cluster",
  },
  {
    field: "Category",
    minWidth: 100,
    cellStyle: getStyles,
    headerTooltip: "Category",
  },
  {
    field: "Cluster",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Cluster",
  },
  {
    field: "Channel",
    minWidth: 100,
    cellStyle: getStyles,
    headerTooltip: "Channel",
  },
  {
    field: "TDP",
    minWidth: 100,
    cellStyle: getStyles,
    headerTooltip: "TDP",
  },
  {
    field: "ACC Forecast in RPM",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "ACC Forecast in RPM",
  },
  {
    field: "Planner Update Forecast RPM",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Planner Update Forecast RPM",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
];
