import React, { useState } from "react";
import { Grid, Button, Typography, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Section from "components/Section";
import { useEffect } from "react";
import useGetAnalysisDetails from "services/sceanrioSimulation/useGetDisplayScenarioDetails";
import DataGridTable from "components/DataGridTable/DataGridTable";
import Loader from "components/Loader";
import DeleteSimulation from "./DeleteSimulation";
import CustomButton from "./CustomButton";
import { Field, useFormikContext } from "formik";

export default function History(props) {
  const userName = localStorage.getItem("username");
  // const userName = "Shruti.Naik@unilever.com";
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setStateRefresh] = useState(true);
  const { values, setFieldValue } = useFormikContext();
  const [gridApi, setGridApi] = useState(null);

  const { data, mutate, isLoading } = useGetAnalysisDetails(userName);

  useEffect(() => {
    mutate();
  }, [mutate, refresh]);

  const colDef = [
    {
      field: "scenario_name",
      headerName: "Scenario Name",
      sortable: true,
    },

    {
      headerName: "Channel",
      field: "channel",
      sortable: true,
    },
    // { headerName: "Filename", field: "filename", sortable: true },
    {
      headerName: "Creation Date",
      field: "creation_date",
      sortable: true,
    },
    { headerName: "Status", field: "status", sortable: true },
    { headerName: "Action", field: "action", cellRenderer: CustomButton },
  ];

  return (
    <Grid container spacing={2}>
      <ArrowBackIcon
        onClick={() => {
          setFieldValue("pageDetails", "dashboard");
          setFieldValue("historykeys", []);
        }}
      />
      <Grid item md={12}>
        <Button
          variant="contained"
          onClick={() => mutate()}
          sx={{ width: "120px", ml: 2 }}
        >
          Refresh
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpenDelete(true)}
          sx={{ width: "120px", ml: 2 }}
        >
          Delete
        </Button>
      </Grid>
      <Grid item md={12}>
        <Section title="">
          {isLoading === true ? (
            <Loader />
          ) : (
            <React.Fragment>
              {data.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2" gutterBottom>
                    Please create a scenario to view the details
                  </Typography>
                </div>
              ) : (
                <DataGridTable
                  height="400px"
                  onGridReady={(e) => {
                    setGridApi(e.api);
                    e.api.sizeColumnsToFit();
                  }}
                  defaultColDef={{
                    cellStyle: () => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                  }}
                  suppressRowTransform={true}
                  columnDefs={colDef}
                  rowData={data}
                  borders={false}
                  isScenarioSimulation
                />
              )}
            </React.Fragment>
          )}
        </Section>
      </Grid>
      <DeleteSimulation
        open={openDelete}
        setOpen={setOpenDelete}
        refetch={mutate}
        simulations={data}
        colDef={colDef}
      />
    </Grid>
  );
}
