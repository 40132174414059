import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFeaturesList() {
  const path = "/demand_forecast/get_features_list";

  const { data, isLoading, isError, error } = useQuery(
    ["getfeaturelist"],
    () => getApi(path),
    {
      enabled: true,
    }
  );

  var featureName = [];
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  if (data) {
    featureName = Object.values(data).flat();
  }

  console.log({ featureName });

  return {
    featureName,
    featuresLoading: isLoading,
    data,
    isError,
    error,
  };
}
