import React from "react";
import { SnackbarProvider } from "notistack";
import ErrorIcon from "@mui/icons-material/Error";

const customIconVariant = {
  error: <ErrorIcon fontSize="small" color="error" />,
};

const NotifyBar = (props) => {
  const { maxSnack, children, action } = props;
  return (
    <SnackbarProvider
      maxSnack={maxSnack}
      action={action}
      iconVariant={customIconVariant}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotifyBar;
