import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const StyledAutoSelect = styled(Autocomplete)`
  ::before,
  ::after {
    border: none !important;
  }
`;

export const StyledTextField = styled(TextField)`
  ::before,
  ::after {
    border: none !important;
  }
`;

const Tags = ({ value }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {value.length} selected
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List sx={{ padding: 2 }}>
          {value.map((f) => (
            <ListItem key={f} disablePadding>
              <ListItemText primary={f} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default function MultiSelectDropdown({
  options = [],
  label,
  displayDropdown,
  field = {},
  form = {},
  noBorders = true,
  variant = "standard",
  ...rest
}) {
  const { name, value = [] } = field;
  const { setFieldTouched, setFieldValue } = form;
  const [opened, setOpened] = useState(false);
  const allSelected = options?.length === value?.length;
  const filter = createFilterOptions();

  const handleChange = (_, values, reason) => {
    if (setFieldTouched && setFieldValue) {
      setFieldTouched(name, true);

      if (reason === "selectOption" || reason === "removeOption") {
        if (values.includes("Select All")) {
          if (allSelected) {
            setFieldValue(name, [], true);
          } else {
            setFieldValue(name, options, true);
          }
        } else {
          setFieldValue(
            name,
            values.filter((v) => v !== "Select All"),
            true
          );
        }
      } else if (reason === "clear") {
        setFieldValue(name, [], true);
      }

      setOpened(false);
    }
  };

  const optionRenderer = (props, option, state) => {
    const { selected } = state || {};
    const selectAllProps =
      option === "Select All" ? { checked: allSelected } : {};
    return (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          sx={{ marginRight: 1 }}
          checked={selected || false}
          {...selectAllProps}
        />
        {option}
      </li>
    );
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        size="small"
        id="tags-standard"
        onChange={handleChange}
        options={["Select All", ...options]}
        limitTags={1}
        value={value}
        loading={displayDropdown}
        disableCloseOnSelect
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return params.inputValue === ""
            ? [
                "Select All",
                ...filtered.filter((option) => option !== "Select All"),
              ]
            : filtered.filter((option) => option !== "Select All");
        }}
        isOptionEqualToValue={(option, val) => option === val}
        renderTags={(tag) => (opened ? <></> : <Tags value={tag} />)}
        renderOption={optionRenderer}
        renderInput={(params) => (
          <div style={{ display: "flex" }}>
            <TextField
              {...params}
              variant={variant}
              InputProps={{ ...params.InputProps, disableUnderline: noBorders }}
              label={label}
            />
          </div>
        )}
        {...rest}
      />
    </React.Fragment>
  );
}
