import api from "services/api";
//get API call
export const getApi = async (path, params = {}) => {
  const response = await api.get(`${path}`, params);
  return response.data;
};

//post API call
export const postApi = async (path, payload = {}, options = {}) => {
  const response = await api.post(path, payload, options);
  return response.data;
};

//delete API call
export const deleteApi = async (path, params = {}, options = {}) => {
  console.log({ params });
  const response = await api.delete(path, params);
  return response.data;
};
