import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import useCreateScenario from 'services/sceanrioSimulation/useCreateScenario';
import useGetMRPDetails from "../hooks/useGetMRPDetails";
import { useFormikContext } from 'formik';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Skeleton } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const getColumnHeaders = (data, x, y) => {
  if (!data || data.length === 0) {
    return [];
  }

  const columnKeys = Object.keys(data[0]);

  return columnKeys.map(item => ({
    headerName: item,
    field: item,
    headerTooltip: item,
    editable: true
  }));
};

const ReactEditableTable = ({ date, createScenario }) => {
  const userName = localStorage.getItem("username");
  const [rowData, setRowData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [jsonResponse, setJsonResponse] = useState('');
  const { IsLoadingCreate, isSuccess, isError, mutateCreateScenario } = useCreateScenario();
  const { mrpDetails, mutateMRPDetails, isSuccessData } = useGetMRPDetails();
  const { values, setFieldValue } = useFormikContext();
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    const startDateInteger = parseInt(values.startDate, 10);
    const endDateInteger = parseInt(values.endDate, 10);
    mutateMRPDetails({
      categoryName: values.createscenarioCategoryName,
      BP: values?.bpOptions,
      Cluster_Code: values.cluster,
      feature: values.features,
      startDate: parseInt(startDateInteger.toString() + values.startTdp.toString()),
      endDate: parseInt(endDateInteger.toString() + values.endTdp.toString())
    });
  }, []);

  useEffect(() => {
    if (isSuccessData) {
      setRowData(mrpDetails);
    }
  }, [isSuccessData]);

  const handleButtonClick = () => {
    const json = JSON.stringify(rowData, null, 2);
    setJsonResponse(json);
  };
  var changedCells = {};

  const handleCellValueChanged = (params) => {
    if (params.oldValue !== params.newValue) {
      if (!changedCells[params.node.id]) {
          changedCells[params.node.id] = {};
      }
      changedCells[params.node.id][params.column.getId()] = true;
      params.api.refreshCells({ columns: [params.column], rowNodes: [params.node], force: true });
  }

  };

  const columnDefs = getColumnHeaders(mrpDetails, values.createscenarioCategoryName, values.features);

  const handleCreateScenario = () => {
    setDisabled(true);
    const columnOrder = getColumnHeaders(mrpDetails, values.createscenarioCategoryName, values.features).map(col => col.field);

    const header = columnOrder.join(',') + '\n';

    const csvData = rowData.map(row => {
      return columnOrder.map(col => (row[col] !== undefined && row[col] !== null) ? row[col] : '').join(',');
    }).join('\n');

    const csvContent = header + csvData;

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const formData = new FormData();
    formData.append('file', blob, 'data.csv');
    formData.append('username', userName);
    formData.append('filename', 'shruti-test.csv');
    formData.append('scenario_name', values.createscenarioName);
    formData.append('scenario_description', values.createscenarioDesc);
    formData.append('creation_date', date);
    formData.append('channel', values.createscenarioCategoryName);
    formData.append('category', values.createscenarioCategoryName.split('_')[1]);
    formData.append('feature', JSON.stringify(values.features))

    mutateCreateScenario(formData);
    setFieldValue("finishEnable", true);
  };

  const handleOk = () => {
    setFieldValue("pageDetails", "dashboard");
    setFieldValue("createscenarioName", "");
    setFieldValue("createscenarioCategoryName", "");
    setFieldValue("features", []);
    setFieldValue("bpOptions", []);
    setFieldValue("cluster", []);
    setFieldValue("startDate", []);
    setFieldValue("channelNameScenario", []);
    setFieldValue("endDate", []);
    setFieldValue("createscenarioDesc", "")
    setOpenDialog(false)
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenDialog(true)
    }
  }, [isSuccess])

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Button
        variant="contained"
        onClick={handleCreateScenario}
        sx={{
          position: "absolute",
          bottom: "-50px",
          right: 0,
          zIndex: 1000,
        }}
      >
        Create Scenario
      </Button>
      {IsLoadingCreate &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      {openDialog &&
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  A new scenario <b>{values.createscenarioName}</b> has been created. Please click on "Finish" to exit the process.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleOk()} color="primary">
                  Finish
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </React.Fragment>
      }

      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        {isSuccessData ?
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            onCellValueChanged={handleCellValueChanged}
            enableRangeSelection={true}
            copyHeadersToClipboard={true}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
              resizable: true,
              cellStyle: function(params){
                if (changedCells[params.node.id] && changedCells[params.node.id][params.column.getId()]) {
                  return {
                      'background-color': '#B0EBB4',
                      'display': "flex",
                      'alignItems': "center",
                      'justifyContent': "center",
                  };
              } else {
                  return {
                      'display': "flex",
                      'alignItems': "center",
                      'justifyContent': "center",
                  };
              }
              },
            }}
          />
          : <Skeleton height={300} />}
      </div>

    </div>
  );
};

export default ReactEditableTable;
