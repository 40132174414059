import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetSucessBasePack() {
  const path = "/scenario_simulation/successBasePack";

  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();

  // useEffect(() => {
  //   if (isError) {
  //     showError(JSON.stringify(error?.response?.data));
  //   }
  // }, [error?.response?.data, isError, showError]);

  let basePackDetails = [];
  let categoryName;
  let scenarioName;
  let scenario_description;
  let feature
  if (typeof data === "object" && Object.keys(data).length > 0) {
    // data.Key.forEach((item) => {
    //   basePackDetails.push({ label: item, value: item });
    // });
    basePackDetails = data.Key;
    categoryName = data.category_name;
    scenarioName = data.scenario_name;
    scenario_description = data.scenario_description;
    feature = data.feature
  }

  return {
    IsLoadingSucess: isLoading,
    mutateSuccessBasePack: mutate,
    basePackDetails,
    isError,
    categoryName,
    scenarioName,
    scenario_description,
    feature
  };
}
