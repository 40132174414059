import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import { getError, getFieldId } from "../field-helper";
import { StyledSelect } from "../MultiSelectField/MultiSelectField";

const SelectField = ({
  field,
  form,
  label = "",
  options,
  fullWidth,
  required,
  disabled,
  variant = "outlined",
  notifyChange,
  helperText,
  error: propError,
  ...props
}) => {
  const { name, value, onChange, onBlur } = field;

  const { error, hasError } = getError(field, form);

  const fieldId = getFieldId(name);
  const labelId = `${fieldId}-simple-select-label`;

  return (
    <FormControl
      variant={variant}
      error={!!propError || hasError}
      size="small"
      margin="none"
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel id={labelId} required={required}>
          {label}
        </InputLabel>
      )}
      <StyledSelect
        name={name}
        id={fieldId}
        label={label}
        labelId={labelId}
        value={value ?? ""}
        onChange={(e) => {
          onChange(e);
          if (typeof notifyChange === "function") {
            notifyChange(e.target || e.currentTarget);
          }
        }}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
      >
        {(options || []).map((option) => (
          <MenuItem key={option} value={option}>
            {option || "[empty]"}
          </MenuItem>
        ))}
      </StyledSelect>

      {(!!propError || error) && (
        <FormHelperText>{helperText || error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectField;
