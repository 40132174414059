import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

//cssfor the app layout
export const Root = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  min-height: 64px;
  z-index: 101;
`;

export const ViewportContainer = styled.div`
  display: flex;
  height: calc(100vh - 64px);
  background: ${({ theme }) => `${theme.palette.mode==="dark"?"#2d2d2d":"#e7ebfa"}`}; 
  `;

export const LeftSideNavbarWrapper = styled.div`
  z-index: 100;
  width: 290px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 97;
  overflow-y: auto;
`;
