import { useQuery } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetTDPlist(fileName,month) {
  const path = "/demand_forecast/get_tdps_list";

  const { data, isLoading, error, isError } = useQuery(
    ["gettdplist", fileName],
    () => postApi(path, { table_name: fileName, month:month }),
    { enabled: !!fileName }
  );

  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const { TDP = [] } = data ?? [];

  return {
    tdpLoading: isLoading,
    tdpList: TDP,
  };
}
