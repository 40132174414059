import DataGridTable from "components/DataGridTable/DataGridTable";
import React, { useEffect } from "react";
import useGetOverallTable from "services/outQuarter/useGetOverallTable";
import { useFormikContext } from "formik";
import { colDef, colDefCagr2, colDefCagr3, colDefCagr4 } from "./formData";
import ReactECharts from "echarts-for-react";
import OverAllFy from "./OverAllFy";
import Loader from "components/Loader";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
export default function OverAllTable(props) {
  const {
    term,
    checked,
    gridApi,
    setGridApi,
    chart,
    enableAPIs,
    decimals,
    isLoading,
    rowDataValue,
    qtrOptions,
    data,
    ...rest
  } = props;

  const [cagr, setCagr] = useState(2);

  return (
    <React.Fragment>
      {isLoading === true ? (
        <Loader />
      ) : (
        <React.Fragment>
          {checked === "table" ? (
            <React.Fragment>
              <FormControl size="small" sx={{ mt: 2,width:200 }}>
                <InputLabel id="demo-simple-select-label">
                  Select CAGR
                </InputLabel>
                <Select
                  value={cagr}
                  label="Select CAGR"
                  onChange={(event) => setCagr(event.target.value)}
                >
                  <MenuItem value={2}>2 yr CAGR</MenuItem>
                  <MenuItem value={3}>3 yr CAGR</MenuItem>
                  <MenuItem value={4}>4 yr CAGR</MenuItem>
                </Select>
              </FormControl>
              <DataGridTable
                {...rest}
                height="400px"
                onGridReady={(e) => setGridApi(e.api)}
                suppressRowTransform={true}
                columnDefs={
                  cagr === 2
                    ? colDefCagr2(decimals)
                    : cagr === 3
                    ? colDefCagr3(decimals)
                    : colDefCagr4(decimals)
                }
                rowData={rowDataValue}
                borders={false}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {chart === "fy" ? (
                <React.Fragment>
                  <OverAllFy
                    optionData={data}
                    term={term}
                    decimals={decimals}
                  />
                </React.Fragment>
              ) : (
                <ReactECharts
                  style={{ height: "300px", width: "100%" }}
                  option={qtrOptions}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
