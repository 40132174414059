/* eslint-disable react-hooks/exhaustive-deps */
import { Title } from "components/NavBar/NavBar.styles";
import { Field, useFormikContext } from "formik";
import { Grid, Stack, Typography } from "@mui/material";
import { filterData } from "../../formData";
import useGetNavBarMtSop from "services/Mts&op/useGetNavBarMtSop";
import { useEffect } from "react";

import React from "react";
import useGetFileName from "services/Mts&op/useGetFileName";
import SelectField from "components/form-fields/SelectField";
import NavBar from "components/NavBar";
import useGetSavedName from "services/saveRecordDetails/useGetSavedName";
import AutocompleteField from "components/form-fields/AutocompleteField";

const MtSopFilter = ({
  onApply,
  enableAPIs,
  onReset,
  onSave,
  setEnableAPIs,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { data, isLoading, mutate } = useGetNavBarMtSop();
  const { fileName } = useGetFileName();
  const { names, mutateSave } = useGetSavedName();
  useEffect(() => {
    mutateSave({
      username: localStorage.getItem("username"),
      pagename: "mtsop",
    });
  }, [enableAPIs, mutateSave]);
  useEffect(() => {
    if (values.filename) {
      mutate({
        filename: values.filename,
        filters: values.filters,
      });
    }
    setEnableAPIs(false);
  }, [values.filename, enableAPIs, mutate]);
  useEffect(() => {
    if (values.filename) {
      setFieldValue("mtsopfilterData", filterData);
    }
  }, [values.filename]);
  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      onSave={onSave}
      filterData={values.mtsopfilterData ?? []}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"savedName"}
              label={"Saved Name"}
              options={names ?? []}
              fullWidth
              component={AutocompleteField}
            />
            <Typography sx={{ textAlign: "center", width: "inherit" }}>
              Or
            </Typography>
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"filename"}
              label={"File Name"}
              component={SelectField}
              options={fileName ?? []}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container item flex={1}>
            <Field
              name="nm"
              label={"Quarter"}
              component={SelectField}
              options={["MQ", "JQ", "SQ", "DQ"]}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container item flex={1}>
            <Field
              name="ratio"
              label={"Ratio"}
              component={SelectField}
              options={["Prev_Qtr", "Skip_Qtr", "Seq_Prev_Qtr"]}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};
export default MtSopFilter;
