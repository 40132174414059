import { Grid, Box, Typography, Tooltip } from "@mui/material";
import Section from "components/Section";
import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useState } from "react";
import SynopsisTable from "./components/SynopsisTable";
import ButtonGroup from "components/ButtonGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { getRowStyle } from "utils/constants";

const Synopsis = ({ enableAPIs, decimals, exportToCSV }) => {
  const [gridApi4, setGridApi4] = useState();
  const [gridApi5, setGridApi5] = useState();
  const [gridApi6, setGridApi6] = useState();
  const [value, setValue] = React.useState("qtr");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [checked, setChecked] = useState("table");
  const [gridOptions4] = useState({
    getRowStyle: (params) => getRowStyle(params, "index"),
    alignedGrids: [],
  });
  const [gridOptions5] = useState({
    getRowStyle: (params) => getRowStyle(params, "index"),
    alignedGrids: [],
  });
  const [gridOptions6] = useState({
    getRowStyle: (params) => getRowStyle(params, "index"),
    alignedGrids: [],
  });

  gridOptions4.alignedGrids.push(gridOptions5);
  gridOptions4.alignedGrids.push(gridOptions6);

  gridOptions5.alignedGrids.push(gridOptions4);
  gridOptions5.alignedGrids.push(gridOptions6);

  gridOptions6.alignedGrids.push(gridOptions4);
  gridOptions6.alignedGrids.push(gridOptions5);

  return (
    <Grid item md={12}>
      <Section
        title={"By Brand Analysis"}
        actions={
          <React.Fragment sx={{ m: 6 }}>
            <ButtonGroup
              setValue={setChecked}
              value={checked}
              options={[
                { label: "Table", value: "table" },
                { label: "Chart", value: "chart" },
              ]}
              primaryColor={"#31b2dc"}
              width={84}
              style={{ paddingRight: "20px" }}
            />

            {checked === "table" && (
              <Tooltip title="Download table">
                <CloudDownloadIcon
                  sx={{ mr: 1 }}
                  onClick={() =>
                    exportToCSV(gridApi4, gridApi5, gridApi6, "Brand Analysis")
                  }
                  style={{
                    fontSize: "34px",
                    marginLeft: "9px",
                    color: "#2036C7",
                  }}
                />
              </Tooltip>
            )}
          </React.Fragment>
        }
      >
        {checked === "chart" && (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="qtr" control={<Radio />} label="QTR" />
              <FormControlLabel value="fy" control={<Radio />} label="FY" />
            </RadioGroup>
          </FormControl>
        )}
        <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
          Volume
        </Typography>

        <SynopsisTable
          term="VOL"
          height={378}
          gridApi={gridApi4}
          setGridApi={setGridApi4}
          decimals={decimals}
          enableAPIs={enableAPIs}
          gridOptions={gridOptions4}
          selected={checked}
          quarter={value}
        />

        <Box sx={{ mt: 2 }}>
          <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
            Value
          </Typography>

          <SynopsisTable
            term="VALUE"
            height={378}
            gridApi={gridApi5}
            setGridApi={setGridApi5}
            decimals={decimals}
            enableAPIs={enableAPIs}
            gridOptions={gridOptions5}
            selected={checked}
            quarter={value}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
            Eaches
          </Typography>

          <SynopsisTable
            term="EACH"
            height={378}
            gridApi={gridApi6}
            setGridApi={setGridApi6}
            decimals={decimals}
            enableAPIs={enableAPIs}
            gridOptions={gridOptions6}
            selected={checked}
            quarter={value}
          />
        </Box>
      </Section>
    </Grid>
  );
};

export default Synopsis;
