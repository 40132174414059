import Axios from 'axios';
import { Configuration } from 'utils/configuration';
import registerRequestInterceptors from './registerRequestInterceptors';
import registerResponseInterceptors from './resgisterResponseInterceptors';

const config = new Configuration();
//creation of the instance for post and get call
const api = Axios.create({
  baseURL: config.GetConfig('API_URL'),
});
//request and response interceptors
registerRequestInterceptors(api);

registerResponseInterceptors(api);

export * from './helper';

export default api;
