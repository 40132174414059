import React, { useMemo, useCallback, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "./DataGridTable.css";
import "ag-grid-enterprise";
import { Button } from "@mui/material";
import { useTheme } from "@emotion/react";

const DataGridTable = (props) => {
  const { height, onGridReady, isScenarioSimulation, ...rest } = props;
  const [gridApi, setGridApi] = useState(null);

  const gridRef = useRef();
  const defaultColDef = useMemo(() => {
    return {
      minWidth: 50,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeight: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
    };
  }, []);

  const saveState = useCallback(() => {
    window.colState = gridRef.current.columnApi.getColumnState();
  }, []);

  const restoreState = useCallback(() => {
    if (!window.colState) {
      return;
    }
    gridRef.current.columnApi.applyColumnState({
      state: window.colState,
      applyOrder: true,
    });
  }, []);

  const sizeToFit = () => {
    if (gridApi && gridApi.api && gridApi.api !== undefined) {
      gridApi.api.sizeColumnsToFit({ defaultMinWidth: 100 });
    }
  };

  const autoSizeAll = () => {
    if (gridApi && gridApi.columnApi) {
      const allColumnIds = [];
      const columns = gridApi.columnApi.getColumns();
      columns.forEach(function (column) {
        allColumnIds.push(column.colId);
      });

      gridApi.columnApi.autoSizeColumns(allColumnIds);
    }
  };

  const headerHeightGetter = () => {
    const columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ];
    const clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );
    const tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  const headerHeightSetter = () => {
    const padding = 20;
    const height = headerHeightGetter() + padding;
    gridApi.api.setHeaderHeight(height <= 0 ? 42 : height);
    gridApi.api.resetRowHeights();
  };

  const theme = useTheme();

  return (
    <React.Fragment>
      {!isScenarioSimulation && (
        <div className="display-right">
          <Button onClick={sizeToFit} sx={{ color: theme.palette.button.text }}>
            Size to Fit
          </Button>
          <Button
            onClick={autoSizeAll}
            sx={{ color: theme.palette.button.text }}
          >
            Re-Size
          </Button>
          <Button onClick={saveState} sx={{ color: theme.palette.button.text }}>
            Save Selection
          </Button>
          <Button
            onClick={restoreState}
            sx={{ color: theme.palette.button.text }}
          >
            Restore Selection
          </Button>
        </div>
      )}
      <div
        className={
          theme.palette.mode === "dark"
            ? "ag-theme-alpine-dark"
            : "ag-theme-alpine"
        }
        style={{ height: height }}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          {...rest}
          ref={gridRef}
          onGridReady={(e) => {
            setGridApi(e);
            onGridReady(e);
          }}
          enableBrowserTooltips={true}
          suppressColumnVirtualisation={true}
          suppressRowVirtualisation={true}
          enableColResize={true}
          onFirstDataRendered={headerHeightSetter}
          onColumnResized={headerHeightSetter}
        />
      </div>
    </React.Fragment>
  );
};
export default DataGridTable;
