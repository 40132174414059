/* eslint-disable react-hooks/exhaustive-deps */
import useGetDemandForecastNonAgg from "services/DemandForecast/useGetDemandForecastNonAgg";
import { color } from "../mappings";
import { alpha } from "@mui/system";
import { useEffect } from "react";
import { useCallback } from "react";
import { useTheme } from "@emotion/react";
import { type } from "@testing-library/user-event/dist/type";

const lineStyle = {
  shadowColor: "#dfe8ed",
  shadowBlur: 1,
  shadowOffsetX: 0,
  shadowOffsetY: 6,
};

let primaryNonAgg = [];
let secondaryNonAgg = [];

export default function useDemandForecastNonAgg(
  values,
  timePeriod,
  primary,
  secondary,
  enableAPIs,
  decimals,
  displayData
) {
  const {
    data,
    xAxis,
    demandForecastLoadingNonAgg,
    yAxis,
    mutateNonAgg,
    invalidateData,
  } = useGetDemandForecastNonAgg(
    {
      key: timePeriod,
    },
    displayData
  );

  const bp = values.filters["BP"];

  const theme = useTheme();

  const onApplyNonAgg = useCallback(
    (primary, secondary) => {
      if (values.fileName.length !== 0) {
        mutateNonAgg({
          filename: values.fileName,
          key: timePeriod,
          data_query: [
            ...primary.map((f) => f.value),
            ...secondary.map((f) => f.value),
          ],
          ...values.filters,
        });
      }
    },
    [enableAPIs, mutateNonAgg, timePeriod]
  );

  const series = [];
  const legend = [];

  useEffect(() => {
    primaryNonAgg = [];
    for (let i = 0; i < bp.length; i++) {
      for (let j = 0; j < primary.length; j++) {
        primaryNonAgg.push({
          label: primary[j].label + "_" + bp[i],
          value: primary[j].value + "_" + bp[i],
        });
      }
    }
  }, [primary, bp]);

  useEffect(() => {
    secondaryNonAgg = [];
    for (let i = 0; i < bp.length; i++) {
      for (let j = 0; j < secondary.length; j++) {
        secondaryNonAgg.push({
          label: secondary[j].label + "_" + bp[i],
          value: secondary[j].value + "_" + bp[i],
        });
      }
    }
  }, [secondary, bp]);

  primaryNonAgg.forEach(({ label, value }, index) => {
    legend.push(label);
    series.push({
      name: label,
      type: "line",
      color: color[index] ?? color[index % color.length],
      lineStyle: {
        ...lineStyle,
        shadowColor: Boolean(color[index])
          ? alpha(color[index], 0.2)
          : alpha(color[index % color.length], 0.2),
      },
      data: yAxis[value],
      smooth: true,
    });
  });

  secondaryNonAgg.forEach(({ label, value }, index) => {
    legend.push(label);
    series.push({
      name: label,
      type: "line",
      color: color[5 + index] ?? color[(5 + index) % color.length],
      lineStyle: {
        ...lineStyle,
        shadowColor: Boolean(color[5 + index])
          ? alpha(color[5 + index], 0.2)
          : alpha(color[(5 + index) % color.length], 0.2),
      },
      data: yAxis[value],
      yAxisIndex: 1,
      smooth: true,
    });
  });

  const accuracyOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      // valueFormatter: (value) => value.toFixed(decimals),
    },
    legend: {
      data: legend,
      type: "scroll",
      // orient: 'vertical',
      orient: "horizontal",
      width: "85%",
      // height: "50%",
      textStyle: {
        color: theme.palette.text.primary,
      },
    },
    toolbox: {
      show: true,
      itemSize: 20,
      feature: {
        magicType: { type: ["line", "bar"] },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: series,
  };

  return {
    accuracyOptionNonAgg: accuracyOption,
    dataNonAgg: data,
    demandForecastLoadingNonAgg,
    invalidateData,
    onApplyNonAgg,
  };
}
