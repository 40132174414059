import React, { useState } from "react";
import { alpha } from "@mui/system";
import {
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import Section from "components/Section";
import ReactECharts from "echarts-for-react";
import { Field, useFormikContext } from "formik";
import AutocompleteField from "components/form-fields/AutocompleteField";

import useGetFileName from "services/DemandForecast/useGetFileName";
import ButtonGroup from "components/ButtonGroup";
import useGetScenarioName from "services/sceanrioSimulation/useGetScenarioName";
import { useEffect } from "react";
import useGetResult from "services/sceanrioSimulation/useGetResult";
import Loader from "components/Loader";
import { color } from "views/DemandForecast/mappings";
import DisplayButton from "components/DisplayButton/DisplayButton";
import DataGridTable from "components/DataGridTable/DataGridTable";
import FeatureImportance from "views/DemandForecast/components/FeatureImportance";
import Autocomplete from "components/Autocomplete";
import useGetBasePack from "services/sceanrioSimulation/useGetBasepack";
import useGetClusterCode from "services/sceanrioSimulation/useClusterCode";
import useGetAnaplanCustomer from "services/sceanrioSimulation/userGetAnalplanCustomer";
import ExportOption from "components/ExportOption/ExportOption";
import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";
import { downloadCSVFromJson } from "../hooks/downloadJson";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useGetKeys from "services/sceanrioSimulation/UseGetKeys";
import useGetCompareResult from "services/sceanrioSimulation/useGetCompareResult";
import useGetCompareResultNonAgg from "services/sceanrioSimulation/useGetCompareResultNonAgg";
import CustomPlaceholder from "components/CustomPlaceholder";
import CheckBox from "@mui/icons-material/CheckBox";

export default function ComparsionPage(props) {
  const userName = localStorage.getItem("username");
  // const userName = "Shruti.Naik@unilever.com";
  // const userName = "Divya.Harshini@unilever.com";
  const lineStyle = {
    shadowColor: "#dfe8ed",
    shadowBlur: 1,
    shadowOffsetX: 0,
    shadowOffsetY: 6,
  };

  const [display, setStateDisplay] = useState(false);
  const [mode, setStateMode] = useState("MOC");
  const { values, setFieldValue } = useFormikContext();
  const { scenarioList, mutateScenario } = useGetScenarioName();
  const { fileName } = useGetFileName();
  const [checked, setChecked] = useState("chart");
  const [selectedValues, setSelectedValues] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [showFeatureImp, setShowFeatureImp] = useState(false);
  const [featureDetails, setFeatureDetails] = useState();
  const [basepack, setBasepack] = useState([]);
  const [anaplanCustomer, setAnaplanCustomer] = useState(null);
  const { keyDetails, mutateGetKeys } = useGetKeys();
  const { anaplanDetails, mutateGetAnaplan } = useGetAnaplanCustomer();
  const [displayData, setDisplayData] = useState("Agg");
  const [dataChange, setDataChange] = useState("");
  const [dataChangeHolder, setDataChangeHolder] = useState(false);

  useEffect(() => {
    setDataChangeHolder(true);
  }, [dataChange, values.demandDriver]);

  const queryClient = useQueryClient();

  const ITEM_HEIGHT = 72;
  const ITEM_PADDING_TOP = 6;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    scenarioResult,
    colDef,
    mutateScenarioCompareResult,
    isLoading,
    xAxis,
    yAxis,
    legendData,
  } = useGetCompareResult(
    mode,
    basepack?.value,
    values.cluster,
    values.scale,
    values.demandDriver
  );

  const {
    scenarioResultNonAgg,
    colDefNonAgg,
    mutateScenarioCompareResultNonAgg,
    isLoadingNonAgg,
    xAxisNonAgg,
    yAxisNonAgg,
    legendDataNonAgg,
  } = useGetCompareResultNonAgg(
    mode,
    basepack?.value,
    values.cluster,
    values.scale,
    values.demandDriver
  );

  const [apply, setStateApply] = useState(false);
  const handleSelectChange = (event) => {
    setSelectedValues(event.target.value);
    setStateApply(false);
    setDisplayData("Agg");
    setStateMode("MOC");
    setFieldValue("keys", []);
  };

  useEffect(() => {
    if (selectedValues.length === 0) setFieldValue("keys", []);
    setStateDisplay(false);
    setDisplayData("Agg");
    setStateMode("MOC");
    setShowFeatureImp(false);
  }, [selectedValues.length]);

  useEffect(() => {
    if (values.keys.length === 0) setStateDisplay(false);
    setDisplayData("Agg");
    setStateMode("MOC");
    setShowFeatureImp(false);
  }, [values.keys.length]);

  useEffect(() => {
    setSelectedValues([]);
    setFieldValue("cluster", []);
    setStateApply(false);
    mutateScenario({
      username: userName,
      channelName: values.categoryNameScenario,
    });
  }, [values.categoryNameScenario]);
  const onApply = (scenario, categoryNameScenario) => {
    mutateGetKeys(
      {
        username: userName,
        categoryName: categoryNameScenario,
        scenarioNames: scenario,
      },
      [scenario]
    );
  };

  // useEffect(() => {
  //   if (
  //     basepack !== null &&
  //     anaplanCustomer !== null &&
  //     values.categoryNameScenario.startsWith("MT")
  //   )
  //     mutateGetCluster({
  //       username: userName,
  //       categoryName: values?.categoryNameScenario?.split("_")[0],
  //       scenario_Name: selectedValues,
  //       BP: [basepack?.value],
  //       anaplanCustomer: anaplanCustomer?.value,
  //     });
  // }, [basepack, anaplanCustomer]);

  // useEffect(() => {
  //   if (basepack !== null && values.categoryNameScenario.startsWith("GT"))
  //     mutateGetCluster({
  //       username: userName,
  //       categoryName: values?.categoryNameScenario?.split("_")[0],
  //       scenario_Name: selectedValues,
  //       BP: [basepack?.value],
  //     });
  // }, [basepack]);

  useEffect(() => {
    if (basepack !== null && values.categoryNameScenario.startsWith("MT"))
      mutateGetAnaplan({
        username: userName,
        categoryName: values?.categoryNameScenario?.split("_")[0],
        scenario_Name: selectedValues,
        BP: [basepack?.value],
      });
  }, [basepack]);

  const getseriesData = () => {
    let seriesOptionData = [];
    if (
      scenarioResult &&
      Array.isArray(scenarioResult) &&
      scenarioResult.length
    ) {
      Object.keys(scenarioResult[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if (values.demandDriver.length > 0) {
            if (i.includes("MRP_updated") || i.includes("GRM_updated")) {
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[5 + index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[5 + index], 0.2),
                },
                data: yAxis[i],
                yAxisIndex: 1,
                smooth: true,
              });
            } else {
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxis[i],
                smooth: true,
              });
            }
          } else {
            seriesOptionData.push({
              // name: i,
              name: i.replace(/(Accenture|ACC)/g, "Falcon"),
              type: "line",
              color: color[index],
              lineStyle: {
                ...lineStyle,
                shadowColor: alpha(color[index], 0.2),
              },
              data: yAxis[i],
              smooth: true,
            });
          }
        }
      });
    }
    return seriesOptionData;
  };

  const getSeriesDataNonAgg = () => {
    let seriesOptionData = [];
    if (
      scenarioResultNonAgg &&
      Array.isArray(scenarioResultNonAgg) &&
      scenarioResultNonAgg.length
    ) {
      Object.keys(scenarioResultNonAgg[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if (values.demandDriver.length > 0) {
            if (i.includes("MRP_updated") || i.includes("GRM_updated")) {
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[5 + index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[5 + index], 0.2),
                },
                data: yAxisNonAgg[i],
                yAxisIndex: 1,
                smooth: true,
              });
            } else {
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxisNonAgg[i],
                smooth: true,
              });
            }
          } else {
            seriesOptionData.push({
              // name: i,
              name: i.replace(/(Accenture|ACC)/g, "Falcon"),
              type: "line",
              color: color[index],
              lineStyle: {
                ...lineStyle,
                shadowColor: alpha(color[index], 0.2),
              },
              data: yAxisNonAgg[i],
              smooth: true,
            });
          }
        }
      });
    }
    return seriesOptionData;
  };

  const scenariooption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      // data: scenarioResult && legendData,
      data: displayData === "Agg" ? legendData : legendDataNonAgg,
      right: "6%",
      top: "5%,",
    },

    grid: {
      left: "3%",
      right: "4%",

      containLabel: true,
    },

    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: displayData === "Agg" ? xAxis : xAxisNonAgg,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],
    series:
      displayData === "Agg"
        ? scenarioResult && getseriesData()
        : scenarioResultNonAgg && getSeriesDataNonAgg(),
  };
  const exportCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  };

  const downloadScenarioData = async (
    selectedValues,
    categoryNameScenario,
    mode
  ) => {
    const fileName = "scenario_" + categoryNameScenario;
    const path = "sceanrio_simulation/download";
    const payload = {
      scenarioName: selectedValues,
      fileName: categoryNameScenario,
      username: userName,
      mode: mode,
    };
    try {
      const data = await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {});
      downloadCSVFromJson(fileName, data);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleApply = () => {
    setDataChangeHolder(false);
    setFeatureDetails({
      bp: basepack?.value,
      cluster: values.cluster,
      anaplanCustomer: anaplanCustomer?.value,
    });
    setShowFeatureImp(true);
    setStateDisplay(true);
    if (values.keys.length !== 0) {
      if (displayData === "Agg") {
        if (values.categoryNameScenario.startsWith("MT")) {
          mutateScenarioCompareResult({
            scenarioName: selectedValues,
            fileName: values.categoryNameScenario,
            mode: mode,
            username: userName,
            Keys: values.keys,
            // clusterCode: values.cluster,
            anaplanCustomer: anaplanCustomer?.value,
            demandDriver: [...values.demandDriver.map((f) => f.value)],
          });
        } else {
          mutateScenarioCompareResult({
            scenarioName: selectedValues,
            fileName: values.categoryNameScenario,
            mode: mode,
            username: userName,
            Keys: values.keys,
            // clusterCode: values.cluster,
            demandDriver: [...values.demandDriver.map((f) => f.value)],
          });
        }
      } else {
        if (values.categoryNameScenario.startsWith("MT")) {
          mutateScenarioCompareResultNonAgg({
            scenarioName: selectedValues,
            fileName: values.categoryNameScenario,
            mode: mode,
            username: userName,
            Keys: values.keys,
            // clusterCode: values.cluster,
            anaplanCustomer: anaplanCustomer?.value,
            demandDriver: [...values.demandDriver.map((f) => f.value)],
          });
        } else {
          mutateScenarioCompareResultNonAgg({
            scenarioName: selectedValues,
            fileName: values.categoryNameScenario,
            mode: mode,
            username: userName,
            Keys: values.keys,
            // clusterCode: values.cluster,
            demandDriver: [...values.demandDriver.map((f) => f.value)],
          });
        }
      }
    }
  };

  return (
    <Grid>
      <ArrowBackIcon
        onClick={() => {
          setFieldValue("pageDetails", "dashboard");
          setFieldValue("categoryNameScenario", "");
          setFieldValue("historykeys", []);
        }}
      />
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Select below scenarios to compare:
          </Typography>
        </Grid>
        <Grid item md={12}>
          <FormControl size="small" sx={{ mt: 2, width: 300 }}>
            <Field
              name={"categoryNameScenario"}
              label={"Category"}
              options={fileName}
              isOptionEqualToValue={(option, value) => option.value === value}
              fullWidth
              component={AutocompleteField}
            />
          </FormControl>
          {values.categoryNameScenario !== "" && (
            <FormControl size="small" sx={{ mt: 2, width: 300, ml: 2 }}>
              <InputLabel id="multiselect-label">Scenario Name</InputLabel>
              <Select
                labelId="multiselect-label"
                id="multiselect"
                multiple
                value={selectedValues}
                onChange={handleSelectChange}
                renderValue={(selected) => selected.join(", ")}
                label="Scenario Name"
                MenuProps={MenuProps}
              >
                {scenarioList.length !== 0 ? (
                  scenarioList.map((i, j) => (
                    <MenuItem value={i.value}>
                      <Checkbox
                        checked={
                          selectedValues.findIndex(
                            (item) => item === i.value
                          ) >= 0
                        }
                      />
                      <ListItemText primary={i.value} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          {values.categoryNameScenario !== "" &&
            selectedValues.length !== 0 && (
              <>
                <Button
                  sx={{ mt: 2, ml: 2, width: "150px" }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    onApply(selectedValues, values.categoryNameScenario);
                    setStateMode("MOC");
                    setStateApply(true);
                    setBasepack(null);
                    setFieldValue("cluster", []);
                    setAnaplanCustomer(null);
                    // setShowFeatureImp(true);
                  }}
                >
                  Next
                </Button>
                <Button
                  sx={{ mt: 2, ml: 2, width: "150px" }}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    downloadScenarioData(
                      selectedValues,
                      values.categoryNameScenario,
                      mode
                    )
                  }
                >
                  Download
                </Button>
              </>
            )}
          <div style={{ display: "flex", marginBottom: "16px" }}>
            {apply === true && (
              <React.Fragment>
                <FormControl size="small" sx={{ mt: 2, width: 300 }}>
                  <Field
                    multiple
                    name="keys"
                    label="Select the keys"
                    options={keyDetails}
                    noBorders={false}
                    variant="outlined"
                    fullWidth
                    component={MultiSelectDropdownF}
                  />
                </FormControl>
                {values.categoryNameScenario.startsWith("MT") && (
                  <FormControl
                    size="small"
                    sx={{ width: 300, marginRight: "20px" }}
                  >
                    <Autocomplete
                      label="Anaplan Customer"
                      value={anaplanCustomer}
                      onChange={(e, value) => {
                        setAnaplanCustomer(value);
                      }}
                      options={anaplanDetails}
                    />
                  </FormControl>
                )}
              </React.Fragment>
            )}
            {/* {values.keys.length !== 0 && (
              <Field
                multiple
                name="scale"
                label="Measure"
                options={values?.dropdown}
                component={AutocompleteField}
                sx={{ mt: 2, width: 300, ml: 2 }}
              />
            )} */}

            <Button
              variant="contained"
              style={{
                marginTop: "12px",
                marginRight: "10px",
                marginLeft: "10px",
                float: "right",
              }}
              onClick={handleApply}
              disabled={values.keys.length === 0}
            >
              Apply
            </Button>
          </div>
        </Grid>
        {display ? (
          !dataChangeHolder ? (
            <React.Fragment>
              {isLoading === true ? (
                <Grid item md={12}>
                  <Loader />
                </Grid>
              ) : (
                <Grid item md={12}>
                  <Section
                    onSettings={
                      <ExportOption
                        checked={checked}
                        onClick={() => exportCsv()}
                      />
                    }
                    title={" "}
                    actions={
                      <ButtonGroup
                        setValue={setChecked}
                        value={checked}
                        options={[
                          { label: "Chart", value: "chart" },
                          { label: "Table", value: "table" },
                        ]}
                        primaryColor={"#31b2dc"}
                        width={84}
                      />
                    }
                  >
                    <React.Fragment>
                      {checked === "chart" ? (
                        <React.Fragment>
                          {scenariooption["series"] &&
                            scenariooption["series"].length > 0 && (
                              <>
                                {values.categoryNameScenario !== "" && (
                                  <Grid item md={12}>
                                    {(values?.categoryNameScenario
                                      ?.split("_")[0]
                                      .includes("GT") ||
                                      values?.categoryNameScenario
                                        ?.split("_")[0]
                                        .includes("MT") ||
                                      values?.categoryNameScenario
                                        ?.split("_")[0]
                                        .includes("OH")) && (
                                      <DisplayButton
                                        name={"MOC Planning"}
                                        active={mode === "MOC"}
                                        buttonOnclick={() => {
                                          setStateMode("MOC");
                                          setDataChange("MOC");
                                        }}
                                      />
                                    )}
                                    {(values?.categoryNameScenario
                                      ?.split("_")[0]
                                      .includes("GT") ||
                                      values?.categoryNameScenario
                                        ?.split("_")[0]
                                        .includes("OH")) && (
                                      <DisplayButton
                                        name={"TDP Planning"}
                                        active={mode === "TDP"}
                                        buttonOnclick={() => {
                                          setStateMode("TDP");
                                          setDataChange("TDP");
                                        }}
                                      />
                                    )}
                                    {(values?.categoryNameScenario
                                      ?.split("_")[0]
                                      .includes("GT") ||
                                      values?.categoryNameScenario
                                        ?.split("_")[0]
                                        .includes("OH")) && (
                                      <DisplayButton
                                        name={"Book Month"}
                                        active={mode === "Book_Month"}
                                        buttonOnclick={() => {
                                          setStateMode("Book_Month");
                                          setDataChange("Book_Month");
                                        }}
                                      />
                                    )}
                                  </Grid>
                                )}
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={displayData}
                                    onChange={(event) =>
                                      setDisplayData(event.target.value)
                                    }
                                  >
                                    <FormControlLabel
                                      value="Agg"
                                      control={<Radio />}
                                      label="Aggregated Data"
                                      // onClick={() => onApply(values.primary,values.secondary)}
                                      onClick={() => setDataChange("Agg")}
                                    />
                                    <FormControlLabel
                                      value="Non-Agg"
                                      control={<Radio />}
                                      label="Non Aggregated Data"
                                      disabled={values.keys.length < 2}
                                      onClick={() => setDataChange("NonAgg")}
                                    />
                                  </RadioGroup>
                                </FormControl>
                                <div style={{ display: "flex" }}>
                                  <Grid item md={6} sx={{ mt: 2 }}>
                                    <Field
                                      multiple
                                      name="scale"
                                      label="Measure"
                                      options={values?.dropdown}
                                      component={AutocompleteField}
                                    />
                                  </Grid>
                                  <Grid item md={6} sx={{ mt: 2, ml: 2 }}>
                                    <Field
                                      multiple
                                      name="demandDriver"
                                      label="Demand Drivers"
                                      options={values?.demandDriverOptions}
                                      component={AutocompleteField}
                                    />
                                  </Grid>
                                </div>
                                <ReactECharts
                                  style={{ height: "450px", width: "100%" }}
                                  option={
                                    scenariooption["series"].length > 0
                                      ? scenariooption
                                      : null
                                  }
                                />
                              </>
                            )}
                        </React.Fragment>
                      ) : (
                        <DataGridTable
                          height="400px"
                          onGridReady={(e) => setGridApi(e.api)}
                          suppressRowTransform={true}
                          columnDefs={
                            displayData === "Agg" ? colDef : colDefNonAgg
                          }
                          rowData={
                            displayData === "Agg"
                              ? scenarioResult
                              : scenarioResultNonAgg
                          }
                          borders={false}
                        />
                      )}
                    </React.Fragment>
                  </Section>
                </Grid>
              )}
            </React.Fragment>
          ) : (
            <Grid item md={12}>
              <CustomPlaceholder />
            </Grid>
          )
        ) : (
          <Grid item md={12}>
            <Section title={""}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" gutterBottom>
                  Please select <b>Category Name</b>,<b>Scenario Name</b>
                </Typography>
              </div>
            </Section>
          </Grid>
        )}
      </Grid>
      {showFeatureImp && (
        <Grid item md={12} sx={{ mt: 2 }}>
          <FeatureImportance
            isScenarioSimulation
            details={values?.categoryNameScenario}
            featureDetails={featureDetails}
            keys={values.keys}
          />
        </Grid>
      )}
    </Grid>
  );
}
