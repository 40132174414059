import { Button } from "@mui/material";
import styled from "@emotion/styled";
export const BootstrapButton = styled(Button)`
  box-shadow: none;
  text-transform: none;
  font-size: 12px;
  padding: 6px 12px;
  line-height: 1.5;
  background-color: #e5eeff;
  border-color: #e5eeff;
  color: #000;
  :hover {
    background-color: #b9cfff;
    box-shadow: none;
    font-weight: bold;
  }

  background-color: ${({ active }) => `${active ? "#B9CFFF" : "#E5EEFF"}`};
  font-weight: ${({ active }) => `${active ? "bold" : 400}`};

  .hidebutton {
    display: "none";
  }
  .displaybutton {
    display: "block";
  }
`;
