import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetForecastFilename() {
  const path = "forecastAdjustment/getFile";

  const { data, isLoading, isError, error } = useQuery(
    ["getForecastAdjustmentFilename"],
    () => getApi(path),
    {
      enabled: true,
    }
  );
  const fileName = [];
  const { showError } = useNotify();

  useEffect(() => {
    if (isError && error?.response?.data) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      fileName.push({ label: element, value: element });
    });
  }

  return {
    fileName,
    isLoading,
    data,
    isError,
    error,
  };
}
