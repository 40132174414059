import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authconfig";
import logo from "../images/unilever-logo.png";
import { Fab, Typography } from "@mui/material";

export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Fab
      onClick={() => instance.loginPopup(loginRequest)}
      color="inherit"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px",
        height: "10vh",
        backgroundColor: "#225CF1",
      }}
    >
      <img src={logo} style={{ paddingTop: "1vh", height: "7vh" }} alt="logo" />
      <Typography
        sx={{ fontSize: "2.8vh", color: "#fff", fontFamily: "Poppins" }}
      >
        Sign-in using Unilever Id
       
      </Typography>
    </Fab>
  );
};
