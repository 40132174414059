import React, { useState } from "react";
import OutQuarteFy from "./SeasonalityFy";
import ReactECharts from "echarts-for-react";
import OverAllVolume from "../../components/CommonTableHeader/Json/OverAllVolume.json";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Field, useFormikContext } from "formik";
import ListItemText from "@mui/material/ListItemText";

import Checkbox from "@mui/material/Checkbox";

import { color } from "views/DemandForecast/mappings";
import AutocompleteField from "components/form-fields/AutocompleteField";
export default function OutQuarterFy({ responsedata, term, season, decimals }) {
  const { values } = useFormikContext();

  const [cagr, setCagr] = React.useState();
  const data = responsedata;
  const [chartInstance, setchartInstance] = React.useState(null);
  const xAxis = [];
  const yAxis = [];

  const legend = [];
  const names = [
    { value: "2Year_CAGR", label: "2 year CAGR" },
    { value: "3Year_CAGR", label: "3 year CAGR" },
    { value: "4Year_CAGR", label: "4 year CAGR" },
  ];
  const growthLine = [];
  {
    data?.map((i) => {
      if (
        i.index !== "2023 SNOP" &&
        i.index !== "2022 SNOP" &&
        i.index !== "2023 Optimistic" &&
        i.index !== "2022 Optimistic"
      ) {
        xAxis.push(i.index);
        yAxis.push(i.FY);
        growthLine.push(i["FYLKL"]);
      }
    });
  }
  const series = [
    {
      name: "FY",
      data: yAxis,
      type: "bar",
      barGap: 1.3,
      color: color[8],
      label: {
        normal: {
          formatter: function (params) {
            var val = params.value.toFixed(decimals);
            return val;
          },
          show: true,
          position: "top",
        },
      },
    },
    {
      name: "Growth",
      data: growthLine,
      type: "line",
      color: color[4],
      yAxisIndex: 1,
    },
  ];
  const optionsfy = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value) => value.toFixed(decimals),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          name: term + "-" + season + "-" + "Seasonality-Fy",
        },
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      // bottom: "9%",
      containLabel: true,
    },
    // label: {
    //   rotate: 90,
    // },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxis,
    },

    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],
    series: series,
  };

  // const [cagrName, setCagrName] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCagrName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  values.cagrSeasonality?.forEach((value, index) => {
    const cagr = [];
    data.map((i) => {
      return cagr.push(i[value.value]);
    });

    legend.push(value.value);
    series.push({
      name: value.value,
      type: "line",
      data: cagr,
      color: color[index + 2],
      yAxisIndex: 1,
    });
  });
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {term === "Vol" && (
          <Grid item xs={3} sx={{ mt: 2 }}>
            <Field
              label="Select CAGR"
              multiple
              name="cagrSeasonality"
              options={names}
              component={AutocompleteField}
            />
          </Grid>
        )}
      </Grid>

      <ReactECharts
        style={{ height: "300px", width: "100%" }}
        option={optionsfy}
        ref={(e) => {
          const instance = e?.getEchartsInstance();
          setchartInstance(instance);
        }}
      />
    </React.Fragment>
  );
}
