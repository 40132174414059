import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";
export default function useGetFaLossTreeV2(decimal = 4) {
  const path = `/sales_and_forecast/fa_loss_tree`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();
  const colDef = [];
  const rowData = [];
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);
  if (Array.isArray(data) && data.length) {
    data.forEach((dataItem) => {
      rowData.push(dataItem);
    });
  }
  if (Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((header) => {
      if (header !== "SubCategory" && header !== "heirarchy") {
        colDef.push({
          field: header,
          minWidth: 150,
          cellStyle: getStyles,
          headerTooltip: header,
          valueFormatter: (params) => getFormatValue(params, decimal),
        });
      }
    });
  }
  return {
    isLoadingV2: isLoading,
    colDefV2: colDef,
    mutateV2: mutate,
    data,
    rowDataV2: rowData,
  };
}
