import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import PublicIcon from "@mui/icons-material/Public";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export const getHierarchy = (values) => {
  const hierarchy = values.hierarchyChannel?.map((f) => f.hierarchy);
  return hierarchy;
};

export const initialValues = {
  filename: "",
  savedName:"",
  filters: {
    Channel_Split: [],
    BasePack: [],
    Description: [],
    Sub_Category: [],
    Modified_Brand: [],
    Brand_Variant: [],
    Segment: [],
    UOM: [],
    Pack_Cluster: [],
    COTC_Cell: [],
    MD_Cell: [],
    Segmentation: [],
    Price_List: [],
    Status: [],
    CP_Type: [],
    Cluster: [],
    Branch: [],
    Big_C: [],
    Category: [],
    Pack_Format: [],
    Format1: [],
    Format2: [],
    Supply_Format: [],
    P1_P2_Format: [],
    PP_Tagging: [],
    Seasonal_Tagging: [],
    Innovation_Tagging: [],
    Tagg_1: [],
    Tagg_2: [],
  },
  nm: "",
  ratio: "",
  decimal:0,
  demandForecastUploadedFile: "Default",
  openDrawer: true,
  clickApply: false,
  hierarchyChannel: [{hierarchy: "Channel_Split"}],
};

export const hireachyDropDown = [
  {
    label: "Channel Split",
    value: "Channel_Split",
  },
  {
    label: "BasePack",
    value: "BasePack",
  },
  {
    label: "Description",
    value: "Description",
  },
  {
    label: "Modified_Brand",
    value: "Modified_Brand",
  },
  {
    label: "Brand_Variant",
    value: "Brand_Variant",
  },
  {
    label: "Pack_Cluster",
    value: "Pack_Cluster",
  },
  {
    label: "UOM",
    value: "UOM",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: "Sub_Category",
    value: "Sub_Category",
  },
  {
    label: "MD_Cell",
    value: "MD_Cell",
  },
  {
    label: "COTC_Cell",
    value: "COTC_Cell",
  },
  {
    label: "Segmentation",
    value: "Segmentation",
  },
  {
    label: "Price_List",
    value: "Price_List",
  },
  {
    label: "Status",
    value: "Status",
  },
  {
    label: "CP_Type",
    value: "CP_Type",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "Branch",
    value: "Branch",
  },
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "Pack_Format",
    value: "Pack_Format",
  },
  {
    label: "Format1",
    value: "Format1",
  },
  {
    label: "Format2",
    value: "Format2",
  },
  {
    label: "Supply_Format",
    value: "Supply_Format",
  },
  {
    label: "P1_P2_Format",
    value: "P1_P2_Format",
  },
  {
    label: "PP_Tagging",
    value: "PP_Tagging",
  },
  {
    label: "Seasonal_Tagging",
    value: "Seasonal_Tagging",
  },
  {
    label: "Innovation_Tagging",
    value: "Innovation_Tagging",
  },
  {
    label: "Tagg_1",
    value: "Tagg_1",
  },
  {
    label: "Tagg_2",
    value: "Tagg_2",
  },
];

export const filterData = [
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel Split",
        name: "Channel_Split",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Basepack",
        name: "BasePack",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "Sub_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "MD Cell",
        name: "MD_Cell",
        component: MultiSelectDropdown,
      },
      {
        label: "COTC Cell",
        name: "COTC_Cell",
        component: MultiSelectDropdown,
      },
      {
        label: "Segmentation",
        name: "Segmentation",
        component: MultiSelectDropdown,
      },
      {
        label: "Price List",
        name: "Price_List",
        component: MultiSelectDropdown,
      },
      {
        label: "Status",
        name: "Status",
        component: MultiSelectDropdown,
      },
      {
        label: "CP Type",
        name: "CP_Type",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Big C",
        name: "Big_C",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Pack Format",
        name: "Pack_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
      {
        label: "Supply Format",
        name: "Supply_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "P1/P2 Format",
        name: "P1_P2_Format",
        component: MultiSelectDropdown,
      },
    ],
  },

  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "PP Tagging",
        name: "PP_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Innovation Tagging",
        name: "Innovation_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Tagg 1",
        name: "Tagg_1",
        component: MultiSelectDropdown,
      },
      {
        label: "Tagg 2",
        name: "Tagg_2",
        component: MultiSelectDropdown,
      },
    ],
  },
];
