const val = "REACT_APP";

export class Configuration {
  proc = process.env ?? {};
  win = window.exp_env ?? {};

  static isDebug() {
    return (process.env ?? {})["REACT_APP_DEBUG"]?.toUpperCase() === "TRUE";
  }

  static isLocal() {
    return (process.env ?? {})["REACT_APP_LOCAL"]?.toUpperCase() === "TRUE";
  }

  GetConfig(key) {
    const fullkey = `${val}_${key.toUpperCase()}`;
    const local = Configuration.isLocal();

    if (!local && this.win[fullkey]) {
      return this.win[fullkey];
    }

    if (this.proc[fullkey]) {
      return this.proc[fullkey];
    }

    return undefined;
  }
}
