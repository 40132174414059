import React from "react";
import { Grid, IconButton } from "@mui/material";
import { FieldArray, Field } from "formik";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AutocompleteField from "components/form-fields/AutocompleteField";

const HierarchyFieldArray = ({ values, options, fieldName }) => {
  return (
    <FieldArray name={`${fieldName}`}>
      {(arrayHelper) => (
        <Grid container spacing={1}>
          {!!values[fieldName]?.length &&
            values[fieldName].map((_, index) => (
              <>
                <Grid item md={2}>
                  <Field
                    options={options}
                    name={`${fieldName}.${index}.hierarchy`}
                    label={`Row Label ${index + 1}`}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    value={values[fieldName][index]["hierarchy"]}
                    component={AutocompleteField}
                    required
                  />
                </Grid>
                <Grid item md={1}>
                  {values[fieldName].length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size={"small"}
                      onClick={() => arrayHelper.remove(index)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                  {index === values[fieldName].length - 1 && (
                    <IconButton
                      size={"small"}
                      onClick={() => {
                        arrayHelper.insert(values[fieldName].length, {
                          hierarchy: "",
                        });
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  )}
                </Grid>
              </>
            ))}
        </Grid>
      )}
    </FieldArray>
  );
};

export default HierarchyFieldArray;
