import React, { useState,useEffect } from "react";
import { Grid, Button, Typography, Skeleton } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useTheme } from "@emotion/react";

import Section from "components/Section";
import Autocomplete from "components/Autocomplete";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import Loader from "components/Loader";
import DataGridTable from "components/DataGridTable/DataGridTable";
import useGetFeatureTables from "services/DemandForecast/useGetFeatureTables";
import useGetFeatureMonths from "services/DemandForecast/useGetFeatureMonths";
import useGetFeaturesList from "services/DemandForecast/useGetFeaturesList";
import useGetFeatureDetails from "services/DemandForecast/useGetFeatureDetails";
import useGetTDPlist from "services/DemandForecast/useGetTDPlist";

import { colDefGT, colDefMT, colDefEcom } from "../formData";

export default function Features() {
  const theme = useTheme();
  const [featureMonth, setFeatureMonth] = useState(null);
  const { featureTables, featureTablesLoading, featureTablesMutate } = useGetFeatureTables();
  const { featureMonths } = useGetFeatureMonths(featureMonth?.value);
  const { featureName, featuresLoading } = useGetFeaturesList();
  const { isLoading, mutate, rowData } = useGetFeatureDetails();
  const [tableName, setTableName] = useState(null);
  const { values, setFieldValue } = useFormikContext();
  const { tdpList, tdpLoading } = useGetTDPlist(tableName?.value, featureMonth?.value);

  useEffect(() => {
    if (
      featureMonth?.value 
    ) {
      featureTablesMutate({
        month: featureMonth?.value
      });
    }
}, [featureMonth?.value]);

  const onApply = () => {
    mutate({
      table_name: tableName?.value,
      features: values.featuresFilter,
      tdp: values.tdpList,
      month:featureMonth?.value
    });
  };

  return (
    <React.Fragment>
      <Section title={"Feature Importance - Basepack Level"}>
        <Grid flex={1} justifyContent={"flex-start"} container spacing={2}>
        <Grid item md={2}>
            <Autocomplete
              label="Month"
              value={featureMonth}
              onChange={(e, value) => {
                setFieldValue("featuresFilter", []);
                setFieldValue("tdpList", []);
                setTableName("");
                setFeatureMonth(value)
              }}
              options={featureMonths}
            />
          </Grid>
          <Grid item md={3}>
            {!featureTablesLoading?
            <Autocomplete
              label="File Name"
              value={tableName}
              onChange={(e, value) => {
                setFieldValue("featuresFilter", []);
                setFieldValue("tdpList", []);
                setTableName(value);
              }}
              options={featureTables}
            />:<Skeleton height={50}/>}
          </Grid>

          <Grid item md={3}>
            {!featuresLoading && !featureTablesLoading?
            <Field
              label="Features"
              name="featuresFilter"
              options={featureName}
              multiple
              noBorders={false}
              variant="outlined"
              fullWidth
              component={MultiSelectDropdownF}
            />:<Skeleton height={50}/>}
          </Grid>

          <Grid item md={3}>
          {!tdpLoading && !featuresLoading && !featureTablesLoading?
            <Field
              label= {tableName && (tableName?.value.startsWith('GT') ? "TDP" : tableName?.value.startsWith('MT') ? "MOC" : "BM" )}
              name="tdpList"
              options={tdpList}
              multiple
              noBorders={false}
              variant="outlined"
              fullWidth
              component={MultiSelectDropdownF}
            />:<Skeleton height={50}/>}
          </Grid>

          <Grid item md={3}>
            <Button
              variant="contained"
              onClick={onApply}
              disabled={
                !tableName?.value ||
                values.featuresFilter.length === 0 ||
                values.tdpList.length === 0
              }
            >
              Apply
            </Button>
          </Grid>
        </Grid>

        {tableName?.value &&
        values.featuresFilter.length !== 0 &&
        values.tdpList.length !== 0 ? (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <DataGridTable
                onGridReady={(e) => {
                  e.api.sizeColumnsToFit();
                }}
                height="400px"
                suppressRowTransform={true}
                columnDefs={tableName?.value.startsWith('GT') ? colDefGT : tableName?.value.startsWith('MT') ? colDefMT : colDefEcom}
                rowData={rowData}
                borders={false}
              />
            )}
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", margin: 3 }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mt: 2, color: theme.palette.text.primary }}
            >
              Please select the above filters to display the data
            </Typography>
          </div>
        )}
      </Section>
    </React.Fragment>
  );
}
