import React, { useState, useEffect } from "react";
import Section from "components/Section";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import ButtonGroup from "components/ButtonGroup";

import OverAllTable from "./OverAllTable";
import { TypographyNameDisplay } from "./hooks/TypographyNameDisplay";
import { exportToCSV } from "./hooks/exportToCSV";
import useGetOverallTable from "services/outQuarter/useGetOverallTable";

import SalesTable from "./SalesTable";

export default function OutQuarterSummaryDashboard(props) {
  const { values, enableAPIs, decimals, measure } = props;
  const [checkedOutQuarter, setCheckedOutQuarter] = useState("table");
  const [gridApi1, setGridApi1] = useState();
  const [gridApi4, setGridApi4] = useState();
  let gridApi2, gridApi3;
  const [chart, setChart] = useState("qtr");
  let outQuarterSummary = "Summary";
  const [gridOptions1] = useState({
    alignedGrids: [],
  });
  const [gridOptions2] = useState({
    alignedGrids: [],
  });
  const [gridOptions4] = useState({
    alignedGrids: [],
  });
  const handleChange1 = (event) => {
    setChart(event.target.value);
  };
  const { rowDataValue, mutate, isLoading, data, qtrOptions } =
    useGetOverallTable(measure, decimals);
  useEffect(() => {
    if (values.filename) {
      mutate({
        term: measure,
        filename: values.filename,
        ...values.filters,
      });
    }
  }, [enableAPIs, mutate, measure]);
  return (
    <React.Fragment>
      {values?.filename.length !== 0 && values.clickApply === true ? (
        <Section
          title={outQuarterSummary}
          actions={
            <React.Fragment sx={{ m: 2 }}>
              <ButtonGroup
                setValue={setCheckedOutQuarter}
                value={checkedOutQuarter}
                options={[
                  { label: "Table", value: "table" },
                  { label: "Chart", value: "chart" },
                ]}
                primaryColor={"#31b2dc"}
                width={84}
              />
              {checkedOutQuarter === "table" && (
                <Tooltip title="Download table">
                  <CloudDownloadIcon
                    sx={{ mr: 1 }}
                    onClick={() =>
                      exportToCSV(
                        gridApi1,
                        gridApi2,
                        gridApi3,
                        "Summary",
                        values.filters
                      )
                    }
                    style={{
                      fontSize: "34px",
                      marginLeft: "9px",
                      color: "#2036C7",
                    }}
                  />
                </Tooltip>
              )}
            </React.Fragment>
          }
        >
          {checkedOutQuarter === "chart" && (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={chart}
                onChange={handleChange1}
              >
                <FormControlLabel value="qtr" control={<Radio />} label="QTR" />
                <FormControlLabel value="fy" control={<Radio />} label="FY" />
              </RadioGroup>
            </FormControl>
          )}

          <Typography
            style={{
              fontStyle: "20px",
              fontSize: "bold",
              marginTop: "10px",
            }}
          >
            {TypographyNameDisplay(measure)}
          </Typography>
          <SalesTable
            term={measure}
            checked={checkedOutQuarter}
            chart={chart}
            enableAPIs={enableAPIs}
            decimals={decimals}
            setGridApi={setGridApi4}
            gridApi={gridApi4}
            exportToCSV={exportToCSV}
            gridOptions={gridOptions4}
            isLoading={isLoading}
            rowDataValue={rowDataValue}
            qtrOptions={qtrOptions}
          />
          <OverAllTable
            term={measure}
            checked={checkedOutQuarter}
            chart={chart}
            enableAPIs={enableAPIs}
            decimals={decimals}
            setGridApi={setGridApi1}
            gridApi={gridApi1}
            exportToCSV={exportToCSV}
            gridOptions={gridOptions1}
            isLoading={isLoading}
            rowDataValue={rowDataValue}
            data={data}
            qtrOptions={qtrOptions}
          />
        </Section>
      ) : (
        <Section title={"Summary"}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body2" gutterBottom>
              Please select <b>filename</b> for OutQuarter and click on apply
              button
            </Typography>
          </div>
        </Section>
      )}
    </React.Fragment>
  );
}
