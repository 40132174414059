import Loader from "components/Loader";
import React, { useMemo } from "react";
import SummaryTableWrapper from "views/Summary/components/SummaryTableWrapper";

export default function ChannelWisePerformanceTable(props) {
  const { height, gridApi, setGridApi, colDef, data, isLoading } = props;
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Row Label",
      width: 300,
      pinned: "left",
      cellRendererParams: {
        suppressCount: true,
      },
      headerTooltip: "Row Label",
      cellStyle: () => {
        return {
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        };
      },
    };
  }, []);
  const getDataPath = useMemo(() => {
    return (data) => {
      return data["heirarchy"];
    };
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <SummaryTableWrapper
          height={height}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          animateRows={true}
          gridApi={gridApi}
          setGridApi={setGridApi}
          getDataPath={getDataPath}
          columnDefs={colDef}
          rowData={data ?? []}
        />
      )}
    </React.Fragment>
  );
}
