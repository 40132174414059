import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DataGridTable from "components/DataGridTable/DataGridTable";
import Loader from "components/Loader";
import React, { useState } from "react";
import {
  colDefSeasonality2CaGR,
  colDefSeasonality3CaGR,
  colDefSeasonality4CaGR,
} from "./hooks/colDefDefSeasonality";
import { useFormikContext } from "formik";

export default function CagrSeason(props) {
  const {
    isLoading,
    colDef,
    chart,
    choice,
    data,
    optionsQtr,
    term,
    season,
    decimals,
    setGridApi,

    ...rest
  } = props;
  const [cagr, setCagr] = useState(2);
  const { values } = useFormikContext();
  return (
    <React.Fragment>
      {isLoading === true ? (
        <Loader />
      ) : (
        <React.Fragment>
          {choice === "table" && (
            <React.Fragment>
              <FormControl size="small" sx={{ mt: 3, width: 200 }}>
                <InputLabel id="demo-simple-select-label">
                  Select CAGR
                </InputLabel>
                <Select
                  value={cagr}
                  label="Select CAGR"
                  onChange={(event) => setCagr(event.target.value)}
                >
                  <MenuItem value={2}>2 yr CAGR</MenuItem>
                  <MenuItem value={3}>3 yr CAGR</MenuItem>
                  <MenuItem value={4}>4 yr CAGR</MenuItem>
                </Select>
              </FormControl>
              <DataGridTable
                {...rest}
                height="400px"
                onGridReady={(e) => {
                  setGridApi(e.api)
                  e.api.sizeColumnsToFit()
                }}
                suppressRowTransform={true}
                columnDefs={
                  cagr === 2
                    ? colDefSeasonality2CaGR(decimals,season,values.filename)
                    : cagr === 3
                    ? colDefSeasonality3CaGR(decimals,season,values.filename)
                    : colDefSeasonality4CaGR(decimals,season,values.filename)
                }
                rowData={data}
                borders={false}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
