import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetMRPDetails() {
  const path = "/scenario_simulation/getMRPDetails";
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const mrpDetails = [];
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
        mrpDetails.push(item);
    });
  }

  return {
    IsLoadingData: isLoading,
    isSuccessData: isSuccess,
    mutateMRPDetails: mutate,
    mrpDetails,
    isError,
  };
}
