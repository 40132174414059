import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { getFormatValue, getStyles } from "utils/constants";
import PublicIcon from "@mui/icons-material/Public";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export const initialValues_PerformanceSummary = {
  volumeCagr: [],
  filters: {
    "ForeCast Channel": [],
    Basepack: [],
    Cluster: [],
    Description: [],
    "Calendar Quarter": [],
    "MOC Month": [],
    "Calendar Month": [],
    "Ten Day Period": [],
    "Brand variant": [],
    "Modified Brand": [],
    Pack_Cluster: [],
    UOM: [],
    Segment: [],
    MD_Cell: [],
    COTC_Cell: [],
    "Sub Category": [],
    Status: [],
    Segmentation: [],
    CP_Type: [],
    Price_List: [],
    Pack_Format: [],
    Format1: [],
    Format2: [],
    Supply_Format: [],
    P1_P2_Format: [],
    PP_Tagging: [],
    Seasonal_Tagging: [],
    Innovation_Tagging: [],
    Category: [],
    Branch: [],
    Tagg_1: [],
    Tagg_2: [],
    QTD: [],
    Big_C: [],
  },
  openDrawer: true,
  decimal:0,
  demandForecastUploadedFile:[],
  uploadCheck: false,
  filename: "",
  hierarchyChannel: [{ hierarchy: "ForeCast Channel" }],
  hierarchyCluster: [{ hierarchy: "Cluster" }],
  hierarchySegment: [{ hierarchy: "Segment" }],
};
export const filtersData = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "Basepack",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: HourglassEmptyIcon,
    filters: [
      {
        label: "Calendar Quarter",
        name: "Calendar Quarter",
        component: MultiSelectDropdown,
      },
      {
        label: "MOC Month",
        name: "MOC Month",
        component: MultiSelectDropdown,
      },
      {
        label: "Calendar Month",
        name: "Calendar Month",
        component: MultiSelectDropdown,
      },
      {
        label: "Ten Day Period",
        name: "Ten Day Period",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "SubCategory",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Sales Business",
      //   name: "Sales_Business",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Sales Business Group",
      //   name: "Sales_Business_Group",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top_Packs",
        component: MultiSelectDropdown,
      },
    ],
  },
  //     {
  //       label: "Active/Delisted",
  //       name: "Active_Delisted",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "Price Point Tagging",
  //       name: "Price_point_Tagging",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "Scope Flag",
  //       name: "Scope_Flag",
  //       component: MultiSelectDropdown,
  //     },
  //   ],
  // },
];
export const hireachyDropDown = [
  {
    label: "ForeCast Channel",
    value: "ForeCast Channel",
  },
  {
    label: "Basepack",
    value: "Basepack",
  },
  {
    label: "Description",
    value: "Description",
  },
  {
    label: "Modified Brand",
    value: "Modified Brand",
  },
  {
    label: "Brand variant",
    value: "Brand variant",
  },
  {
    label: "Calendar Quarter",
    value: "Calendar Quarter",
  },
  {
    label: "MOC Month",
    value: "MOC Month",
  },
  {
    label: "Calendar Month",
    value: "Calendar Month",
  },
  {
    label: "Ten Day Period",
    value: "Ten Day Period",
  },
  {
    label: "Pack_Cluster",
    value: "Pack_Cluster",
  },
  {
    label: "UOM",
    value: "UOM",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: "Sub Category",
    value: "Sub Category",
  },
  {
    label: "MD_Cell",
    value: "MD_Cell",
  },
  {
    label: "COTC_Cell",
    value: "COTC_Cell",
  },
  {
    label: "Segmentation",
    value: "Segmentation",
  },
  {
    label: "Price_List",
    value: "Price_List",
  },
  {
    label: "Status",
    value: "Status",
  },
  {
    label: "CP_Type",
    value: "CP_Type",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "Branch",
    value: "Branch",
  },
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "Pack_Format",
    value: "Pack_Format",
  },
  {
    label: "Format1",
    value: "Format1",
  },
  {
    label: "Format2",
    value: "Format2",
  },
  {
    label: "Supply_Format",
    value: "Supply_Format",
  },
  {
    label: "P1_P2_Format",
    value: "P1_P2_Format",
  },
  {
    label: "PP_Tagging",
    value: "PP_Tagging",
  },
  {
    label: "Seasonal_Tagging",
    value: "Seasonal_Tagging",
  },
  {
    label: "Innovation_Tagging",
    value: "Innovation_Tagging",
  },
  {
    label: "Tagg_1",
    value: "Tagg_1",
  },
  {
    label: "Tagg_2",
    value: "Tagg_2",
  },

  {
    label: "Big_C",
    value: "Big_C",
  },
];

export const colDef = (decimal = 4) => [
  {
    headerName: "QGP",
    field: "QGP VOL",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "QGP",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "QGP Value in Lakhs",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "QGP Value in Lakhs",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    headerName: "Sales Vol",
    field: "Primary Sales Qty  in RPM",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Primary Sales Qty  in RPM",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "Primary GSV Rs Lacs (with POGO)",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Primary GSV Rs Lacs (with POGO)",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  // {
  //   field: "Sales in Cr",
  //   minWidth: 150,
  //   cellStyle: getStyles,
  //   headerTooltip: "Sales in Cr",
  //   valueFormatter: (params) => getFormatValue(params, decimal),
  // },
  // {
  //   field: "QGP in Cr",
  //   minWidth: 150,
  //   cellStyle: getStyles,
  //   headerTooltip: "QGP in Cr",
  //   valueFormatter: (params) => getFormatValue(params, decimal),
  // },
  {
    field: "GAP Val",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "GAP Val",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "GAP Vol",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "GAP Vol",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "Vol Achd%",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Achd%",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
  {
    field: "Val Achd%",
    minWidth: 150,
    cellStyle: getStyles,
    headerTooltip: "Achd%",
    valueFormatter: (params) => getFormatValue(params, decimal),
  },
];
