import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormControl,
  Button,
  Typography,
} from "@mui/material";
import ButtonGroup from "components/ButtonGroup";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import { StyledCard } from "components/Section/Section.styles";
import { useFormikContext } from "formik";
import React from "react";
import { useEffect, useState, useCallback } from "react";
import useGetFaLossTree from "services/SalesForecastVersioning/useGetFaLoss";
import useGetFaLossTreeQGP from "services/SalesForecastVersioning/useGetFaLossQGP";
import useGetFaLossTreeV2 from "services/SalesForecastVersioning/useGetFalossV2";
import ChannelWisePerformanceTable from "views/ForecastVersioningPerformanceSummary/Component/ChannelWisePerformanceTable";
import { faLossDropdown } from "../formData";

export default function FaLossDetails({ enableAPIs }) {
  // const [decimal, setDecimal] = useState(0);
  const [checked, setChecked] = useState("table");
  const { values, setFieldValue } = useFormikContext();
  const [gridApi1, setGridApi1] = useState();
  const [gridApi2, setGridApi2] = useState();
  const [gridApi3, setGridApi3] = useState();
  const { isLoading, mutate, colDef, rowData } = useGetFaLossTree(values.decimal);
  const { isLoadingQGP, mutateQGP, colDefQGP, rowDataQGP } =
    useGetFaLossTreeQGP(values.decimal);
  const { isLoadingV2, mutateV2, colDefV2, rowDataV2 } =
    useGetFaLossTreeV2(values.decimal);
  const onApply = useCallback(
    (values) => {
      mutate({
        heirarchy: values.hierarchyFaLoss?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: "2023_01",
        key: "V1",
      });
    },
    [mutate]
  );
  const onApplyQGP = useCallback(
    (values) => {
      mutateQGP({
        heirarchy: values.hierarchyFaLoss?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: "2023_01",
        key: "QGP",
      });
    },
    [mutateQGP]
  );
  const onApplyV2 = useCallback(
    (values) => {
      mutateV2({
        heirarchy: values.hierarchyFaLoss?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: "2023_01",
        key: "V2",
      });
    },
    [mutateV2]
  );
  // useEffect(() => {
  //   onApply(values);
  //   onApplyQGP(values);
  //   onApplyV2(values);
  // }, [onApply, enableAPIs]);

  const onClickApply = (values) => {
    onApply(values);
    onApplyQGP(values);
    onApplyV2(values);
  };
  const filteredOptions = faLossDropdown.filter(
    (option) =>
      !values.hierarchyFaLoss?.some((h) => h.hierarchy === option.value)
  );
  const exportCsv=()=>{
    if(gridApi1){
      gridApi1.exportDataAsCsv();
    }
    if(gridApi2){
      gridApi2.exportDataAsCsv();
    }
    if(gridApi3){
      gridApi3.exportDataAsCsv();
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} md={12}>
            <Grid item xs={10} md={10}></Grid>
            <Grid item md={2} xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  // onChange={(event) => setDecimal(event.target.value)}
                  onChange={(event) => setFieldValue("decimal",event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <Grid item md={12}>
        <Section
          title={"FA Loss Tree"}
          onSettings={
            <ExportOption
              onClick={()=>exportCsv()}
            />
        }
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <HierarchyFieldArray
                values={values}
                options={filteredOptions}
                fieldName={"hierarchyFaLoss"}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => onClickApply(values)}
                sx={{ mt: 2 }}
              >
                Apply
              </Button>
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, marginTop: "25px" }}
              >
                QGP
              </Typography>
              <ChannelWisePerformanceTable
                height={378}
                gridApi={gridApi2}
                setGridApi={setGridApi2}
                colDef={colDefQGP}
                isLoading={isLoadingQGP}
                data={rowDataQGP}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, marginTop: "25px" }}
              >
                V1
              </Typography>
              <ChannelWisePerformanceTable
                height={378}
                gridApi={gridApi1}
                setGridApi={setGridApi1}
                colDef={colDef}
                isLoading={isLoading}
                data={rowData}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, marginTop: "25px" }}
              >
                V2
              </Typography>
              <ChannelWisePerformanceTable
                height={378}
                gridApi={gridApi3}
                setGridApi={setGridApi3}
                colDef={colDefV2}
                isLoading={isLoadingV2}
                data={rowDataV2}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
    </Grid>
  );
}
