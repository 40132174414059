export const initialScenarioValues = {
  channelName: "",
  channelNameScenario: "",
  createscenarioCategoryName: "",
  createscenarioName: "",
  features: [],
  finishEnable: false,
  dropdown: [
    // { label: "Forecast", value: "Accenture_Forecast" },
    // { label: "Forecast in RPM", value: "ACC Forecast in RPM" },
    { label: "Forecast", value: "Falcon_Forecast" },
    { label: "Forecast in RPM", value: "Falcon Forecast in RPM" },
    { label: "Forecast in Value", value: "Forecast in Value" },
    { label: "CV", value: "CV" },
  ],
  demandDriverOptionsMRP: [{ label: "MRP", value: "MRP" }],
  demandDriverOptionsGRM: [{ label: "GRM", value: "GRM" }],
  demandDriverOptions: [
    { label: "MRP", value: "MRP" },
    { label: "GRM", value: "GRM" },
    // { label: "TTS", value: "tts" },
  ],
  cluster: [],
  feature_importance_filters: {
    tdp: [],
    channel: [],
    cluster_name: [],
    basepack: [],
    basepackDescription: [],
    anaplanCustomer: [],
  },
  // scale: [{ label: "Forecast", value: "Accenture_Forecast" }],
  scale: [{ label: "Forecast", value: "Falcon_Forecast" }],
  demandDriver: [],
  variable_type: "Final Feature Importance Description",
  pageDetails: "dashboard",
  startDate: [],
  endDate: [],
  isTdpValid: false,
  categoryNameScenario: "",
  createscenarioDesc: "",
  startTdp: "",
  endTdp: "",
  keys: [],
  dashboardkeys: [],
  historykeys: [],
};

export const featureOptions = ["MRP", "GRM"];
