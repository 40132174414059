import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetLineWise(decimal = 4, base) {
  const path = `/sales_and_forecast/linewise`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const colDefLinewise = [
    {
      field: `${base}`,
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: `${base}`,
    },
    {
      field: "+Ve",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "+Ve",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: "-Ve",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "-Ve",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: "0",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "0",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: "Grand_Total",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "Grand_Total",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
  ];

  return {
    isLoadingLinewise: isLoading,
    colDefLinewise,
    mutate,
    rowDataLinewise: data,
  };
}
