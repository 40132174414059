import { Box } from "@mui/system";
import {
  RightSideContainer,
  ViewportContainer,
} from "components/AppLayout/AppLayout.styles";
import { Formik } from "formik";
import React from "react";
import { initialScenarioValues } from "../formData";
import ScenarioSimultaionDashboard from "./ScenarioSimulationDashboard";


const Content = () => {

  return (
    <ViewportContainer>
      <RightSideContainer>
        <Box sx={{ margin: 3 }}>
         <ScenarioSimultaionDashboard/>
         
        </Box>
      </RightSideContainer>
    </ViewportContainer>
  );
};

export default function ScenarioSimulationDashboardMainPage() {
  return (
    <Formik initialValues={initialScenarioValues}>
      <Content />
    </Formik>
  );
}
