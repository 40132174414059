import DataGridTable from "components/DataGridTable/DataGridTable";
import React, { useEffect } from "react";
import useGetOverallTable from "services/outQuarter/useGetOverallTable";
import { useFormikContext } from "formik";
import { colDef, colDefCagr2, colDefCagr3, colDefCagr4 } from "./formData";
import ReactECharts from "echarts-for-react";
import OverAllFy from "./OverAllFy";
import Loader from "components/Loader";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
export default function SalesTable(props) {
  const {
    term,
    checked,
    gridApi,
    setGridApi,
    chart,
    enableAPIs,
    decimals,

    rowDataValue,
    isLoading,
    ...rest
  } = props;

  const { values } = useFormikContext();

  return (
    <React.Fragment>
      {isLoading === true ? (
        <Loader />
      ) : (
        <React.Fragment>
          {checked === "table" && (
            <DataGridTable
              {...rest}
              height="400px"
              onGridReady={(e) => setGridApi(e.api)}
              suppressRowTransform={true}
              columnDefs={colDef(decimals)}
              rowData={rowDataValue}
              borders={false}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
