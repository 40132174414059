import React, { useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Card,
  CardContent,
  FormControlLabel ,
  Checkbox
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import Autocomplete from "components/Autocomplete";
import useGetFileName from "services/DemandForecast/useGetFileName";



export default function StepOne() {
  const { values,setFieldValue } = useFormikContext();
  const [scenarioName, setScenarioName] = useState("");
  const [createscenarioCategoryName, setCreatescenarioCategoryName] = useState("");
  const { fileName } = useGetFileName();
  const [checked, setChecked]=useState({MRP:false,GRM:false,TTS:false})
  

  const handleNameChange = (e) => {
    const { value } = e.target;
    setScenarioName(value);
    setFieldValue("createscenarioName", value);
  };
  const handleNameChangeDesc = (e) => {
    const { value } = e.target;
    setScenarioName(value);
    setFieldValue("createscenarioDesc", value);
  };

  let newFeatures=[]

  for (const [key, value] of Object.entries(checked)) {
    if(value === true){
      newFeatures.push(key)
    }
  }

  const handleChange1 = (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, MRP: isChecked });
    updateFeatures({ ...checked, MRP: isChecked });
  };
  
  const handleChange2 = (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, GRM: isChecked });
    updateFeatures({ ...checked, GRM: isChecked });
  };
  
  const handleChange3 = (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, TTS: isChecked });
    updateFeatures({ ...checked, TTS: isChecked });
  };
  
  const handleChange4 = (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, Eco: isChecked });
    updateFeatures({ ...checked, Eco: isChecked });
  };
  const handleChange5 = (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, Temperature: isChecked });
    updateFeatures({ ...checked, Temperature: isChecked });
  };
  const updateFeatures = (updatedChecked) => {
    let newFeatures = [];
    for (const [key, value] of Object.entries(updatedChecked)) {
      if (value === true) {
        newFeatures.push(key);
      }
    }
    setFieldValue("features", newFeatures);
  };
  return (
    <div>
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ minWidth: 800, minHeight: 500 }}>
        <CardContent>
          <Grid sx={{ width: 700 }} container spacing={2} justifyItems="center">
            <Grid item md={12}>
              <Typography variant="caption" color="textSEcondary">
                In order to create a scenario please fill out the below details
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">Name of Scenario</Typography>
            </Grid>
            <Grid item md={12}>
              <Field
                as={TextField}
                fullWidth
                placeholder="Enter name of scenario (100 characters maximum)"
                variant="outlined"
                value={values.createscenarioName}
                onChange={handleNameChange}
                name="createscenarioName"
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">Scenario Description</Typography>
            </Grid>
            <Grid item md={12}>
              <Field
                as={TextField}
                fullWidth
                rows={10}
                placeholder="Enter scenario description(1000 characters maximum)"
                variant="outlined"
                value={values.createscenarioDesc}
                onChange={handleNameChangeDesc}
                name="createscenarioDesc"
              />
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item md={6}>
                <Grid item md={12} sx={{ mt: 2, ml: 2 }}>
                  <Typography variant="body2">Select Category</Typography>
                </Grid>
                <Grid item md={12} sx={{ mt: 2, ml: 2 }}>
                  <Autocomplete
                    label={"Select Category Name"}
                    options={fileName}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    value={values?.createscenarioCategoryName}
                    onChange={(e, value) => {
                      setFieldValue("features", []);
                      setFieldValue("bpOptions", []);
                      setFieldValue("cluster", []);
                      setFieldValue("startDate", []);
                      setFieldValue("endDate", []);
                      setFieldValue("isTdpValid", false);
                      setCreatescenarioCategoryName(value)
                      setFieldValue("createscenarioCategoryName",value?.value)
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item md={6}>
                <Grid item md={12} sx={{ mt: 2, ml: 2 }}>
                  <Typography variant="body2">Select Features</Typography>
                </Grid>
                <div>
                  <Grid item md={12} sx={{ mt: 0.7, ml: 2 }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked.MRP}
                            onChange={handleChange1}
                            color="primary"
                          />
                        }
                        label="MRP"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked.GRM}
                            onChange={handleChange2}
                            color="primary"
                          />
                        }
                        label="GRM"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked.TTS}
                            onChange={handleChange3}
                            color="primary"
                          />
                        }
                        label="TTS"
                      />
                       <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked.Eco}
                            onChange={handleChange4}
                            color="primary"
                          />
                        }
                        label="ECO"
                      />
                       <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked.Temperature}
                            onChange={handleChange5}
                            color="primary"
                          />
                        }
                        label="Temperature"
                      />
                    </FormControl>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
   
    </div>
  );
}
