import useGetFeatures from "services/DemandForecast/useGetFeatures";

export default function useFeatureImportance() {
  const { yAxis, mutate, featureImportance, isLoading } = useGetFeatures();

  const featureOptions = {
    grid: {
      left: "25%",
      right: "15%",
      width: "auto",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "value",
      },
    ],
    yAxis: [
      {
        type: "category",
        data: yAxis,
        axisPointer: {
          type: "shadow",
        },
        roundCap: true,
      },
    ],
    series: [
      {
        name: "feature importance",
        type: "bar",
        data: featureImportance,
        itemStyle: {
          borderRadius: [0, 15, 15, 0],
        },
        label: {
          show: true,
          rotate: 0,
          offset: [40, 0],
          position: "outside",
          align: "center",
          color: "#000",
          backgroundColor: "#F0F0F0",
          borderRadius: [20, 20, 20, 20],
          padding: [6, 6, 6, 6],
        },
        barWidth: 16,
      },
    ],
  };
  return {
    featureOptions,
    mutate,
    isLoading,
    featureImportance,
  };
}
