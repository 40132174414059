import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { useFormikContext, Field } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import SelectField from "components/form-fields/SelectField";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";
import useGetAccurancyDetails from "services/SalesForecastVersioning/Adoption/usegetAccurancyDetails";
import ChannelWisePerformanceTable from "views/ForecastVersioningPerformanceSummary/Component/ChannelWisePerformanceTable";
import { StyledCard } from "components/Section/Section.styles";
import useGetFileUploadedList from "services/DemandForecast/useGetFileUploadedList";

import { AdoptionDropdown } from "../../Adoption/formData";
import DeleteSummaryFiles from "../../Summary/components/DeleteSummaryFiles";
import SummaryUpload from "../../Summary/components/SummaryUpload";

export default function Accuracy({ enableAPIs, tag }) {
  // const [decimal, setDecimal] = useState(0);
  const [gridApi1, setGridApi1] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const { isLoading, mutate, colDef, rowData } =
    useGetAccurancyDetails(values.decimal);

  const { demandForecastUploadedFile, refetch } = useGetFileUploadedList({
    username: localStorage.getItem("username"),
    tag: tag,
  });

  const filteredOptions = AdoptionDropdown.filter(
    (option) =>
      !values.hierarchyAccurancy?.some((h) => h.hierarchy === option.value)
  );

  const onApply = useCallback(
    (values) => {
      mutate({
        heirarchy: values.hierarchyAccurancy?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
        input_type: values.uploadCheck ? "upload" : "default",
        username: localStorage.getItem("username"),
      });
    },
    [mutate]
  );

  useEffect(() => {
    onApply(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onApply, enableAPIs]);

  const exportCsv = () => {
    if (gridApi1) {
      gridApi1.exportDataAsCsv();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} md={12} justifyContent="flex-end">
            <Grid item md={2} xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  // onChange={(event) => setDecimal(event.target.value)}
                  onChange={(event) => setFieldValue("decimal",event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <Field
                name="demandForecastUploadedFile"
                label={"Uploaded File Name"}
                component={SelectField}
                options={[...demandForecastUploadedFile]}
                fullWidth
                disabled={demandForecastUploadedFile.length === 0}
              />
            </Grid>
            <Grid item>
              <Tooltip title="Select Uploaded file">
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.uploadCheck}
                  onChange={(e) => {
                    setFieldValue("uploadCheck", e.target.checked);
                  }}
                  disabled={demandForecastUploadedFile.length === 0}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={"Delete File"}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenDelete(true)}
                  disabled={demandForecastUploadedFile.length === 0}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={"Upload File"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModal(true)}
                >
                  <CloudUploadIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <DeleteSummaryFiles
        tag={tag}
        open={openDelete}
        setOpen={setOpenDelete}
        demandForecastUploadedFile={demandForecastUploadedFile}
        refetch={refetch}
      />
      <SummaryUpload
        tag={tag}
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetch}
      />
      <Grid item md={12}>
        <Section
          title={"FA FB"}
          onSettings={<ExportOption onClick={() => exportCsv()} />}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <HierarchyFieldArray
                values={values}
                options={filteredOptions}
                fieldName={"hierarchyAccurancy"}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => onApply(values)}
                sx={{ mt: 2 }}
              >
                Apply
              </Button>
            </Grid>
            <Grid item md={12}>
              <ChannelWisePerformanceTable
                height={378}
                gridApi={gridApi1}
                setGridApi={setGridApi1}
                colDef={colDef}
                isLoading={isLoading}
                data={rowData}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
    </Grid>
  );
}
