import { useQuery } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetSummaryUploadedFile(
  payload,
  decimals = 4,
  enable
) {
  const path = `demand_forecast/get_uploaded_summary_data`;
  const { data, isLoading, error, isError } = useQuery(
    ["getsummary", payload],
    () => postApi(path, payload),
    { enabled: enable }
  );
  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  const xAxis = [];
  const xAxisfy = [];
  let numberLeast = NaN;
  let highestNumber = NaN;
  let finalNumber;
  let numberLeastfy = NaN;
  let highestNumberfy = NaN;
  let finalNumberfy;
  const series = [];
  const legend = [];
  const seriesfy = [];

  const selected = {};
  const selectedfy = {};

  const colDefUploaded = [
    {
      field: "Channel_Split",
      width: 120,
      headerName: "Channel",
      pinned: "left",
    },
  ];

  if (Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((header) => {
      if (header !== "Channel_Split") {
        colDefUploaded.push({
          field: header,
          cellStyle: (params) => getStyles(params, "Channel_Split"),
          headerTooltip: header,
          valueFormatter: (params) => getFormatValue(params, decimals),
        });
      }
    });
  }
  if (data && Array.isArray(data) && data.length) {
    const temp = [];
    const tempfy = [];
    Object.keys(data[0]).forEach((item) => {
      if (item.startsWith("MQ-")) {
        temp.push(item);
        const sortedValue = temp.sort();
        const leastNumber = sortedValue[0];
        const firstNumber = sortedValue[sortedValue.length - 1];
        numberLeast = leastNumber.split("-")[1];
        highestNumber = firstNumber.split("-")[1];
        finalNumber = highestNumber.split("gr")[0];
      }
      if (item.startsWith("FY-")) {
        tempfy.push(item);
        const sortedValuefy = tempfy.sort();
        const leastNumberfy = sortedValuefy[0];
        const firstNumberfy = sortedValuefy[sortedValuefy.length - 1];
        numberLeastfy = leastNumberfy.split("-")[1];
        highestNumberfy = firstNumberfy.split("-")[1];
        finalNumberfy = highestNumberfy.split("gr")[0];
      }
    });

    for (let i = numberLeast; i <= finalNumber; i++) {
      ["MQ", "JQ", "SQ", "DQ"].forEach((a) => {
        xAxis.push(a + "-" + i);
      });
    }
    for (let i = numberLeastfy; i <= finalNumberfy; i++) {
      ["FY"].forEach((a) => {
        xAxisfy.push(a + "-" + i);
      });
    }
  }

  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      legend.push(item["Channel_Split"]);
      selected[`${item["Channel_Split"]}`] = false;
      selected[`${item["Channel_Split"]} gr`] = false;
      const seriesDataGr = [];
      xAxis.forEach((axisItem) => {
        seriesDataGr.push(`${item[`${axisItem}gr`] ?? 0}`);
      });
      const seriesData = [];
      xAxis.forEach((axisItem) => {
        seriesData.push(`${item[axisItem] ?? 0}`);
      });

      series.push({
        name: item["Channel_Split"],
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: seriesData,
      });
      series.push({
        name: `${item["Channel_Split"]} gr`,
        type: "line",
        smooth: true,
        data: seriesDataGr.map((f) => f.split("%")[0]),
        yAxisIndex: 1,
      });
    });
  }

  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      legend.push(item["Channel_Split"]);
      selectedfy[`${item["Channel_Split"]}`] = false;
      selectedfy[`${item["Channel_Split"]} gr`] = false;
      const seriesDataGrfy = [];
      xAxisfy.forEach((axisItem) => {
        seriesDataGrfy.push(`${item[`${axisItem}gr`] ?? 0}`);
      });
      const seriesDatafy = [];

      xAxisfy.forEach((axisItem) => {
        seriesDatafy.push(`${item[axisItem] ?? 0}`);
      });

      seriesfy.push({
        name: item["Channel_Split"],
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: seriesDatafy,
      });
      seriesfy.push({
        name: `${item["Channel_Split"]} gr`,
        type: "line",
        smooth: true,
        data: seriesDataGrfy.map((f) => f.split("%")[0]),
        yAxisIndex: 1,
      });
    });
  }
  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      //data: legend,
      right: "6%",
      top: "5%,",
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: series,
  };

  const optionsfy = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      selected: {
        ...selectedfy,
        Grand_Total: true,
        "Grand_Total gr": true,
      },
      right: "6%",
      top: "5%,",
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },

    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: xAxisfy,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],

    series: seriesfy,
  };
  return {
    summaryUploadeData: isLoading,
    rowDataUploaded: data,
    colDefUploaded,
    optionUploaded: options,
    optionsfyUploaded: optionsfy,
  };
}
