export const columns = (primary, secondary, key, decimals) => {
  const series = [...primary, ...secondary];
  const columns = [];
  columns.push({
    field: key,
    headerName: key,
  });
  series.forEach(({ label, value }) => {
    columns.push({
      headerName: label,
      field: value,
      valueFormatter: (params) =>
        isNaN(params.value)
          ? params.value
          : Number(params.value).toFixed(decimals),
    });
  });
  return columns;
};

export const color = [
  "#3346D3",
  "#01CFFE",
  // "#5d6cdc",
  "#242440",
  "#e3d363",
  "#f59196",
  "#6be6c1",
  "#cc188d",
  "#626c91",
  // "#3fb1e3",
  "#a0a7e6",
  "#23337e",
  "#f2161f",
  "#84d24c",
  "#ee5d3e",
  "#952cf4",
  "#282a31",
  "#8cd326",
  "#75604e",
  "#14b37d",
  "#29272a",
  "#282a31",
  "#e3d363",
  "#f59196",
  "#cc188d",
  // "#3fb1e3",
  "#6be6c1",
  "#626c91",
  "#a0a7e6",
  "#23337e",
  "#f2161f",
  "#84d24c",
  "#ee5d3e",
  "#952cf4",
  "#8cd326",
  "#75604e",
  "#14b37d",
  "#29272a",
  "#282a31",
  "#e3d363",
  "#f59196",
  "#cc188d",
  // "#3fb1e3",
  "#6be6c1",
  "#626c91",
  "#a0a7e6",
  "#23337e",
  "#f2161f",
  "#84d24c",
  "#ee5d3e",
  "#952cf4",
  "#8cd326",
  "#75604e",
  "#14b37d",
  "#29272a",
];
