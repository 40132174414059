import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetDownloadFileName() {
  const path = "filter_list_test/getDownloadFileName";

  const { data, isLoading, isError, error } = useQuery(
    ["getdownloadfilenames"],
    () => getApi(path),
    {
      enabled: true,
    }
  );
  const fileName = [];
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      fileName.push(element);
    });
  }

  return {
    downloadData: fileName,
    isLoading,
    data,
    isError,
    error,
  };
}
