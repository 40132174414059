import DataGridTable from "components/DataGridTable/DataGridTable";
import React from "react";

import Loader from "components/Loader/Loader";

export default function SummaryTableWrapper(props) {
  const { columnDefs = [], gridApi, setGridApi, isLoading, ...rest } = props;

  if (isLoading) {
    return <Loader />;
  }
  return (
    <DataGridTable
      {...rest}
      onGridReady={(e) => setGridApi(e.api)}
      columnDefs={columnDefs}
    />
  );
}
