import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";

import PublicIcon from "@mui/icons-material/Public";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { PendingOutlined } from "@mui/icons-material";

export const initialValues_faLoss = {
  filters: {
    BP: [],
    Cluster: [],
    Channel: [],
    Branch: [],
    "Sales.Category": [],
    Category: [],
    "Sales Business": [],
    "Sales Business Group": [],
    UOM: [],
    "Pack.Cluster": [],
    Segment: [],
    Brand: [],
    "Brand.Variant": [],
    Seasonal_Tagging: [],
    Scope_Flag: [],
    "Modified Brand": [],
    "Top Packs": [],
    SubCategory: [],
  },
  openDrawer: true,
  decimal:0,
  demandForecastUploadedFile:[],
  filename: "",
  hierarchyFaLoss: [{ hierarchy: "Category" }],
};

export const filtersData_filterLoss = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BP",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand.Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack.Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },

      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  // {
  //   title: "Time",
  //   icon: AlarmIcon,
  //   filters: [
  //     {
  //       label: "Year",
  //       name: "Year",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "BM Year",
  //       name: "Year_BM",
  //       component: MultiSelectDropdown,
  //     },
  //   ],
  // },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales.Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "SubCategory",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales Business Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlined,
    filters: [
      {
        label: "Pack Cluster",
        name: "Pack.Cluster",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top Packs",
        component: MultiSelectDropdown,
      },
    ],
  },
];
export const faLossDropdown = [
  {
    label: "Basepack",
    value: "Basepack",
  },
  {
    label: "BP Description",
    value: "BP.Descp",
  },
  {
    label: "Modified Brand",
    value: "Modified Brand",
  },
  {
    label: "Format1",
    value: "Format1",
  },

  {
    label: "Pack Cluster",
    value: "Pack Cluster",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: " SubCategory",
    value: "SubCategory",
  },
  {
    label: "Top Packs",
    value: "Top Packs",
  },
  {
    label: " Winter/ Non Winter",
    value: "Winter/ Non Winter",
  },
  {
    label: "Format2",
    value: "Format2",
  },
  {
    label: "UOM",
    value: "UOM",
  },

  {
    label: "Active/Delisted",
    value: "Active/Delisted",
  },
  {
    label: "Pricelist",
    value: "Pricelist",
  },
  {
    label: "Pricelist2",
    value: "Pricelist2",
  },
  {
    label: "Supply Format",
    value: "Supply Format",
  },
  {
    label: "FocFocused Format",
    value: "Focused Format",
  },

  {
    label: "Segmentation",
    value: "Segmentation",
  },
  { label: "COTC/ MD cell", value: "COTC/ MD cell" },
  {
    label: "Covid Format",
    value: "COVID FORMAT",
  },

  {
    label: "Category",
    value: "Category",
  },
  { label: "Price point Tagging", value: "Price point Tagging" },
  {
    label: "Revised UOM",
    value: "Revised UOM",
  },
];
