import Autocomplete from "components/Autocomplete";

import React from "react";

const AutocompleteField = (props) => {
  const {
    form,
    field,
    field: { name, value },
    helperText,
    ...rest
  } = props;

  const handleOnChange = (_, value) => {
    if (Array.isArray(value)) {
      form.setFieldValue(name, value, true);
    } else {
      form.setFieldValue(name, value?.value ?? "", true);
    }
  };

  const handleOnBlur = (e) => {
    form.setFieldTouched(name, true);
    rest.onBlur?.(e);
  };

  return (
    <Autocomplete
      {...rest}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};

export default AutocompleteField;
