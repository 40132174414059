import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Section from "components/Section";
import Comparsion from "./Comparsion";
import { Field, useFormikContext } from "formik";
import useGetSucessBasePack from "services/sceanrioSimulation/useGetSuccessBasepack";
import Loader from "components/Loader";
import useGetAnalysisDetails from "services/sceanrioSimulation/useGetDisplayScenarioDetails";
import Box from "@mui/material/Box";
import usePendingBasePackTable from "services/sceanrioSimulation/usePendingBasePackTable";
import CustomButton from "./CustomButton";
import DataGridTable from "components/DataGridTable/DataGridTable";

export default function RecentScenarioSimulation() {
  const { values, setFieldValue } = useFormikContext();
  const [refresh, setStateRefresh] = useState(true);
  const userName = localStorage.getItem("username");
  const { data, mutate, isLoading } = useGetAnalysisDetails(userName);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    mutate();
  }, [mutate, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      mutate();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  // const {
  //   IsLoadingCreate,
  //   category_Name,
  //   scenario_Name,
  //   mutatePendingBasePack,
  // } = useGetPendingBasePack();
  const {
    mutateSuccessBasePack,
    IsLoadingSucess,
    basePackDetails,
    categoryName,
    scenarioName,
    scenario_description,
    feature,
  } = useGetSucessBasePack();
  const { mutatePendingScenarioTable, IsLoadingCreate, pendingDetails } =
    usePendingBasePackTable();

  useEffect(() => {
    mutatePendingScenarioTable({
      // username: "Abhishek.Trivedi2@unilever.com",
      // username: localStorage.getItem("username"),
      username: "Shruti.Naik@unilever.com",
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      mutateSuccessBasePack({
        username: "Shruti.Naik@unilever.com",
        // username: localStorage.getItem("username"),
      });
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    mutateSuccessBasePack({
      username: "Shruti.Naik@unilever.com",
      // username: localStorage.getItem("username"),
    });
  }, []);

  const handleCreate = () => {
    setFieldValue("createscenarioName", "");
    setFieldValue("createscenarioCategoryName", "");
    setFieldValue("features", []);
    setFieldValue("bpOptions", []);
    setFieldValue("cluster", []);
    setFieldValue("startDate", []);
    setFieldValue("channelNameScenario", []);
    setFieldValue("endDate", []);
    setFieldValue("pageDetails", "createPage");
    setFieldValue("finishEnable", false);
  };

  const colDef = [
    {
      headerName: "Scenario Name",
      field: "scenario_name",
      sortable: true,
    },

    {
      headerName: "Category Name",
      field: "category_name",
      sortable: true,
    },
    { headerName: "Status", field: "status", sortable: true },
    { headerName: "Action", field: "action", cellRenderer: CustomButton },
  ];

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <Button onClick={handleCreate}>Create +</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => setFieldValue("pageDetails", "comparePage")}>
          Compare
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={() => setFieldValue("pageDetails", "historyPage")}>
          History
        </Button>
      </Grid>

      <Grid item md={12}>
        {IsLoadingCreate === true ? (
          <Loader />
        ) : (
          <React.Fragment>
            {pendingDetails.length === 0 ? (
              <Box
                component="section"
                sx={{ p: 2, borderRadius: 1, bgcolor: "white" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" color="primary">
                  No scenario in pending state
                </Typography>
              </Box>
            ) : (
              <Section
                title="Overview"
                actions={
                  <Button
                    variant="contained"
                    onClick={() =>
                      mutatePendingScenarioTable({
                        username: userName,
                      })
                    }
                    sx={{ width: "120px", ml: 16 }}
                  >
                    Refresh
                  </Button>
                }
              >
                <DataGridTable
                  height="400px"
                  onGridReady={(e) => {
                    setGridApi(e.api);
                    e.api.sizeColumnsToFit();
                  }}
                  defaultColDef={{
                    cellStyle: () => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                  }}
                  suppressRowTransform={true}
                  columnDefs={colDef}
                  rowData={pendingDetails}
                  borders={false}
                  isScenarioSimulation
                />
              </Section>
            )}
          </React.Fragment>
        )}
      </Grid>
      <Grid item md={12}>
        {IsLoadingSucess == true ? (
          <Loader />
        ) : (
          <React.Fragment>
            {basePackDetails?.length === 0 ? (
              // <Section>
              //   <p>No recent scenario, Create a new scenario</p>
              // </Section>
              <Box
                component="section"
                sx={{ p: 2, borderRadius: 1, bgcolor: "white" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" color="primary">
                  No recent scenario, Create a new scenario
                </Typography>
              </Box>
            ) : (
              <Section title="">
                <Comparsion
                  backArrow={false}
                  scenarioName={scenarioName}
                  categoryName={categoryName}
                  basePackDetails={basePackDetails}
                  scenario_description={scenario_description}
                  feature={feature}
                />
              </Section>
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
