import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetForecastData(decimals = 4) {
  const path = `forecastAdjustment/displayTable`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) => postApi(path, payload));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  return {
    isTableLoading: isLoading,
    data,
    mutate,
  };
}
