import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { getError, getFieldId } from "../field-helper";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
export const StyledSelect = styled(Select)`
  ::before,
  ::after {
    border: none !important;
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

const MultiSelectField = ({
  field,
  form,
  label,
  options,
  fullWidth,
  placeholder,
  required,
  disabled,
  notifyChange,
  displayDropdown,
  helperText,
  variant = "outlined",
  error: propError,
  setLimit,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldTouched, setFieldValue } = form;
  const { error, hasError } = getError(field, form);
  const fieldId = getFieldId(name);
  const labelId = `${fieldId}-outlined-label`;

  const isAllSelected = options.length > 0 && value.length === options.length;

  const handleChange = (event) => {
    if (setLimit === "true"){
      const value=event.target.value.slice(0,2)
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
    }else{
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setFieldTouched(name, true);
      setFieldValue(
        name,
        value.length === options.length + 1
          ? []
          : Array.from(options, (x) => x.value),
        true
      );
      return;
    }
  
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
  }
  };
  return (
    <FormControl
      variant={variant}
      error={!!propError || hasError}
      size="small"
      margin="none"
      fullWidth={fullWidth}
    >
      <InputLabel id={labelId} required={required}>
        {label}
      </InputLabel>

      <StyledSelect
        labelId="mutiple-select-label"
        multiple
        value={value}
        onChange={handleChange}
        label={label}
        renderValue={(selected) => {
          return selected.length === options.length
            ? `All ${label}`
            : selected.join(", ");
        }}
        MenuProps={MenuProps}
        sx={{
          "& .MuiSelect-iconOutlined": {
            display: value && value.length === 0 ? "" : "none",
          },
          "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
        }}
      >
     

          
        <MenuItem value="all">
          {displayDropdown === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ height: "20px", width: "20px", marginLeft: "75px" }}
              />
            </div>
          ) : (
            <React.Fragment>
               {(setLimit === "true")?(<></>):(
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    value?.length > 0 && value?.length < options.length
                  }
                />
               
              </ListItemIcon>
               )}
               {(setLimit === "true")?(<></>):(
              <ListItemText primary="Select All" />
               )}
            </React.Fragment>
          )}
        </MenuItem>
         
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <Checkbox checked={value.indexOf(option.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </StyledSelect>
      {(!!propError || error) && (
        <FormHelperText>{helperText || error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiSelectField;
