import React, {useState} from "react";
import {IconButton} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFormikContext } from "formik";

export default function CustomButton(props){
  const[enableApi,setEnableApi]=useState(false)
  const { setFieldValue } = useFormikContext();

  return (
    <>
    <IconButton
          variant="outlined"
          disabled={props.data.status == "SUCCESS"?false:true}
          onClick={() => {
            setFieldValue("pageDetails", "historyComparePage")
            setFieldValue("propsHistoryData",props.data)
            setFieldValue("propsHistoryApi",enableApi)
            setFieldValue("channelNameScenario", props.data.channel ?? props.data.category_name)
            setEnableApi(true)
          }}
          sx={{ textTransform:'none' }}
        >
          <InfoOutlinedIcon/>
        </IconButton>
        </>
  )
}