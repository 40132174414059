import React, { useState } from "react";
import Box from "@mui/material/Box";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Formik, useFormikContext } from "formik";

import {
  LeftSideNavbarWrapper,
  RightSideContainer,
  ViewportContainer,
} from "components/AppLayout/AppLayout.styles";

import { initialValues_Adoption } from "../Adoption/formData";
import AdoptionFilter from "../Adoption/AdoptionFilter";
import Accuracy from "./component/Accuracy";

const Content = () => {


  
  const [enableAPIs, setEnableAPIs] = useState(true);

  const { setValues, values, setFieldValue } = useFormikContext();

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  const onApply = () => {
    setEnableAPIs(!enableAPIs);
  };

  const onReset = () => {
    setValues(initialValues_Adoption);
    window.sessionStorage.setItem(
      "performanceDashboardInitialValues",
      JSON.stringify(initialValues_Adoption)
    );
    setEnableAPIs(!enableAPIs);
  };

  const onSave = () => {
    window.sessionStorage.setItem(
      "performanceDashboardInitialValues",
      JSON.stringify(values)
    );
  };

  return (
    <ViewportContainer>
      {values.openDrawer === true ? (
        <LeftSideNavbarWrapper>
          <AdoptionFilter
            onApply={onApply}
            onReset={onReset}
            onSave={onSave}
            enableAPIs={enableAPIs}
            setEnableAPIs={setEnableAPIs}
          />
        </LeftSideNavbarWrapper>
      ) : (
        <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor: "pointer",
          }}
        />
      )}
      <RightSideContainer>
        <Box sx={{ margin: 3 }}>
          <Accuracy enableAPIs={enableAPIs} tag="accuracy" />
        </Box>
      </RightSideContainer>
    </ViewportContainer>
  );
};

export default function AccuracyDashboard() {
  const initialFormValues = window.sessionStorage.getItem(
    "initialValues_ForecastVersioningPerformanceSummary"
  )
    ? JSON.parse(
        window.sessionStorage.getItem(
          "initialValues_ForecastVersioningPerformanceSummary"
        )
      )
    : initialValues_Adoption;

  return (
    <Formik initialValues={initialFormValues}>
      <Content />
    </Formik>
  );
}
