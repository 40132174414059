import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetBasePack() {
  const path = "/scenario_simlation/getBasePack";
  const {
    data = [],
    mutate,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation((formData) => postApi(path, formData));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  // if (data && Array.isArray(data) && data.length) {
  //   data.forEach((item) => {
  //     basePackDetails.push({ label: item, value: item });
  //   });
  // }
  // const data = {
  //   "test-hhc": [13000],
  //   "test-grm-increase": [13000],
  //   "test-grm-inc": [13000],
  // };

  return {
    IsLoadingCreate: isLoading,
    mutateGetBasepack: mutate,
    basePackDetails: data,
    isError,
  };
}
