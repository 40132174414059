import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Formik, useFormikContext } from "formik";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PerformanceSummary from "./Component/PerformanceSummary";
import PerformanceFilter from "./PerformanceFilter";
import { initialValues_PerformanceSummary } from "./formData";
import {
  LeftSideNavbarWrapper,
  RightSideContainer,
  ViewportContainer,
} from "components/AppLayout/AppLayout.styles";

const Content = () => {
  const [enableAPIs, setEnableAPIs] = useState(true);
  const { setValues, values, setFieldValue } = useFormikContext();
  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };
  const onApply = () => {
    setEnableAPIs(!enableAPIs);
  };
  const onReset = () => {
    setValues(initialValues_PerformanceSummary);
    window.sessionStorage.setItem(
      "performanceDashboardInitialValues",
      JSON.stringify(initialValues_PerformanceSummary)
    );
    setEnableAPIs(!enableAPIs);
  };
  const onSave = () => {
    window.sessionStorage.setItem(
      "performanceDashboardInitialValues",
      JSON.stringify(values)
    );
  };
  return (
    <ViewportContainer>
      {values.openDrawer === true ? (
        <LeftSideNavbarWrapper>
          <PerformanceFilter
            onApply={onApply}
            onReset={onReset}
            onSave={onSave}
            enableAPIs={enableAPIs}
            setEnableAPIs={setEnableAPIs}
          />
        </LeftSideNavbarWrapper>
      ) : (
        <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor:"pointer",
          }}
        />
      )}
      <RightSideContainer>
        <Box sx={{ margin: 3 }}>
          <PerformanceSummary enableAPIs={enableAPIs} tag={"performance_summary"}/>
        </Box>
      </RightSideContainer>
    </ViewportContainer>
  );
};
export default function PerformanceDashboard() {
  const initialFormValues = window.sessionStorage.getItem(
    "initialValues_ForecastVersioningPerformanceSummary"
  )
    ? JSON.parse(
        window.sessionStorage.getItem(
          "initialValues_ForecastVersioningPerformanceSummary"
        )
      )
    : initialValues_PerformanceSummary;
  return (
    <Formik initialValues={initialFormValues}>
      <Content />
    </Formik>
  );
}
