/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from "react";

import { useFormikContext } from "formik";
import ReactECharts from "echarts-for-react";
import useGetMtSOPValue from "services/Mts&op/useGetMtSOPValue";
import useGetMtSopPivotData from "services/Mts&op/useGetMtSopPivotData";
import SummaryTableWrapper from "views/Summary/components/SummaryTableWrapper";
import useGetMtSOPUpload from "services/Mts&op/useGetMTSOPUpload";
import { getHierarchy } from "./formData";

import { useDeepCompareEffect } from "utils/useDeepCompareEffect";
import Loader from "components/Loader/Loader";

export const MtSopTable = (props) => {
  const {
    term,
    enableAPIs,
    decimals,
    height,
    displayOption,
    gridApi,
    setGridApi,
    fetch,
  } = props;
  const { values } = useFormikContext();

  const enable = values.demandForecastUploadedFile !== "Default";

  const { data, colDef, uploading, optionsUpload } = useGetMtSOPUpload(
    {
      username: localStorage.getItem("name"),
      filename: values.demandForecastUploadedFile,
      key: term,
      ...values.filters,
      ratio: values["ratio"],
      nm: values["nm"],
    },
    decimals,
    enable,
    term
  );
  const [chartInstance, setchartInstance] = React.useState(null);

  const { colDefValue, isLoading, mutate, rowDataValue, options } =
    useGetMtSopPivotData(
      decimals,
      term,
      values.hierarchyBusinessGroup?.map((f) => f.hierarchy)
    );

  useEffect(() => {
    if (values.filename) {
      mutate({
        key: term,
        filename: values.filename,
        filters: values.filters,
        ratio: values["ratio"],
        nm: values["nm"],
        heirarchy: values.hierarchyBusinessGroup?.map((f) => f.hierarchy),
      });
    }
  }, [enableAPIs, mutate, term, fetch]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Business Group",
      minWidth: 100,
      maxWidth: 800,
      pinned: "left",
      cellRendererParams: {
        suppressCount: true,
      },
      headerTooltip: "Business Group",
      cellStyle: () => {
        return {
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        };
      },
    };
  }, []);

  useDeepCompareEffect(() => {
    if (chartInstance && enable) {
      chartInstance.setOption(optionsUpload, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    } else if (chartInstance && !enable) {
      chartInstance.setOption(options, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    }
    if (chartInstance) {
      chartInstance.setOption(options, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    }
  }, [options, chartInstance, optionsUpload]);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data["channel"];
    };
  }, []);

  return enable ? (
    uploading ? (
      <Loader />
    ) : (
      <React.Fragment>
        {displayOption === "table" ? (
          <SummaryTableWrapper
            height={height}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            animateRows={true}
            gridApi={gridApi}
            setGridApi={setGridApi}
            getDataPath={getDataPath}
            columnDefs={colDef}
            rowData={data ?? []}
          />
        ) : (
          <ReactECharts
            style={{ height: "500px", width: "100%" }}
            option={options}
            ref={(e) => {
              const instance = e?.getEchartsInstance();
              setchartInstance(instance);
            }}
          />
        )}
      </React.Fragment>
    )
  ) : isLoading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {displayOption === "table" ? (
        <SummaryTableWrapper
          height={height}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          animateRows={true}
          gridApi={gridApi}
          setGridApi={setGridApi}
          getDataPath={getDataPath}
          columnDefs={colDefValue}
          rowData={rowDataValue ?? []}
        />
      ) : (
        <ReactECharts
          style={{ height: "500px", width: "100%" }}
          option={options}
          ref={(e) => {
            const instance = e?.getEchartsInstance();
            setchartInstance(instance);
          }}
        />
      )}
    </React.Fragment>
  );
};
