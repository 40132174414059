import { Grid, Button, Typography } from "@mui/material";

import List from "@mui/material/List";

import React from "react";
import { useState, useEffect } from "react";
import Dialog from "components/Dialog";
import Autocomplete from "components/Autocomplete";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useDeletefiles from "services/DemandForecast/useDeletefiles";
import DataGridTable from "components/DataGridTable/DataGridTable";
import useDeleteScenario from "services/sceanrioSimulation/useDeleteScenario";

const DeleteSimulation = ({
  open,
  setOpen,
  refetch,
  simulations = [],
  colDef,
}) => {
  const [files, setFiles] = useState(null);

  const { mutateDelete, isLoadingDelete, isSuccess } = useDeleteScenario();

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch, isLoadingDelete]);

  const steps = ["Select Files", "Preview", "Confirmation"];

  const getModalContent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <Autocomplete
            value={files ?? []}
            multiple
            onChange={(e, value) => {
              setFiles(value);
            }}
            limitTags={10}
            options={simulations?.map((f) => {
              return { label: f.scenario_name, value: f };
            })}
          />
        );
      case 1:
        return (
          <>
            <Typography variant="subtitle2" padding={(0, 2)}>
              List of files selected
            </Typography>
            <List dense={true}>
              {files && (
                <DataGridTable
                  height={200}
                  rowData={files.map((f) => f.value)}
                  columnDefs={colDef}
                  borders={false}
                  isScenarioSimulation
                />
              )}
            </List>
          </>
        );
      default:
        if (isLoadingDelete) {
          return (
            <Typography variant="body1" padding={(0, 2)}>
              Loading ...
            </Typography>
          );
        }
        return (
          <>
            {" "}
            {isSuccess ? (
              <Typography variant="body1" padding={(0, 2)}>
                File(s) Deleted Successfully
              </Typography>
            ) : (
              <Typography variant="body1" color={"error"} padding={(0, 2)}>
                Failed to delete the file(s)
              </Typography>
            )}
          </>
        );
    }
  };

  const getActions = (stage) => {
    switch (stage) {
      case 0:
        return (
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
          >
            Next
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              mutateDelete({
                username: localStorage.getItem("username"),
                scenario_data: files.map((f) => f.value),
              });
              setActiveStep(activeStep + 1);
            }}
          >
            Delete
          </Button>
        );
      default:
        return (
          <Button
            variant="contained"
            disabled={isLoadingDelete}
            onClick={() => {
              refetch();
              setOpen(false);
              setFiles([]);
              setActiveStep(0);
            }}
          >
            Close
          </Button>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        refetch();
        setOpen(false);
        setFiles([]);
        setActiveStep(0);
      }}
      header={
        <Typography variant="title" color={"primary"}>
          Delete Files
        </Typography>
      }
      maxWidth="sm"
      fullWidth={true}
      footer={
        <Grid container flex={1} justifyContent={"space-between"}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          {getActions(activeStep)}
        </Grid>
      }
    >
      <Grid
        container
        flexDirection={"column"}
        sx={{ paddingTop: 2 }}
        spacing={2}
      >
        <Grid item md={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item md={12}>
          {getModalContent(activeStep)}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteSimulation;
