import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetAccurancyDetails(decimal = 4) {
  const path = `/sales_and _forecast/adoption_accuracy`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();
  
  const colStyles=(decimal)=>{
    return {minWidth: 150,
     cellStyle: (params) => getStyles(params, "heirarchy"),
     valueFormatter: (params) => getFormatValue(params, decimal)}
 }
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const colDef = [
    {
      field: "HUL Units Accuracy",
      ...colStyles(decimal),
      headerTooltip: "HUL Units Accuracy",
    },
    {
      field: "ACC Units Accuracy",
      headerName: "Falcon Units Accuracy",
      ...colStyles(decimal),
      headerTooltip: "Falcon Units Accuracy",
    },
    {
      field: "HUL Units Bias",
      ...colStyles(decimal),
      headerTooltip: "HUL Units Bias",
    },
    {
      field: "ACC Units Bias",
      headerName: "Falcon Units Bias",
      ...colStyles(decimal),
      headerTooltip: "Falcon Units Bias",
    },
    {
      field: "HUL Cases Accuracy",
      ...colStyles(decimal),
      headerTooltip: "HUL Cases Accuracy",
    },
    {
      field: "ACC Cases Accuracy",
      headerName: "Falcon Cases Accuracy",
      ...colStyles(decimal),
      headerTooltip: "Falcon Cases Accuracy",
    },
    {
      field: "HUL Cases Bias",
      ...colStyles(decimal),
      headerTooltip: "HUL Cases Bias",
    },
    {
      field: "ACC Cases Bias",
      headerName: "Falcon Cases Bias",
      ...colStyles(decimal),
      headerTooltip: "Falcon Cases Bias",
    },
    {
      field: "HUL RPM Accuracy",
      ...colStyles(decimal),
      headerTooltip: "HUL RPM Accuracy",
    },
    {
      field: "ACC RPM Accuracy",
      headerName: "Falcon RPM Accuracy",
      ...colStyles(decimal),
      headerTooltip: "Falcon RPM Accuracy",
    },
    {
      field: "HUL RPM Bias",
      ...colStyles(decimal),
      headerTooltip: "HUL RPM Bias",
    },
    {
      field: "ACC RPM Bias",
      headerName: "Falcon RPM Bias",
      ...colStyles(decimal),
      headerTooltip: "Falcon RPM Bias",
    },
  ];

  return {
    isLoading,
    colDef,
    mutate,
    rowData: data,
  };
}
