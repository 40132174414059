import { getDataForCSV } from "utils/constants";

export const exportToCSV = (gridApi1, gridApi2, gridApi3, name, filters) => {
  const spreadsheets = [
    gridApi1.getSheetDataForExcel({
      prependContent: getDataForCSV(filters),
      sheetName: "volume",
    }),
    gridApi2.getSheetDataForExcel({
      prependContent: getDataForCSV(filters),
      sheetName: "value",
    }),
    gridApi3.getSheetDataForExcel({
      prependContent: getDataForCSV(filters),
      sheetName: "Each",
    }),
  ];

  gridApi1.exportMultipleSheetsAsExcel({
    data: spreadsheets,
    fileName: `${name}.xlsx`,
  });
};
