import NavBar from "components/NavBar/NavBar";
import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Grid, Stack } from "@mui/material";
import { Title } from "components/NavBar/NavBar.styles";
import AutocompleteField from "components/form-fields/AutocompleteField/AutocompleteField";
import { filtersData } from "views/ForecastAdjustment/formData";
import useGetForecastFilename from "services/ForecastAdjustments/useGetForecastFilename";
import useGetForecastFilters from "services/ForecastAdjustments/useGetForecastFilters";

const ForecastAdjustmentFilters = ({ onApply, onReset, enableApis }) => {
  const { values, setFieldValue } = useFormikContext();
  const { isLoading, fileName } = useGetForecastFilename();
  const { data, mutate } = useGetForecastFilters();

  useEffect(() => {
    if (values.filename) {
      mutate({
        fileName: values.filename,
        filters: values.filters,
      });
    }
  }, [mutate, values.filename, enableApis]);

  useEffect(() => {
    if (values.filename) {
      setFieldValue("filterData", filtersData);
    }
  }, [values.filename]);

  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      filterData={values.filterData ?? []}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              sx={{ mt: 2 }}
              name={"filename"}
              label={"File Name"}
              options={fileName}
              isOptionEqualToValue={(option, value) => option.value === value}
              fullWidth
              component={AutocompleteField}
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};

export default ForecastAdjustmentFilters;
