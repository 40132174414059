import React from "react";
import { Field, useFormikContext } from "formik";
import IconButton from "@mui/material/IconButton";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const steps = [
  "Name the scenario, select category and features to edit",
  "Select Required Basepack,Cluster code and TDP",
  "Edit selected feature values",
];

export default function CreateScenarioSimaultion() {
  const current = new Date();
  const { values, setFieldValue } = useFormikContext();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const currentDate = current.toLocaleDateString(); // Get the current date
  const [createScenario, setCreateScenario] = React.useState(false);

  const currentTime = current.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const date = `${currentDate} ${currentTime}`;

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === steps.length) {
      setCreateScenario(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setFieldValue("pageDetails", "dashboard");
    setActiveStep(0);
    setFieldValue("createscenarioName", "");
    setFieldValue("createscenarioCategoryName", "");
    setFieldValue("features", []);
    setFieldValue("bpOptions", []);
    setFieldValue("cluster", []);
    setFieldValue("startDate", []);
    setFieldValue("channelNameScenario", []);
    setFieldValue("endDate", []);
    setFieldValue("isTdpValid", false);
    setFieldValue("startTdp", "");
    setFieldValue("endTdp", "");
  };

  const disableNext = (active) => {
    if (active === 0) {
      if (
        values.createscenarioName.length === 0 ||
        values?.createscenarioCategoryName?.length === 0 ||
        values.features.length === 0
      ) {
        return true;
      } else return false;
    }
    if (active === 1) {
      if (
        values?.bpOptions?.length === 0 ||
        values?.cluster?.length === 0 ||
        values?.startDate?.length === 0 ||
        values?.endDate?.length === 0 ||
        values?.startTdp?.length === 0 ||
        values?.endTdp?.length === 0
      ) {
        return true;
      } else return false;
    }
    return false;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <IconButton
          sx={{ marginBottom: "5px", marginLeft: "-10px" }}
          onClick={() => setFieldValue("pageDetails", "dashboard")}
        >
          <HomeOutlinedIcon sx={{ fontSize: 40 }} color="primary" />
        </IconButton>
        <Stepper activeStep={activeStep} sx={{ flex: 1 }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {activeStep === steps.length ? (
        <div></div>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {activeStep === 0 ? (
            <StepOne />
          ) : activeStep === 1 ? (
            <StepTwo />
          ) : (
            <StepThree date={date} createScenario={createScenario} />
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              // color="inherit"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="contained"
              onClick={
                activeStep === steps.length - 1 ? handleReset : handleNext
              }
              disabled={
                activeStep === steps.length - 1
                  ? !values.finishEnable
                  : disableNext(activeStep)
              }
            >
              {activeStep === steps.length - 1 ? "" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
