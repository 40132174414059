import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { useEffect } from "react";
import useNotify from "utils/useNotify";
export default function useGetScenarioName() {
  const path = "/scenario_simulation/get_scenarioName";
  const scenarioList = [];
  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      scenarioList.push({ label: item, value: item });
    });
  }
  return {
    scenarioList,
    isLoading,
    mutateScenario: mutate,
  };
}
