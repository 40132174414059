import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetKeys() {
  const path = "/scenario_simulation/getKeyDisplay";
  const {
    data = [],
    mutate,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation((formData) => postApi(path, formData));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    IsLoadingCreate: isLoading,
    mutateGetKeys: mutate,
    keyDetails: data,
    isError,
  };
}
