import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFeature() {
  const path = "/scenario_simulation/get_feature";

  const { data, mutate, isLoading, isSuccess, isError } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();

  let feature = []
  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      feature.push({ label: item, value: item });
    });
  }

  return {
    isLoadingFeature: isLoading,
    mutateGetFeature: mutate,
    isError,
    feature
  };
}
