import { Title } from "components/NavBar/NavBar.styles";
import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Field, useFormikContext } from "formik";

import SelectField from "components/form-fields/SelectField";


import NavBar from "components/NavBar";
import useGetAdoptionFilename from "services/SalesForecastVersioning/Adoption/useGetAdoptionFileName";
import useGetAdoptionFilter from "services/SalesForecastVersioning/Adoption/useGetAdoptionFilter";
import { filtersData_filterLoss } from "../formData";

const FaLossFilter = ({
  onApply,
  enableAPIs,
  onReset,
  onSave,
  setEnableAPIs,
}) => {
  const { values } = useFormikContext();
  const { data, isLoading, mutate } = useGetAdoptionFilter();

  // const { fileName } = useGetAdoptionFilename();
  useEffect(() => {
    // if (values.filename && enableAPIs) {
       if (enableAPIs) {
      mutate({
        filename: "Sample", //make it has filename
        filters: values.filters,
      });
     }
    setEnableAPIs(false);
  }, [enableAPIs, mutate]);

  return (
    <NavBar
      onApply={onApply}
      onReset={onReset}
      onSave={onSave}
      filterData={filtersData_filterLoss}
      data={data}
      isLoading={isLoading}
    >
      <Stack sx={{ flex: 1, marginBottom: 1 }}>
        <Title>
          <Grid container alignItems="center">
            <Field
              name={"filename"}
              label={"File Name"}
              component={SelectField}
              // options={fileName}
              fullWidth
            />
          </Grid>
        </Title>
      </Stack>
    </NavBar>
  );
};
export default FaLossFilter;
