import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Section from "components/Section";
import { useFormikContext } from "formik";

import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";
import { AdoptionDropdown } from "../formData";
import useGetAccurancyDetails from "services/SalesForecastVersioning/Adoption/usegetAccurancyDetails";
import ChannelWisePerformanceTable from "views/ForecastVersioningPerformanceSummary/Component/ChannelWisePerformanceTable";

export default function Accurancy({ decimals, enableAPIs }) {
  const { values } = useFormikContext();
  const { isLoading, mutate, colDef, rowData } =
    useGetAccurancyDetails(decimals);
  const filteredOptions = AdoptionDropdown.filter(
    (option) =>
      !values.hierarchyAccurancy?.some((h) => h.hierarchy === option.value)
  );
  const [gridApi1, setGridApi1] = useState();

  const onApply = useCallback(
    (values) => {
      mutate({
        heirarchy: values.hierarchyAccurancy?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: "Sample",
      });
    },
    [mutate]
  );

  useEffect(() => {
    onApply(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onApply, enableAPIs]);

  return (
    <Section
      title={"Accurancy"}
      actions={
        <React.Fragment sx={{ m: 2 }}>
          {/* <ButtonGroup
            setValue={setChecked}
            value={checked}
            // options={[
            //   { label: "Table", value: "table" },
            //   { label: "Chart", value: "chart" },
            // ]}
            primaryColor={"#31b2dc"}
            width={84}
          /> */}
        </React.Fragment>
      }
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <HierarchyFieldArray
            values={values}
            options={filteredOptions}
            fieldName={"hierarchyAccurancy"}
          />
          <Button
            size="small"
            variant="contained"
            onClick={() => onApply(values)}
            sx={{ mt: 2 }}
          >
            Apply
          </Button>
        </Grid>
        <Grid item md={12}>
          <ChannelWisePerformanceTable
            height={378}
            gridApi={gridApi1}
            setGridApi={setGridApi1}
            colDef={colDef}
            isLoading={isLoading}
            data={rowData}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
