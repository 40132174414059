import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetNavBarMtSop() {
  const path = "/mt_sop/getfilterformtsop";

  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  return {
    data,
    isLoading,
    mutate,
  };
}
