import React, { useState } from "react";
import { alpha } from "@mui/system";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";

import Section from "components/Section";
import ReactECharts from "echarts-for-react";
import { Field, useFormikContext } from "formik";
import AutocompleteField from "components/form-fields/AutocompleteField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonGroup from "components/ButtonGroup";
import { useEffect } from "react";
import useGetResult from "services/sceanrioSimulation/useGetResult";
import Loader from "components/Loader";
import { color } from "views/DemandForecast/mappings";
import DisplayButton from "components/DisplayButton/DisplayButton";
import DataGridTable from "components/DataGridTable/DataGridTable";
import FeatureImportance from "views/DemandForecast/components/FeatureImportance";
import Autocomplete from "components/Autocomplete";
import useGetBasePack from "services/sceanrioSimulation/useGetBasepack";
import useGetClusterCode from "services/sceanrioSimulation/useClusterCode";
import useGetAnaplanCustomer from "services/sceanrioSimulation/userGetAnalplanCustomer";
import ExportOption from "components/ExportOption/ExportOption";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";
import { downloadCSVFromJson } from "../hooks/downloadJson";
import useGetCompareResult from "services/sceanrioSimulation/useGetCompareResult";
import useGetCompareResultNonAgg from "services/sceanrioSimulation/useGetCompareResultNonAgg";
import useGetFeature from "services/sceanrioSimulation/useGetFeature";
import CustomPlaceholder from "components/CustomPlaceholder";

export default function HistoryComparison(props) {
     const{data, enableApi} = props
//  const userName = "shruti.Naik@unilever.com";
   
   const userName = localStorage.getItem("username");
  const lineStyle = {
    shadowColor: "#dfe8ed",
    shadowBlur: 1,
    shadowOffsetX: 0,
    shadowOffsetY: 6,
  };

  const [mode, setStateMode] = useState("MOC");
  const [displayData, setDisplayData] = useState("Agg");
  const { values, setFieldValue } = useFormikContext();
 
  const [checked, setChecked] = useState("chart");
  const [selectedValues, setSelectedValues] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [showFeatureImp, setShowFeatureImp] = useState(false);
  const [featureDetails, setFeatureDetails] = useState();

  const [basepack, setBasepack] = useState(null);
  const [anaplanCustomer, setAnaplanCustomer] = useState(null);
  const { basePackDetails, mutateGetBasepack } = useGetBasePack();
  const [dataChange, setDataChange] = useState("");
  const [dataChangeHolder, setDataChangeHolder] = useState(false);

  useEffect(()=>{
    setDataChangeHolder(true)
  },[dataChange, values.demandDriver])
  
  const { anaplanDetails, mutateGetAnaplan } = useGetAnaplanCustomer();
  const queryClient = useQueryClient();
  const {
    scenarioResult,
    colDef,
    mutateScenarioCompareResult,
    isLoading,
    xAxis,
    yAxis,
    legendData,
  } = useGetCompareResult(mode, basepack?.value, values.cluster, values.scale, values.demandDriver);

  const {
    scenarioResultNonAgg,
    colDefNonAgg,
    mutateScenarioCompareResultNonAgg,
    isLoadingNonAgg,
    xAxisNonAgg,
    yAxisNonAgg,
    legendDataNonAgg,
  } = useGetCompareResultNonAgg(mode, basepack?.value, values.cluster, values.scale, values.demandDriver);

  const {
    isLoadingFeature,
    mutateGetFeature,
    isError,
    feature
  } = useGetFeature();

  const [apply, setStateApply] = useState(false);
 

  

  useEffect(()=>{
    mutateGetBasepack({
        username: userName,
        categoryName: data.channel ? data.channel.split("_")[0] : data.category_name.split("_")[0],
        scenario_Name: [data.scenario_name],
      });
  },[enableApi])



  

  // useEffect(() => {
  //   if (basepack !== null && values.channelNameScenario.startsWith("MT"))
  //     mutateGetAnaplan({
  //       username: userName,
  //       categoryName: values.channelNameScenario.split("_")[0],
  //       scenario_Name: [data.scenario_name],
  //       BP: values.historykeys,
  //     });
  // }, [basepack]);

  const getseriesData = () => {
    let seriesOptionData = [];
    if (
      scenarioResult &&
      Array.isArray(scenarioResult) &&
      scenarioResult.length
    ) {
      Object.keys(scenarioResult[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if(values.demandDriver.length > 0){
            if(i.includes("MRP_updated") || i.includes("GRM_updated")){
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[5 + index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[5 + index], 0.2),
                },
                data: yAxis[i],
                yAxisIndex: 1,
                smooth: true,
              });
            }else{
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxis[i],
                smooth: true,
              });
            }
          }else{
          seriesOptionData.push({
            // name: i,
            name: i.replace(/(Accenture|ACC)/g, "Falcon"),
            type: "line",
            color: color[index],
            lineStyle: {
              ...lineStyle,
              shadowColor: alpha(color[index], 0.2),
            },
            data: yAxis[i],
            smooth: true,
          });}
        }
      });
    }
    return seriesOptionData;
  };

  const getSeriesDataNonAgg = () => {
    let seriesOptionData = [];
    if (
      scenarioResultNonAgg &&
      Array.isArray(scenarioResultNonAgg) &&
      scenarioResultNonAgg.length
    ) {
      Object.keys(scenarioResultNonAgg[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if(values.demandDriver.length > 0){
            if(i.includes("MRP_updated") || i.includes("GRM_updated")){
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[5 + index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[5 + index], 0.2),
                },
                data: yAxisNonAgg[i],
                yAxisIndex: 1,
                smooth: true,
              });
            }else{
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxisNonAgg[i],
                smooth: true,
              });
            }
          }else{
          seriesOptionData.push({
            // name: i,
            name: i.replace(/(Accenture|ACC)/g, "Falcon"),
            type: "line",
            color: color[index],
            lineStyle: {
              ...lineStyle,
              shadowColor: alpha(color[index], 0.2),
            },
            data: yAxisNonAgg[i],
            smooth: true,
          });}
        }
      });
    }
    return seriesOptionData;
  };
  
  const scenariooption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      // data: scenarioResult && legendData,
      data: displayData==="Agg" ? legendData : legendDataNonAgg,
      right: "6%",
      top: "5%,",
    },

    grid: {
      left: "3%",
      right: "4%",

      containLabel: true,
    },

    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: displayData==="Agg" ? xAxis : xAxisNonAgg,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],
    series: displayData==="Agg"?scenarioResult && getseriesData() : scenarioResultNonAgg && getSeriesDataNonAgg(),
  };
  const exportCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  };

  const downloadScenarioData = async (
    selectedValues,
    channelNameScenario,
    mode
  ) => {
    const fileName = "scenario_" + channelNameScenario;
    const path = "sceanrio_simulation/download";
    const payload = {
      scenarioName: selectedValues,
      fileName: channelNameScenario,
      username: userName,
      mode: mode,
    };
    try {
      const data = await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {});
      downloadCSVFromJson(fileName, data);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

 const onApply = () =>{
  setDataChangeHolder(false)
  setFeatureDetails({
    bp: basepack?.value,
    cluster: values.cluster,
    anaplanCustomer: anaplanCustomer?.value,
  })

  setShowFeatureImp(true)

  if(displayData === "Agg"){
    if (values.channelNameScenario.startsWith("MT")) {
      mutateScenarioCompareResult({
        scenarioName: [data.scenario_name],
        fileName: values.channelNameScenario,
        mode: mode,
        username: userName,
        Keys: values.historykeys,
        anaplanCustomer: anaplanCustomer?.value,
        demandDriver: [
          ...values.demandDriver.map((f) => f.value),
        ],
      });
    } else {
      mutateScenarioCompareResult({
        scenarioName: [data.scenario_name],
        fileName: values.channelNameScenario,
        mode: mode,
        username: userName,
        Keys: values.historykeys,
        demandDriver: [
          ...values.demandDriver.map((f) => f.value),
        ],
      });
    }
  }else{
    if (values.categoryNameScenario.startsWith("MT")) {
      mutateScenarioCompareResultNonAgg({
        scenarioName: [data.scenario_name],
        fileName: values.channelNameScenario,
        mode: mode,
        username: userName,
        Keys: values.historykeys,
        anaplanCustomer: anaplanCustomer?.value,
        demandDriver: [
          ...values.demandDriver.map((f) => f.value),
        ],
      });
    } else {
      mutateScenarioCompareResultNonAgg({
        scenarioName: [data.scenario_name],
        fileName: values.channelNameScenario,
        mode: mode,
        username: userName,
        Keys: values.historykeys,
        demandDriver: [
          ...values.demandDriver.map((f) => f.value),
        ],
      });
    }
  }

  mutateGetFeature({
    username: userName,
    scenarioName: data.scenario_name,
    categoryName: values.channelNameScenario.toString()
  })
 }

  return (
    <Grid>
      <ArrowBackIcon
       onClick={() => {
        if (data.channel) {
          setFieldValue("pageDetails", "historyPage");
          setFieldValue("historykeys", []);
        } else {
          setFieldValue("pageDetails", "dashboard");
          setFieldValue("historykeys", []);
        }
      }}
      />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl size="small" sx={{ mt: 2, width: 300 }}>
          <TextField
          disabled
          id="outlined-disabled"
          label="Category"
          defaultValue={data.channel ?? data.category_name}
          size="small"
        />
          </FormControl>
          <FormControl size="small" sx={{ mt: 2, width: 300, ml:2 }}>
          <TextField
          disabled
          id="outlined-disabled"
          label="Scenario Name"
          defaultValue={data.scenario_name}
          size="small"
        />
          </FormControl>

              <Button
                sx={{ mt: 2, ml: 2, width: "150px" }}
                size="small"
                variant="contained"
                onClick={() =>
                  downloadScenarioData(
                    data.scenario_name,
                    values.channelNameScenario,
                    mode
                  )
                }
              >
                Download
              </Button>

          {1 === 1 && (
            <div style={{ display: "flex", marginBottom:"16px" }}>
              <FormControl size="small" sx={{ mt: 2, width: 300 }}>
                <Field
                  multiple
                  name="historykeys"
                  label="Select Keys"
                  options={basePackDetails}
                  noBorders={false}
                  variant="outlined"
                  fullWidth
                  component={MultiSelectDropdownF}
                  // sx={{ mt: 2, width: 300, ml: 2 }}
                />
              </FormControl>
              {values.channelNameScenario.startsWith("MT") && (
                <FormControl size="small" sx={{ mt: 2, ml: 2, width: 300 }}>
                  <Autocomplete
                    label="Anaplan Customer"
                    value={anaplanCustomer}
                    onChange={(e, value) => {
                      setAnaplanCustomer(value);
                    }}
                    options={anaplanDetails}
                  />
                </FormControl>
              )}
              {/* <FormControl size="small" sx={{ mt: 2, ml: 2, width: 300 }}>
                <Field
                  multiple
                  name="cluster"
                  label="Cluster Code"
                  options={clusterDetails}
                  noBorders={false}
                  variant="outlined"
                  fullWidth
                  component={MultiSelectDropdownF}
                  // sx={{ mt: 2, width: 300, ml: 2 }}
                />
              </FormControl> */}
              {/* <Field
                multiple
                name="scale"
                label="Measure"
                options={values?.dropdown}
                component={AutocompleteField}
                sx={{ mt: 2, width: 300, ml: 2 }}
              /> */}

                <Button
                  variant="contained"
                  style={{
                    marginTop: "12px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    float: "right",
                  }}
                  onClick={onApply}
                  disabled={values.historykeys.length === 0}
                >
                  Apply
                </Button>
            </div>
          )}
        </Grid>
        {
        values.historykeys.length !== 0 ? (
          !dataChangeHolder?(
          <React.Fragment>
            {isLoading === true ? (
              <Loader />
            ) : (
              <Grid item md={12}>
                <Section
                  onSettings={
                    <ExportOption
                      checked={checked}
                      onClick={() => exportCsv()}
                    />
                  }
                  title={" "}
                  actions={
                    <ButtonGroup
                      setValue={setChecked}
                      value={checked}
                      options={[
                        { label: "Chart", value: "chart" },
                        { label: "Table", value: "table" },
                      ]}
                      primaryColor={"#31b2dc"}
                      width={84}
                    />
                  }
                >
                  <React.Fragment>
                    {checked === "chart" ? (
                      <React.Fragment>
                        {scenariooption["series"] &&
                          scenariooption["series"].length > 0 && (
                            <>
                            {values.channelNameScenario !== "" && (
          <Grid item md={12}>
            {(values.channelNameScenario.split("_")[0].includes("GT") ||
              values.channelNameScenario.split("_")[0].includes("MT") ||
              values.channelNameScenario.split("_")[0].includes("OH")) && (
              <DisplayButton
                name={"MOC Planning"}
                active={mode === "MOC"}
                buttonOnclick={() => {
                  setStateMode("MOC");
                  setDataChange("MOC")
                }}
              />
            )}
            {(values.channelNameScenario.split("_")[0].includes("GT") ||
              values.channelNameScenario.split("_")[0].includes("OH")) && (
              <DisplayButton
                name={"TDP Planning"}
                active={mode === "TDP"}
                buttonOnclick={() => {
                  setStateMode("TDP");
                  setDataChange("TDP")
                }}
              />
            )}
            {(values?.channelNameScenario
                                      ?.split("_")[0]
                                      .includes("GT") ||
                                      values?.channelNameScenario
                                        ?.split("_")[0]
                                        .includes("OH")) && (
                                      <DisplayButton
                                        name={"Book Month"}
                                        active={mode === "Book_Month"}
                                        buttonOnclick={() => {
                                          setStateMode("Book_Month");
                                          setDataChange("Book_Month");
                                        }}
                                      />
                                    )}
          </Grid>
        )}
                            <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={displayData}
                  onChange={(event) => setDisplayData(event.target.value)}
                >
                  <FormControlLabel
                    value="Agg"
                    control={<Radio />}
                    label="Aggregated Data"
                    // onClick={() => onApply(values.primary,values.secondary)}
                    onClick={()=>setDataChange("Agg")}
                  />
                  <FormControlLabel
                    value="Non-Agg"
                    control={<Radio />}
                    label="Non Aggregated Data"
                    disabled={values.historykeys.length < 2}
                    // onClick={() => onApplyNonAgg(values.primary,values.secondary)}
                    onClick={()=>setDataChange("Non-Agg")}
                  />
                </RadioGroup>
              </FormControl>
              <div style={{display:"flex"}}>
                                  <Grid item md={6} sx={{ mt: 2 }}>
                                  <Field
                                    multiple
                                    name="scale"
                                    label="Measure"
                                    options={values?.dropdown}
                                    component={AutocompleteField}
                                  />
                                  </Grid>
                                  <Grid item md={6} sx={{ mt: 2, ml: 2 }}>
                                  <Field
                                    multiple
                                    name="demandDriver"
                                    label="Demand Drivers"
                                    // options={values?.demandDriverOptions}
                                    options={feature.length>1 ? values?.demandDriverOptions :
                                      feature[0] === "MRP" ? values?.demandDriverOptionsMRP : values?.demandDriverOptionsGRM }
                                    component={AutocompleteField}
                                  />
                                  </Grid>
                                </div>
                            <ReactECharts
                              style={{ height: "450px", width: "100%" }}
                              option={
                                scenariooption["series"].length > 0
                                  ? scenariooption
                                  : null
                              }
                            />
                            </>
                          )}
                      </React.Fragment>
                    ) : (
                      <DataGridTable
                        height="400px"
                        onGridReady={(e) => setGridApi(e.api)}
                        suppressRowTransform={true}
                        columnDefs={colDef}
                        rowData={scenarioResult}
                        borders={false}
                      />
                    )}
                  </React.Fragment>
                </Section>
              </Grid>
            )}
          </React.Fragment>):
          <Grid item md={12}>
          <CustomPlaceholder/>
          </Grid>
        ) : (
          <Grid item md={12}>
            <Section title={""}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" gutterBottom>
                  Please select <b>Basepack</b>,<b>Cluster Code</b>
                </Typography>
              </div>
            </Section>
          </Grid>
        )}
      </Grid>
      {showFeatureImp && <Grid item md={12} sx={{ mt: 2 }}>
        <FeatureImportance isScenarioSimulation details={data.channel ?? data.category_name} featureDetails={featureDetails} keys={values.historykeys}/>
      </Grid>}
    </Grid>
  );
}
