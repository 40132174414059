import React,{useState} from 'react'
import Box from "@mui/material/Box";
import { Formik ,useFormikContext} from "formik";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { LeftSideNavbarWrapper, RightSideContainer, ViewportContainer } from 'components/AppLayout/AppLayout.styles';
import FaLossFilter from './Component/FaLossFilter';
import { initialValues_faLoss } from './formData';
import FaLossDetails from './Component/FaLossDetails';


const Content=()=>{
    const [enableAPIs, setEnableAPIs] = useState(true);
    const { setValues, values, setFieldValue } = useFormikContext();
    const handleDrawerOpen = () => {
      setFieldValue("openDrawer", !values.openDrawer);
    };
    const onApply = () => {
      setEnableAPIs(!enableAPIs);
    };
    const onReset = () => {
      setValues(initialValues_faLoss);
      window.sessionStorage.setItem(
        "faLossInitialValues",
        JSON.stringify(initialValues_faLoss)
      );
      setEnableAPIs(!enableAPIs);
    };
    const onSave = () => {
      window.sessionStorage.setItem("performanceDashboardInitialValues", JSON.stringify(values));
    };
    return(
      <ViewportContainer>
        {values.openDrawer === true?(
          <LeftSideNavbarWrapper>
           <FaLossFilter
            onApply={onApply}
            onReset={onReset}
            onSave={onSave}
            enableAPIs={enableAPIs}
            setEnableAPIs={setEnableAPIs}
           />
          </LeftSideNavbarWrapper>
        ):(
          <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor:"pointer",
          }}
        />
        )}
         <RightSideContainer>
          <Box sx={{ margin: 3 }}>
         <FaLossDetails enableAPIs={enableAPIs}/>
          </Box>
        </RightSideContainer>
      </ViewportContainer>
    )
  }
export default function FaLossDashboard() {
    const initialFormValues = window.sessionStorage.getItem("initialValues_faLoss")
      ? JSON.parse(window.sessionStorage.getItem("initialValues_faLoss"))
      : initialValues_faLoss;
    return (
      <Formik initialValues={initialFormValues}>
      <Content/>
      </Formik>
    )
  }
