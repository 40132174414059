import { useQuery } from "react-query";
import { useEffect } from "react";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";

export default function useGetFeatureMonths() {
  const path = "demand_forecast/get_feature_months";
  const { data, isError, error } = useQuery(
    ["get_feature_months"],
    () => getApi(path)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  const featureMonths = [];
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
        featureMonths.push({ label: item, value: item });
    });
  }

  return {
    featureMonths,
  };
}
