import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@emotion/react";

const ButtonGroup = ({
  value,
  setValue,
  options = [],
  primaryColor,
  width,
}) => {
  const handleActiveButton = (event, newactiveButton) => {
    if (newactiveButton) {
      setValue(newactiveButton);
    }
  };
  const theme = useTheme()
  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleActiveButton}>
      {options.map((item, index) => (
        <ToggleButton
          sx={{
            borderRadius: `16px !important`,
            border: `${theme.palette.border.primary} solid !important`,
            height: 30,
            fontSize: 11,
            fontWeight:
              value === item.value ? "600 !important" : "400 !important",
            width: width,
            marginRight:
              index !== options.length - 1
                ? `-10px !important`
                : `0px !important`,
            marginLeft: index === 0 ? `0px !important` : `-10px !important`,
            color: "#000",
            backgroundColor:
              value === item.value
                ? `${theme.palette.mode==="dark"?"#5b5b5b":primaryColor} !important`
                : "#c7d0e1 !important",
            zIndex: value === item.value ? 1001 : 999,
          }}
          value={item.value}
          size="small"
        >
          <strong>{item.label}</strong>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ButtonGroup;
