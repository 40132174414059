import { useSnackbar } from "notistack";

const defaultOptions = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  preventDuplicate: true,
  variant: "default",
};

const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSuccess = (message, options) => {
    enqueueSnackbar(message, {
      ...defaultOptions,
      variant: "success",
      ...options,
    });
  };

  const showError = (message, options) => {
    enqueueSnackbar(message, {
      ...defaultOptions,
      variant: "error",
      ...options,
    });
  };

  const showWarning = (message) => {
    enqueueSnackbar(message, { ...defaultOptions, variant: "warning" });
  };

  const showInfo = (message) => {
    enqueueSnackbar(message, { ...defaultOptions, variant: "info" });
  };

  const showDefault = (message) => {
    enqueueSnackbar(message, { ...defaultOptions });
  };

  return {
    closeSnackbar,
    showSuccess,
    showError,
    showWarning,
    showInfo,
    showDefault,
  };
};

export default useNotify;
