import React, { useState } from "react";
import { alpha } from "@mui/system";
import {
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";

import Section from "components/Section";
import ReactECharts from "echarts-for-react";
import { Field, useFormikContext } from "formik";
import AutocompleteField from "components/form-fields/AutocompleteField";

import useGetFileName from "services/DemandForecast/useGetFileName";
import ButtonGroup from "components/ButtonGroup";
import useGetScenarioName from "services/sceanrioSimulation/useGetScenarioName";
import { useEffect } from "react";
import useGetResult from "services/sceanrioSimulation/useGetResult";
import Loader from "components/Loader";
import { color } from "views/DemandForecast/mappings";
import DisplayButton from "components/DisplayButton/DisplayButton";
import DataGridTable from "components/DataGridTable/DataGridTable";
import FeatureImportance from "views/DemandForecast/components/FeatureImportance";
import Autocomplete from "components/Autocomplete";

import useGetClusterCode from "services/sceanrioSimulation/useClusterCode";
import useGetAnaplanCustomer from "services/sceanrioSimulation/userGetAnalplanCustomer";
import ExportOption from "components/ExportOption/ExportOption";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";
import { lineStyle } from "../hooks/lineStyle";
import { downloadCSVFromJson } from "../hooks/downloadJson";
import useGetScenarioDescription from "services/sceanrioSimulation/useGetScenarioDescription";
import useGetCompareResult from "services/sceanrioSimulation/useGetCompareResult";
import useGetCompareResultNonAgg from "services/sceanrioSimulation/useGetCompareResultNonAgg";
import CustomPlaceholder from "components/CustomPlaceholder";

export default function Comparsion(props, { categoryName }) {
  // const userName = "Shruti.Naik@unilever.com";
  const userName = localStorage.getItem("username");

  const [mode, setStateMode] = useState("MOC");
  const [displayResult, setDisplayResult] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const [checked, setChecked] = useState("chart");
  const [selectedValues, setSelectedValues] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [showFeatureImp, setShowFeatureImp] = useState(false);
  const [featureDetails, setFeatureDetails] = useState();

  const [basepack, setBasepack] = useState(null);
  const [anaplanCustomer, setAnaplanCustomer] = useState(null);
  const { clusterDetails, mutateGetCluster } = useGetClusterCode();
  const { anaplanDetails, mutateGetAnaplan } = useGetAnaplanCustomer();
  const queryClient = useQueryClient();
  const [displayData, setDisplayData] = useState("Agg");
  const [dataChange, setDataChange] = useState("");
  const [dataChangeHolder, setDataChangeHolder] = useState(false);

  useEffect(() => {
    setDataChangeHolder(true);
  }, [dataChange, values.demandDriver]);

  // const { mutateScenarioDescription, IsLoadingSucess, summary } =
  //   useGetScenarioDescription();

  // useEffect(() => {
  //   mutateScenarioDescription({
  //     username: userName,
  //     categoryName: props.categoryName,
  //     scenario_Name: props.scenarioName,
  //   });
  // }, []);

  const {
    scenarioResult,
    colDef,
    mutateScenarioCompareResult,
    isLoading,
    xAxis,
    yAxis,
    legendData,
  } = useGetCompareResult(
    mode,
    basepack?.value,
    values.cluster,
    values.scale,
    values.demandDriver
  );

  const {
    scenarioResultNonAgg,
    colDefNonAgg,
    mutateScenarioCompareResultNonAgg,
    isLoadingNonAgg,
    xAxisNonAgg,
    yAxisNonAgg,
    legendDataNonAgg,
  } = useGetCompareResultNonAgg(
    mode,
    values.dashboardkeys,
    values.cluster,
    values.scale,
    values.demandDriver
  );

  useEffect(() => {
    if (
      basepack !== null &&
      (props.categoryName.startsWith("MT") ||
        props.categoryName.startsWith("MT"))
    )
      if (props.backArrow == true) {
        mutateGetAnaplan({
          username: userName,
          categoryName: props.categoryName.split("_")[0],
          scenario_Name: selectedValues,
          BP: [basepack?.value],
        });
      } else {
        mutateGetAnaplan({
          username: userName,
          categoryName: props.categoryName,
          scenario_Name: [props.scenarioName],
          BP: [basepack?.value],
        });
      }
  }, [basepack]);

  const getseriesData = () => {
    let seriesOptionData = [];
    if (
      scenarioResult &&
      Array.isArray(scenarioResult) &&
      scenarioResult.length
    ) {
      Object.keys(scenarioResult[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if (values.demandDriver.length > 0) {
            if (i.includes("MRP_updated") || i.includes("GRM_updated")) {
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[5 + index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[5 + index], 0.2),
                },
                data: yAxis[i],
                yAxisIndex: 1,
                smooth: true,
              });
            } else {
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxis[i],
                smooth: true,
              });
            }
          } else {
            seriesOptionData.push({
              // name: i,
              name: i.replace(/(Accenture|ACC)/g, "Falcon"),
              type: "line",
              color: color[index],
              lineStyle: {
                ...lineStyle,
                shadowColor: alpha(color[index], 0.2),
              },
              data: yAxis[i],
              smooth: true,
            });
          }
        }
      });
    }
    return seriesOptionData;
  };

  const getSeriesDataNonAgg = () => {
    let seriesOptionData = [];
    if (
      scenarioResultNonAgg &&
      Array.isArray(scenarioResultNonAgg) &&
      scenarioResultNonAgg.length
    ) {
      Object.keys(scenarioResultNonAgg[0]).forEach((i, index) => {
        if (i !== mode && i !== "Key" && !i.includes("_Final_Status")) {
          if (values.demandDriver.length > 0) {
            if (i.includes("MRP_updated") || i.includes("GRM_updated")) {
              seriesOptionData.push({
                // name: i,
                name: i,
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxisNonAgg[i],
                yAxisIndex: 1,
                smooth: true,
              });
            } else {
              seriesOptionData.push({
                // name: i,
                name: i.replace(/(Accenture|ACC)/g, "Falcon"),
                type: "line",
                color: color[index],
                lineStyle: {
                  ...lineStyle,
                  shadowColor: alpha(color[index], 0.2),
                },
                data: yAxisNonAgg[i],
                smooth: true,
              });
            }
          } else {
            seriesOptionData.push({
              // name: i,
              name: i.replace(/(Accenture|ACC)/g, "Falcon"),
              type: "line",
              color: color[index],
              lineStyle: {
                ...lineStyle,
                shadowColor: alpha(color[index], 0.2),
              },
              data: yAxisNonAgg[i],
              smooth: true,
            });
          }
        }
      });
    }
    return seriesOptionData;
  };

  const scenariooption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      // data: scenarioResult && legendData,
      data: displayData === "Agg" ? legendData : legendDataNonAgg,
      right: "6%",
      top: "5%,",
    },

    grid: {
      left: "3%",
      right: "4%",

      containLabel: true,
    },

    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: displayData === "Agg" ? xAxis : xAxisNonAgg,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],
    series:
      displayData === "Agg"
        ? scenarioResult && getseriesData()
        : scenarioResultNonAgg && getSeriesDataNonAgg(),
  };
  const exportCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  };

  const onApply = () => {
    setDataChangeHolder(false);
    setShowFeatureImp(true);
    setDisplayResult(true);
    setFeatureDetails({
      bp: basepack?.value,
      cluster: values.cluster,
      anaplanCustomer: anaplanCustomer?.value,
    });
    if (displayData === "Agg") {
      if (props?.categoryName?.startsWith("MT")) {
        mutateScenarioCompareResult({
          scenarioName: [props.scenarioName],
          fileName: props.categoryName,
          mode: mode,
          username: userName,
          Keys: values.dashboardkeys,
          demandDriver: [...values.demandDriver.map((f) => f.value)],
          anaplanCustomer: anaplanCustomer?.value,
        });
      } else {
        mutateScenarioCompareResult({
          scenarioName: [props.scenarioName],
          fileName: props.categoryName,
          mode: mode,
          username: userName,
          Keys: values.dashboardkeys,
          demandDriver: [...values.demandDriver.map((f) => f.value)],
        });
      }
    } else {
      if (values.categoryNameScenario.startsWith("MT")) {
        mutateScenarioCompareResultNonAgg({
          scenarioName: [props.scenarioName],
          fileName: props.categoryName,
          mode: mode,
          username: userName,
          Keys: values.dashboardkeys,
          demandDriver: [...values.demandDriver.map((f) => f.value)],
          anaplanCustomer: anaplanCustomer?.value,
        });
      } else {
        mutateScenarioCompareResultNonAgg({
          scenarioName: [props.scenarioName],
          fileName: props.categoryName,
          mode: mode,
          username: userName,
          Keys: values.dashboardkeys,
          demandDriver: [...values.demandDriver.map((f) => f.value)],
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Grid>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={4} sx={{ mt: 2 }}>
                <Card>
                  <CardContent sx={{ height: "100px" }}>
                    <Typography variant="h6" color="primary">
                      Scenario Name: {props.scenarioName}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sx={{ mt: 2 }}>
                <Card>
                  <CardContent sx={{ height: "100px" }}>
                    <Typography variant="h6" color="primary">
                      Category Name: {props.categoryName}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sx={{ mt: 2 }}>
                <Card>
                  <CardContent sx={{ height: "100px" }}>
                    <Typography variant="h6" color="primary">
                      Description: {props.scenario_description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <React.Fragment>
              <div style={{ display: "flex", marginBottom: "16px" }}>
                <FormControl size="small" sx={{ mt: 2, width: 300 }}>
                  <Field
                    multiple
                    name="dashboardkeys"
                    label="Select the keys"
                    options={props.basePackDetails}
                    noBorders={false}
                    variant="outlined"
                    fullWidth
                    component={MultiSelectDropdownF}
                  />
                </FormControl>
                {props.categoryName && props.categoryName?.startsWith("MT") && (
                  <FormControl size="small" sx={{ mt: 2, ml: 2, width: 300 }}>
                    <Autocomplete
                      label="Anaplan Customer"
                      value={anaplanCustomer}
                      onChange={(e, value) => {
                        setAnaplanCustomer(value);
                      }}
                      options={anaplanDetails}
                    />
                  </FormControl>
                )}

                {/* <Field
                  multiple
                  name="scale"
                  label="Measure"
                  options={values?.dropdown}
                  component={AutocompleteField}
                  sx={{ mt: 2, width: 300, ml: 2 }}
                /> */}

                <Button
                  variant="contained"
                  style={{
                    marginTop: "12px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    float: "right",
                  }}
                  onClick={onApply}
                  // disabled={!basepack?.value && !values.cluster}
                >
                  Apply
                </Button>
              </div>
            </React.Fragment>

            {values.dashboardkeys.length !== 0 && displayResult ? (
              !dataChangeHolder ? (
                <React.Fragment>
                  {isLoading === true ? (
                    <Grid item md={12}>
                      <Loader />
                    </Grid>
                  ) : (
                    <Grid item md={12}>
                      <Section
                        onSettings={
                          <ExportOption
                            checked={checked}
                            onClick={() => exportCsv()}
                          />
                        }
                        title={" "}
                        actions={
                          <ButtonGroup
                            setValue={setChecked}
                            value={checked}
                            options={[
                              { label: "Chart", value: "chart" },
                              { label: "Table", value: "table" },
                            ]}
                            primaryColor={"#31b2dc"}
                            width={84}
                          />
                        }
                      >
                        <React.Fragment>
                          {checked === "chart" ? (
                            <React.Fragment>
                              {scenariooption["series"] &&
                                scenariooption["series"].length > 0 && (
                                  <>
                                    {props.categoryName && (
                                      <Grid item md={12} sx={{ mt: 1, mb: 1 }}>
                                        {(props.categoryName
                                          .split("_")[0]
                                          .includes("GT") ||
                                          props.categoryName
                                            .split("_")[0]
                                            .includes("MT") ||
                                          props.categoryName
                                            .split("_")[0]
                                            .includes("OH")) && (
                                          <DisplayButton
                                            name={"MOC Planning"}
                                            active={mode === "MOC"}
                                            buttonOnclick={() => {
                                              setStateMode("MOC");
                                              setDataChange("MOC");
                                            }}
                                          />
                                        )}
                                        {(props.categoryName
                                          .split("_")[0]
                                          .includes("GT") ||
                                          props.categoryName
                                            .split("_")[0]
                                            .includes("OH")) && (
                                          <DisplayButton
                                            name={"TDP Planning"}
                                            active={mode === "TDP"}
                                            buttonOnclick={() => {
                                              setStateMode("TDP");
                                              setDataChange("TDP");
                                            }}
                                          />
                                        )}
                                        {(props.categoryName
                                          .split("_")[0]
                                          .includes("GT") ||
                                          props.categoryName
                                            .split("_")[0]
                                            .includes("OH")) && (
                                          <DisplayButton
                                            name={"Book Month"}
                                            active={mode === "Book_Month"}
                                            buttonOnclick={() => {
                                              setStateMode("Book_Month");
                                              setDataChange("Book_Month");
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    )}
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={displayData}
                                        onChange={(event) =>
                                          setDisplayData(event.target.value)
                                        }
                                      >
                                        <FormControlLabel
                                          value="Agg"
                                          control={<Radio />}
                                          label="Aggregated Data"
                                          // onClick={() => onApply(values.primary,values.secondary)}
                                          onClick={() => setDataChange("Agg")}
                                        />
                                        <FormControlLabel
                                          value="Non-Agg"
                                          control={<Radio />}
                                          label="Non Aggregated Data"
                                          disabled={
                                            values.dashboardkeys?.length < 2
                                          }
                                          // onClick={() => onApplyNonAgg(values.primary,values.secondary)}
                                          onClick={() =>
                                            setDataChange("NonAgg")
                                          }
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    <div style={{ display: "flex" }}>
                                      <Grid item md={6} sx={{ mt: 2 }}>
                                        <Field
                                          multiple
                                          name="scale"
                                          label="Measure"
                                          options={values?.dropdown}
                                          component={AutocompleteField}
                                        />
                                      </Grid>
                                      <Grid item md={6} sx={{ mt: 2, ml: 2 }}>
                                        <Field
                                          multiple
                                          name="demandDriver"
                                          label="Demand Drivers"
                                          options={
                                            props?.feature.length > 1
                                              ? values?.demandDriverOptions
                                              : props?.feature[0] === "MRP"
                                              ? values?.demandDriverOptionsMRP
                                              : values?.demandDriverOptionsGRM
                                          }
                                          component={AutocompleteField}
                                        />
                                      </Grid>
                                    </div>
                                    <ReactECharts
                                      style={{ height: "450px", width: "100%" }}
                                      option={
                                        scenariooption["series"].length > 0
                                          ? scenariooption
                                          : null
                                      }
                                    />
                                  </>
                                )}
                            </React.Fragment>
                          ) : (
                            <DataGridTable
                              height="400px"
                              onGridReady={(e) => setGridApi(e.api)}
                              suppressRowTransform={true}
                              columnDefs={colDef}
                              rowData={scenarioResult}
                              borders={false}
                            />
                          )}
                        </React.Fragment>
                      </Section>
                    </Grid>
                  )}
                </React.Fragment>
              ) : (
                <Grid item md={12}>
                  <CustomPlaceholder />
                </Grid>
              )
            ) : (
              <Grid item md={12}>
                <Section title={""}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body2" gutterBottom>
                      Please select the keys
                    </Typography>
                  </div>
                </Section>
              </Grid>
            )}
          </Grid>
          {showFeatureImp && (
            <Grid item md={12} sx={{ mt: 2 }}>
              <FeatureImportance
                isScenarioSimulation
                details={props.categoryName}
                featureDetails={featureDetails}
                keys={values.dashboardkeys}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
