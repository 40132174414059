import React, { useState, useCallback } from "react";
import { Grid, Button } from "@mui/material";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import { useEffect } from "react";
import useGetLineWise from "services/SalesForecastVersioning/Adoption/useGetLineWise";
import { useFormikContext, Field } from "formik";
import Loader from "components/Loader";
import DataGridTable from "components/DataGridTable/DataGridTable";
import { LinewiseDropdown } from "../formData";
import AutocompleteField from "components/form-fields/AutocompleteField";
export default function Linewise({ decimals, enableAPIs }) {
  const { values } = useFormikContext();
  const { isLoadingLinewise, colDefLinewise, mutate, rowDataLinewise } =
    useGetLineWise(decimals, values.baseLinewise);

  const onApply = useCallback((values) => {
    mutate({
      base: values.baseLinewise,
      filters: values.filters,
      filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
      input_type: values.uploadCheck ? "upload" : "default",
      username: localStorage.getItem("username"),
    });
  }, []);

  useEffect(() => {
    onApply(values);
  }, [enableAPIs, onApply]);

  const [gridApi, setGridApi] = useState();

  const exportCsv=()=>{
    if(gridApi){
      gridApi.exportDataAsCsv();
    }
  }
  return (
    <Grid item md={12}>
      <Section 
        title={"Linewise"} 
        onSettings={
            <ExportOption
              onClick={()=>exportCsv()}
            />
        }
      >
        <Field
          label="Base row"
          name="baseLinewise"
          options={LinewiseDropdown}
          isOptionEqualToValue={(option, value) => option.value === value}
          component={AutocompleteField}
        />
        <Button
          size="small"
          variant="contained"
          onClick={() => onApply(values)}
          sx={{ mt: 2 }}
        >
          Apply
        </Button>
        {isLoadingLinewise === true ? (
          <Loader />
        ) : (
          <DataGridTable
            height="700px"
            onGridReady={(e) => setGridApi(e.api)}
            suppressRowTransform={true}
            columnDefs={colDefLinewise}
            suppressFieldDotNotation={true}
            rowData={rowDataLinewise}
            borders={false}
          />
        )}
      </Section>
    </Grid>
  );
}
