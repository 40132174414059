import styled from "@emotion/styled";

export const NavBarWrapper = styled.div`
  position: relative;
  display: inline-flex;
  height: 100%;
  width: 100%;
`;

export const SideBar = styled.div`
  background: ${({ theme }) => `${theme.palette.background.paper}`};
  width: 100%;
  flex: 0 0 300;
  box-shadow: ${({ theme }) => `${theme.shadows[3]}`};
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1200;
  border-top: 1px solid #eeeeee;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  left: 0;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track  background*/

  ::-webkit-scrollbar-track {
    width: 10px;
    border-radius: 5px;
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent !important;
    border-radius: 9px !important;
    background-clip: content-box;
    background: ${({ theme }) => `${theme.palette.grey["300"]}`};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    width: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
`;

export const Content = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.spacing(2)}`};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;
