const attachToken = (config) => {
  return config;
};
//request interceptors
const REQUEST_INTERCEPTORS = [attachToken];

export default function registerRequestInterceptors(api) {
  REQUEST_INTERCEPTORS.forEach((interceptor) =>
    api.interceptors.request.use(interceptor)
  );
}
