import { useEffect } from "react";
import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
export default function useGetDemandForecastFilter() {
  const path = "/filter_list_test/gettestallfilters";
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (payload) => postApi(path, payload)
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  return {
    data,
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
