import Axios from "axios";

const extractErrorAndRethrow = (error) => {
  if (Axios.isCancel(error)) {
    // API request is canceled
    return Promise.reject({
      message: error?.response?.data,
      isCancelled: true,
    });
  }

  return Promise.reject(error);
};
//response
const RESPONSE_INTERCEPTORS = [[undefined, extractErrorAndRethrow]];

export default function registerResponseInterceptors(api) {
  RESPONSE_INTERCEPTORS.forEach((handlers) =>
    api.interceptors.response.use(...handlers)
  );
}
