import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {Divider} from "@mui/material";

export const StyledLink = styled(Link)`
  text-decoration: none ;
  color: #fff;
`;

export const Line = styled(Divider)`
  margin: 0;
  flex-shrink: 0;
  border-style: solid;
  border-color: #ffffff;
  color: #fff;
`;

