import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useFormikContext, Field } from "formik";
import SelectField from "components/form-fields/SelectField";
import { StyledCard } from "components/Section/Section.styles";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import ButtonGroup from "components/ButtonGroup";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";
import { hireachyDropDown, colDef } from "../formData";
import useGetPerformanceChannelData from "services/SalesForecastVersioning/useGetPerforanceChannelData";
import ChannelWisePerformanceTable from "./ChannelWisePerformanceTable";
import useGetPerformanceCusterData from "services/SalesForecastVersioning/useGetPerformanceClusterData";
import useGetPerformanceSegmentData from "services/SalesForecastVersioning/useGetPerformanceSegmentData";
import useGetFileUploadedList from "services/DemandForecast/useGetFileUploadedList";
import DeleteSummaryFiles from "../../Summary/components/DeleteSummaryFiles";
import SummaryUpload from "../../Summary/components/SummaryUpload";

export default function PerformanceSummary({ enableAPIs, tag }) {
  // const [decimal, setDecimal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [checked, setChecked] = useState("table");
  const { values, setFieldValue } = useFormikContext();

  const { demandForecastUploadedFile, refetch } = useGetFileUploadedList({
    username: localStorage.getItem("username"),
    tag: tag,
  });

  const { isLoading, mutate, rowData } = useGetPerformanceChannelData();
  const { isLoadingCluster, mutateCluster, rowDataCluster } =
    useGetPerformanceCusterData(values.decimal);
  const { isLoadingSegment, mutateSegment, rowDataSegment } =
    useGetPerformanceSegmentData(values.decimal);
  const [gridApi1, setGridApi1] = useState();
  const [gridApi2, setGridApi2] = useState();
  const [gridApi3, setGridApi3] = useState();

  const exportChannelCsv = () => {
    if (gridApi1) {
      gridApi1.exportDataAsCsv();
    }
  };

  const exportClusterCsv = () => {
    if (gridApi2) {
      gridApi2.exportDataAsCsv();
    }
  };

  const exportSegmentCsv = () => {
    if (gridApi3) {
      gridApi3.exportDataAsCsv();
    }
  };

  const onApply = useCallback(
    (values) => {
      mutate({
        heirarchy: values.hierarchyChannel?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
        input_type: values.uploadCheck ? "upload" : "default",
        username: localStorage.getItem("username"),
      });
    },
    [mutate]
  );
  const onApplyCluster = useCallback(
    (values) => {
      mutateCluster({
        heirarchy: values.hierarchyCluster?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
        input_type: values.uploadCheck ? "upload" : "default",
        username: localStorage.getItem("username"),
      });
    },
    [mutateCluster]
  );
  const onApplySegment = useCallback(
    (values) => {
      mutateSegment({
        heirarchy: values.hierarchySegment?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
        input_type: values.uploadCheck ? "upload" : "default",
        username: localStorage.getItem("username"),
      });
    },
    [mutateSegment]
  );
  useEffect(() => {
    onApply(values);
    onApplyCluster(values);
    onApplySegment(values);
  }, [onApply, enableAPIs, onApplyCluster, onApplySegment]);

  const filteredOptions = hireachyDropDown.filter(
    (option) =>
      !values.hierarchyChannel?.some((h) => h.hierarchy === option.value)
  );
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} md={12} justifyContent="flex-end">
            <Grid item md={2} xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  // onChange={(event) => setDecimal(event.target.value)}
                  onChange={(event) => setFieldValue("decimal",event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <Field
                name="demandForecastUploadedFile"
                label={"Uploaded File Name"}
                component={SelectField}
                options={[...demandForecastUploadedFile]}
                fullWidth
                disabled={demandForecastUploadedFile.length === 0}
              />
            </Grid>
            <Grid item md={1}>
              <Tooltip title="Select Uploaded file">
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.uploadCheck}
                  onChange={(e) => {
                    setFieldValue("uploadCheck", e.target.checked);
                  }}
                  disabled={demandForecastUploadedFile.length === 0}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={"Delete File"}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenDelete(true)}
                  disabled={demandForecastUploadedFile.length === 0}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={"Upload File"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModal(true)}
                >
                  <CloudUploadIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <DeleteSummaryFiles
        tag={tag}
        open={openDelete}
        setOpen={setOpenDelete}
        demandForecastUploadedFile={demandForecastUploadedFile}
        refetch={refetch}
      />
      <SummaryUpload
        tag={tag}
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetch}
      />
      <Grid item md={12}>
        <Section
          title={"Channel Wise Performance"}
          onSettings={
            <ExportOption
              onClick={()=>exportChannelCsv()}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <HierarchyFieldArray
                values={values}
                options={filteredOptions}
                fieldName={"hierarchyChannel"}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => onApply(values)}
                sx={{ mt: 2 }}
              >
                Apply
              </Button>
            </Grid>
            <Grid item md={12}>
              <ChannelWisePerformanceTable
                height={378}
                gridApi={gridApi1}
                setGridApi={setGridApi1}
                colDef={colDef(values.decimal)}
                isLoading={isLoading}
                data={rowData}
              />
            </Grid>
          </Grid>
        </Section>

        <div style={{ marginTop: "20px" }}>
          <Section
            title={"Cluster Wise Performance"}
            onSettings={
              <ExportOption
                onClick={()=>exportClusterCsv()}
              />
            }
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <HierarchyFieldArray
                  values={values}
                  options={hireachyDropDown}
                  fieldName={"hierarchyCluster"}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => onApplyCluster(values)}
                  style={{
                    height: "32px",
                    width: "70px",
                  }}
                  sx={{ mt: 2 }}
                >
                  Apply
                </Button>
                <ChannelWisePerformanceTable
                  height={378}
                  gridApi={gridApi2}
                  setGridApi={setGridApi2}
                  colDef={colDef(values.decimal)}
                  isLoading={isLoadingCluster}
                  data={rowDataCluster}
                />
              </Grid>
            </Grid>
          </Section>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Section
            title={"Segment Wise Performance"}
            onSettings={
              <ExportOption
                onClick={()=>exportSegmentCsv()}
              />
            }
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <HierarchyFieldArray
                  values={values}
                  options={hireachyDropDown}
                  fieldName={"hierarchySegment"}
                />

                <Button
                  size="small"
                  variant="contained"
                  onClick={() => onApplySegment(values)}
                  style={{
                    height: "32px",
                    width: "70px",
                  }}
                  sx={{ mt: 2 }}
                >
                  Apply
                </Button>
                <ChannelWisePerformanceTable
                  height={378}
                  gridApi={gridApi3}
                  setGridApi={setGridApi3}
                  colDef={colDef(values.decimal)}
                  isLoading={isLoadingSegment}
                  data={rowDataSegment}
                />
              </Grid>
            </Grid>
          </Section>
        </div>
      </Grid>
    </Grid>
  );
}
