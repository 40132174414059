import React, { useMemo } from "react";
import Loader from "components/Loader/Loader";

import SummaryTableWrapper from "views/Summary/components/SummaryTableWrapper";

export default function FAReportTable(props) {
  const { height, gridApi, setGridApi, colDef, data, isLoading } = props;

  const getDataPath = useMemo(() => {
    return (data) => {
      return data["region"];
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Row Label",
      width: 150,
      pinned: "left",
      cellRendererParams: {
        suppressCount: true,
      },
      headerTooltip: "Row Label",
      cellStyle: () => {
        return {
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        };
      },
    };
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <SummaryTableWrapper
          height={height}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          animateRows={true}
          gridApi={gridApi}
          setGridApi={setGridApi}
          getDataPath={getDataPath}
          columnDefs={colDef}
          rowData={data ?? []}
        />
      )}
    </React.Fragment>
  );
}
