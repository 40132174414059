import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PublicIcon from "@mui/icons-material/Public";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export const initialValues_Adoption = {
  filters: {
    BP: [],
    Cluster: [],
    Channel: [],
    Branch: [],
    "Sales.Category": [],
    Category: [],
    "Sales Business": [],
    "Sales Business Group": [],
    UOM: [],
    "Pack.Cluster": [],
    Segment: [],
    Brand: [],
    "Brand.Variant": [],
    Seasonal_Tagging: [],
    Scope_Flag: [],
    "Modified Brand": [],
    "Top Packs": [],
    SubCategory: [],
  },
  openDrawer: true,
  decimal:0,
  demandForecastUploadedFile:[],
  filename: "",
  baseLinewise: "Category",
  baseWeighted: "Category",
  hierarchyAccurancy: [{ hierarchy: "Category" }],
  hierarchyWeighed: [{ hierarchy: "Category" }],
};
export const filtersData = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BP",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand.Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack.Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  // {
  //   title: "Time",
  //   icon: AlarmIcon,
  //   filters: [
  //     {
  //       label: "Year",
  //       name: "Year",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "BM Year",
  //       name: "Year_BM",
  //       component: MultiSelectDropdown,
  //     },
  //   ],
  // },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales.Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "SubCategory",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales Business Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  // {
  //   title: "Format",
  //   icon: PendingOutlinedIcon,
  //   filters: [
  //     {
  //       label: "Pack Cluster",
  //       name: "Pack.Cluster",
  //       component: MultiSelectDropdown,
  //     },
  // {
  //   label: "Format 1",
  //   name: "Format1",
  //   component: MultiSelectDropdown,
  // },
  // {
  //   label: "Format 2",
  //   name: "Format2",
  //   component: MultiSelectDropdown,
  // },
  //   ],
  // },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top Packs",
        component: MultiSelectDropdown,
      },
    ],
  },
  //     {
  //       label: "Active/Delisted",
  //       name: "Active_Delisted",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "Price Point Tagging",
  //       name: "Price_point_Tagging",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "Scope Flag",
  //       name: "Scope_Flag",
  //       component: MultiSelectDropdown,
  //     },
  //   ],
  // },
];
export const AdoptionDropdown = [
  {
    label: "BP",
    value: "BP",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "Channel",
    value: "Channel",
  },

  {
    label: "Branch",
    value: "Branch",
  },
  {
    label: "Sales Category",
    value: "Sales.Category",
  },
  {
    label: " Category",
    value: "Category",
  },
  {
    label: "Sales Business",
    value: "Sales Business",
  },
  {
    label: " Sales Business Group",
    value: "Sales Business Group",
  },
  {
    label: "Pack Cluster",
    value: "Pack.Cluster",
  },
  {
    label: "UOM",
    value: "UOM",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: "Brand",
    value: "Brand",
  },
  {
    label: "Modified Brand",
    value: "Modified Brand",
  },
  {
    label: "Brand variant",
    value: "Brand.variant",
  },
  {
    label: "Scope_Flag",
    value: "Scope Flag",
  },
  {
    label: "Top Packs",
    value: "Top Packs",
  },
  {
    label: "Seasonal_Tagging",
    value: "Seasonal Tagging",
  },
  {
    label: "Sub Category",
    value: "SubCategory",
  },

  {
    label: "Seasonal_Tagging",
    value: "Seasonal_Tagging",
  },
];

export const LinewiseDropdown = [
  {
    label: "BP",
    value: "BP",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "Channel",
    value: "Channel",
  },

  {
    label: "Branch",
    value: "Branch",
  },
  {
    label: "Sales Category",
    value: "Sales.Category",
  },
  {
    label: " Category",
    value: "Category",
  },
  {
    label: "Sales Business",
    value: "Sales Business",
  },
  {
    label: " Sales Business Group",
    value: "Sales Business Group",
  },
  {
    label: "Pack Cluster",
    value: "Pack.Cluster",
  },
  {
    label: "UOM",
    value: "UOM",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: "Brand",
    value: "Brand",
  },
  {
    label: "Modified Brand",
    value: "Modified Brand",
  },
  {
    label: "Brand variant",
    value: "Brand.variant",
  },
  {
    label: "Scope_Flag",
    value: "Scope Flag",
  },
  {
    label: "Top Packs",
    value: "Top Packs",
  },
  {
    label: "Seasonal_Tagging",
    value: "Seasonal Tagging",
  },
  {
    label: "Sub Category",
    value: "SubCategory",
  },

  {
    label: "Seasonal_Tagging",
    value: "Seasonal_Tagging",
  },
];
