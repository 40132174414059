import React,{useState} from 'react'
import Box from "@mui/material/Box";
import { Formik ,useFormikContext} from "formik";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { initialValues_Adoption } from './formData';
import { LeftSideNavbarWrapper, RightSideContainer, ViewportContainer } from 'components/AppLayout/AppLayout.styles';
import AdoptionFilter from './AdoptionFilter';
import AdoptionDetails from './AdoptionDetails';

const Content=()=>{
    const [enableAPIs, setEnableAPIs] = useState(true);
    const { setValues, values, setFieldValue } = useFormikContext();
    const handleDrawerOpen = () => {
      setFieldValue("openDrawer", !values.openDrawer);
    };
    const onApply = () => {
      setEnableAPIs(!enableAPIs);
    };
    const onReset = () => {
      setValues(initialValues_Adoption);
      window.sessionStorage.setItem(
        "performanceDashboardInitialValues",
        JSON.stringify(initialValues_Adoption)
      );
      setEnableAPIs(!enableAPIs);
    };
    const onSave = () => {
      window.sessionStorage.setItem("performanceDashboardInitialValues", JSON.stringify(values));
    };
    return(
      <ViewportContainer>
        {values.openDrawer === true?(
          <LeftSideNavbarWrapper>
           <AdoptionFilter
            onApply={onApply}
            onReset={onReset}
            onSave={onSave}
            enableAPIs={enableAPIs}
            setEnableAPIs={setEnableAPIs}
           />
          </LeftSideNavbarWrapper>
        ):(
          <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor:"pointer",
          }}
        />
        )}
         <RightSideContainer>
          <Box sx={{ margin: 3 }}>
           <AdoptionDetails enableAPIs={enableAPIs} tag="adoption"/>
          </Box>
        </RightSideContainer>
      </ViewportContainer>
    )
  }
export default function AdoptionDashboard() {
    const initialFormValues = window.sessionStorage.getItem("initialValues_ForecastVersioningPerformanceSummary")
      ? JSON.parse(window.sessionStorage.getItem("initialValues_ForecastVersioningPerformanceSummary"))
      : initialValues_Adoption;
    return (
      <Formik initialValues={initialFormValues}>
      <Content/>
      </Formik>
    )
  }
