import React from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import StartingPage from "./StartingPage";
import Login from "views/Login/Login";

export default function AppLayout() {
  const username=localStorage.getItem("username")
  return (
    <>
      <AuthenticatedTemplate>
        <StartingPage username={username}/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login/>
      {/* <StartingPage username={username}/> */}
      </UnauthenticatedTemplate>
    </>
  );
}
