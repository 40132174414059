export const msalConfig = {
  auth: {
    clientId: "5dc9e55b-94e5-4b98-81b7-2e032ae448e7",
    authority:
      "https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
