import AppHeader from "components/AppHeader/AppHeader";
import React, { useEffect, useState } from "react";
import Login from "views/Login/Login";
import { Header, Root } from "./AppLayout.styles";
import AppRoutes from "./AppRoutes";
import { useMsal } from "@azure/msal-react";
import { handleLogout } from "ui-component/SignoutButton";
import useGetUserDetails from "services/DemandForecast/useGetUserDetails";

export default function StartingPage({ username }) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const { instance } = useMsal();
  const loginTime = new Date();
  const startTime = loginTime.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const { mutate } = useGetUserDetails();
 

  useEffect(() => {
    let logoutTimer = setTimeout(() => {
      // logout();
    }, 5 * 60 * 1000);

    function resetTimer() {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        // logout();
      }, 5 * 60 * 1000);
    }
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, []);
 
  async function logout() {
    try {
      // Call useGetUserDetails to fetch user details before logging out
      await Promise.resolve(
        mutate({
          starttime: startTime,
          endtime: new Date().toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          name: username,
          date: new Date().toLocaleDateString(),
        })
      );
      // Logout user
      setIsLoggedIn(false);
      handleLogout("redirect", instance);

      // Perform any other logout actions, such as clearing local storage
    } catch (error) {
      console.error("Error fetching user details before logout", error);
    }
  }

  if (isLoggedIn) {
    return (
      <Root>
        <Header>
          <AppHeader  startTime={startTime} username={username}/>
        </Header>
        <AppRoutes username={username}/>
      </Root>
    );
  } else {
    return <Login />;
  }
}
