import { Grid, Box, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useState, useEffect } from "react";
import Dialog from "components/Dialog";
import { DragAndUpload } from "components/DragAndUpload";
import useGetUploadSummaryFile from "services/DemandForecast/useGetUploadSumaryFile";
import { useAccount } from "@azure/msal-react";

const SummaryUpload = ({ open, setOpen, uploadedFiles, refetch, tag }) => {
  const [uploadedFile, setUploadedFile] = useState();
  const [fileName, setFileName] = useState("");
  const [result, setResult] = useState("");
  const [showError, setShowError] = useState(false);
  const uniqueFileName = (event) => {
    setFileName(event.target.value);
  };

  const localAccountId = localStorage.getItem("localAccountId");
  const accountInfo = useAccount({ localAccountId });

  const { mutate, isLoading, isSuccess, isError } = useGetUploadSummaryFile();

  useEffect(() => {
    if (isSuccess) {
      setResult("File uploaded successfully");
      refetch();
    }
    if (isError) {
      setResult("Failed to upload file");
    }
    if (isLoading) {
      setResult("Loading ...");
    }
  }, [isSuccess, refetch, isLoading, isError]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setFileName("");
        setUploadedFile();
        setResult("");
      }}
      header={
        <Typography variant="title" color={"primary"}>
          Upload file
        </Typography>
      }
      maxWidth="lg"
      footer={
        <Box sx={{ padding: (0, 1) }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={() => {
              if (
                uploadedFiles &&
                Array.isArray(uploadedFiles) &&
                uploadedFiles.includes(fileName)
              ) {
                setShowError(true);
              } else {
                setShowError(false);
                const formData = new FormData();
                formData.append("username", localStorage.getItem("username"));
                formData.append("file", uploadedFile);
                formData.append("filename", fileName);
                formData.append("tag", tag);
                mutate(formData);
              }
            }}
          >
            Upload File
          </LoadingButton>
        </Box>
      }
    >
      <Grid
        container
        flexDirection={"column"}
        sx={{ paddingTop: 1 }}
        spacing={2}
      >
        <Grid item md={12}>
          <TextField
            error={showError}
            label="Enter File Name"
            variant="outlined"
            value={fileName}
            onChange={uniqueFileName}
            helperText={showError ? "Name already exists" : null}
            fullWidth
            autoComplete="off"
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
          />
        </Grid>
        <Grid item md={12}>
          <DragAndUpload
            label="Upload file"
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            supportedFileFormat="Excel/CSV file format"
          />
        </Grid>
        <Grid item md={12}>
          <Typography variant="subtitle2">Status : {result}</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SummaryUpload;
