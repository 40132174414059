import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";
import { color } from "views/DemandForecast/mappings";
import "../../components/DataGridTable/DataGridTable.css";

export default function useGetOverallTable(term, decimals = 4) {
  const path = "/demand_forecast/getoutquater";
  const {
    data = [],
    isLoading,
    mutate,
    error,
    isError,
  } = useMutation((payload) => postApi(path, payload));
  const { showError } = useNotify();
  const xAxis = [];
  const series = [];
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  if (Array.isArray(data) && data.length) {
    ["MQ", "JQ", "SQ", "DQ"].forEach((a, index) => {
      series.push({
        name: a,
        type: "bar",
        barGap: 1.3,
        emphasis: {
          focus: "series",
        },

        color: color[index + 4],

        data: data.map((i) => {
          return i[a];
        }),
        label: {
          normal: {
            formatter: function (params) {
              var val = params.value.toFixed(decimals);
              return val;
            },
            show: true,
            position: "top",
            rotate: 90,
          },
        },
      });
    });
    data?.forEach((i) => {
      xAxis.push(i.index);
    });
  }
  const qtrOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value) => value.toFixed(decimals),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          name: term + "Summary-qtr",
        },
      },
    },

    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      // bottom: "9%",
      containLabel: true,
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 140,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: {
      type: "value",
    },
    // label: {
    //   rotate: 90,
    // },

    series: series,
  };

  return {
    rowDataValue: data,
    isLoading,
    mutate,
    rowData: data,
    qtrOptions,
    data,
  };
}
