import React, { useEffect, useState } from "react";
import useGetSeasonality from "services/outQuarter/useGetSeasonality";
import SeasonalityTable from "./SeasonalityTable";
import { useFormikContext } from "formik";
import CagrSeason from "./CagrSeason";

export default function SeasonalityDashboard(props) {
  const [gridOptions1] = useState({
    alignedGrids: [],
  });
  const [gridOptions2] = useState({
    alignedGrids: [],
  });
  const [gridApi1, setGridApi1] = useState();
  const [gridApi2, setGridApi2] = useState();
  const {
    term,
    season,
    gridApi,
    setGridApi,
    choice,
    chart,
    enableAPIs,
    decimals,
    ...rest
  } = props;
  const { values } = useFormikContext();
  const { colDef, mutate, data, optionsQtr, isLoading } = useGetSeasonality(
    season,
    term,
    decimals,
    values.filename
  );
  useEffect(() => {
    if (values.filename) {
      mutate({
        term: term,
        filename: values.filename,
        season: season,
        ...values.filters,
      });
    }
  }, [enableAPIs, mutate, term, season]);
  return (
    <React.Fragment>
      <SeasonalityTable
        isLoading={isLoading}
        colDef={colDef}
        data={data}
        optionsQtr={optionsQtr}
        term={term}
        season={season}
        decimals={decimals}
        chart={chart}
        choice={choice}
        gridOptions={gridOptions1}
        setGridApi={setGridApi1}
        gridApi={gridApi1}
      />
      <CagrSeason
        isLoading={isLoading}
        colDef={colDef}
        data={data}
        optionsQtr={optionsQtr}
        term={term}
        season={season}
        decimals={decimals}
        chart={chart}
        choice={choice}
        gridOptions={gridOptions2}
        setGridApi={setGridApi2}
        gridApi={gridApi2}
      />
    </React.Fragment>
  );
}
