import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ButtonGroup from "components/ButtonGroup";
import Section from "components/Section";
import { StyledCard } from "components/Section/Section.styles";
import React, { useState } from "react";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import SeasonalityTable from "./SeasonalityTable";
import { getDataForCSV } from "utils/constants";
import { useFormikContext } from "formik";

import OutQuarterSummaryDashboard from "./OutQuarterSummaryDashboard";
import { exportToCSV } from "./hooks/exportToCSV";
import { TypographyNameDisplay } from "./hooks/TypographyNameDisplay";
import SeasonalityDashboard from "./SeasonalityDashboard";

export default function OutQuarter({ enableAPIs }) {
  const { values, setFieldValue } = useFormikContext();

  const [checked1, setChecked1] = useState("table");
  const [season, setSeason] = React.useState("Winter");

  const [chart1, setChart1] = useState("qtr");
  // const [decimals, setDecimals] = useState(0);

  const [gridApi4, setGridApi4] = useState();
  let gridApi6;

  // const [measure, setMeasure] = React.useState("Vol");
  const handleChange = (event) => {
    setSeason(event.target.value);
  };

  const handleChangeDecimals = (event) => {
    setFieldValue("decimal",event.target.value);
  };
  const [gridOptions1] = useState({
    alignedGrids: [],
  });
  const [gridOptions2] = useState({
    alignedGrids: [],
  });
  const [gridOptions3] = useState({
    alignedGrids: [],
  });
  const [gridOptions4] = useState({
    alignedGrids: [],
  });
  const [gridOptions5] = useState({
    alignedGrids: [],
  });
  const [gridOptions6] = useState({
    alignedGrids: [],
  });
  gridOptions1.alignedGrids.push(gridOptions2);
  gridOptions2.alignedGrids.push(gridOptions1);
  gridOptions3.alignedGrids.push(gridOptions2);
  gridOptions2.alignedGrids.push(gridOptions3);
  gridOptions4.alignedGrids.push(gridOptions5);
  gridOptions5.alignedGrids.push(gridOptions6);
  gridOptions6.alignedGrids.push(gridOptions4);

  const handleChangeMeasurment = (event) => {
    // setMeasure(event.target.value);
    setFieldValue("measure",event.target.value);
  };
  return (
    <Box sx={{ margin: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <StyledCard>
            <Grid item container spacing={2} justifyContent="flex-end" md={12}>
              <Grid item md={2}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Measure</InputLabel>
                  <Select
                    value={values.measure}
                    label="Measure"
                    onChange={handleChangeMeasurment}
                  >
                    <MenuItem value={"Vol"}>Volume</MenuItem>
                    <MenuItem value={"Value"}>Value</MenuItem>
                    <MenuItem value={"Each"}>Eaches</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                  <Select
                    value={values.decimal}
                    label="Decimal"
                    onChange={handleChangeDecimals}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>
        <Grid item md={12}>
          <OutQuarterSummaryDashboard
            values={values}
            enableAPIs={enableAPIs}
            decimals={values.decimal}
            measure={values.measure}
          />
        </Grid>
        <Grid item md={12}>
          {values?.filename.length !== 0 && values.clickApply === true ? (
            <Section
              title={"Seasonality"}
              actions={
                <React.Fragment sx={{ m: 2 }}>
                  <ButtonGroup
                    setValue={setChecked1}
                    value={checked1}
                    options={[
                      { label: "Table", value: "table" },
                      { label: "Chart", value: "chart" },
                    ]}
                    primaryColor={"#31b2dc"}
                    width={84}
                  />
                  {checked1 === "table" && (
                    <Tooltip title="Download table">
                      <CloudDownloadIcon
                        sx={{ mr: 1 }}
                        onClick={() =>
                          exportToCSV(
                            gridApi4,
                            gridApi4,
                            gridApi6,
                            "Seasonality",
                            values.filters
                          )
                        }
                        style={{
                          fontSize: "34px",
                          marginLeft: "9px",
                          color: "#2036C7",
                        }}
                      />
                    </Tooltip>
                  )}
                </React.Fragment>
              }
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={season}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Winter"
                    control={<Radio />}
                    label="Winter"
                  />
                  <FormControlLabel
                    value="Summer"
                    control={<Radio decimals={values.decimal} />}
                    label="Summer"
                  />
                </RadioGroup>
              </FormControl>
              {checked1 === "chart" && (
                <FormControl style={{ marginLeft: "8%" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={chart1}
                    onChange={(event) => {
                      setChart1(event.target.value);
                    }}
                    decimals={values.decimal}
                  >
                    <FormControlLabel
                      value="qtr"
                      control={<Radio />}
                      label="QTR"
                    />
                    <FormControlLabel
                      value="fy"
                      control={<Radio />}
                      label="FY"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <Typography
                style={{
                  fontStyle: "20px",
                  fontSize: "bold",
                  marginTop: "10px",
                }}
              >
                {TypographyNameDisplay(values.measure)}
              </Typography>
              {/* <SeasonalityTable
                season={season}
                term={measure}
                choice={checked1}
                chart={chart1}
                enableAPIs={enableAPIs}
                decimals={decimals}
                gridOptions={gridOptions4}
                gridApi={gridApi4}
                setGridApi={setGridApi4}
              /> */}
              <SeasonalityDashboard
                values={values}
                enableAPIs={enableAPIs}
                decimals={values.decimal}
                measure={values.measure}
                season={season}
                term={values.measure}
                choice={checked1}
                chart={chart1}
              />
            </Section>
          ) : (
            <Section title={"Seasonality"}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" gutterBottom>
                  Please select <b>filename</b> for OutQuarter and click on
                  apply button
                </Typography>
              </div>
            </Section>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
