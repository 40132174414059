import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { useEffect } from "react";
import useNotify from "utils/useNotify";
export default function useGetFaReportFilter() {
  const path = "/sales_and_forecast_routes/fa_fb_report_filters";
  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  return {
    data,
    isLoading,
    mutate,
  };
}
