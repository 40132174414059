import { useQuery } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetMtSOPUpload(payload, decimals = 4, enable, term) {
  const path = "/mt_sop/get_upload_mtsoptable";
  const {
    data = [],
    isLoading,
    error,
    isError,
  } = useQuery(["getmtsop", payload], () => postApi(path, payload), {
    enabled: enable,
  });
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  const colDef = [];
  const xAxis = [];
  const series = [];
  const selected = {};
  let highestNumber = NaN;
  let highestNumber1 = NaN;
  let smallNumber = NaN;

  if (data && Array.isArray(data) && data.length) {
    const temp = [];
    Object.keys(data[0]).forEach((header) => {
      if (header !== "Business_Group" && header !== "VP_Category") {
        colDef.push({
          field: header,
          cellStyle: getStyles,
          headerTooltip: header,
          width: 120,
          valueFormatter: (params) => getFormatValue(params, decimals),
        });
      }
    });
    Object.keys(data[0]).forEach((item) => {
      if (item.startsWith("MQ'")) {
        temp.push(item);
      }
    });
    const sortedValue = temp.sort();
    const leastNumber = sortedValue[0];
    const firstNumber = sortedValue[sortedValue.length - 1];
    const leastNumber1 = leastNumber.split("'")[1];
    smallNumber = leastNumber1.split(term)[0];
    highestNumber = firstNumber.split("'")[1];
    highestNumber1 = highestNumber.split(term)[0];

    for (let i = parseInt(smallNumber); i <= parseInt(highestNumber1); i++) {
      ["MQ", "JQ", "SQ", "DQ"].forEach((a) => {
        xAxis.push(`${a}'${i} ${term}`);
      });
    }

    const legend = [];

    if (data && Array.isArray(data) && data.length) {
      data.forEach((item) => {
        const seriesData = [];
        if (item["Business_Group"].length === 1) {
          legend.push(item["Business_Group"][0]);
          selected[item["Business_Group"][0]] = false;
          xAxis.forEach((axisItem) => {
            seriesData.push(`${item[axisItem] ?? 0}`);
          });
          series.push({
            name: item["Business_Group"][0],
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: seriesData,
          });
          if (item["Business_Group"][0] === "PC") {
          }
        }
      });
    }
  }

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      // selected: {
      //   ...selected,
      //   Grand_Total: true,
      // },
      right: "6%",
      top: "5%,",
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: {
      type: "value",
    },
    series: series,
  };
  return {
    uploading: isLoading,
    data,
    colDef,

    optionsUpload: options,
  };
}
