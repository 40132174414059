import { createTheme } from "@mui/material";

const themeOptions = {
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#2036C7",
    },
    action:{
      selectedOption:"#ADD8E6"
    }
  },
};

export const getColorPalette = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: "#2036C7",
          },
          background: {
            paper: "#fcfafa",
          },
          accordion:{
            backgroundColor: {
              default:'#fff',
              selected:'lightblue'
            },
            color: '#2036C7'
          },
          border: {
            primary: '#e7ebfa',
            // secondary: grey[500],
          },
          button:{
            border:"#2036C7",
            text:"#2036C7"
          },
        }
      : {
          // palette values for dark mode
          // primary: "#121212",
          primary: {
            main: "#4b4b4b",
          },
          button:{
            border:"#fff",
            text:"#fff"
          },
          background: {
            paper: "#383838",
            default: "#404040",
          },
          text: {
            primary: '#fff',
            // secondary: grey[500],
          },
          border: {
            primary: '#4c4c4c',
            // secondary: grey[500],
          },
          accordion:{
            backgroundColor: {
              default:'#424242',
              selected: '#4c5a66'
            },
            color: '#fff'
          }
        }),
  },
});


export const theme = createTheme(themeOptions);
