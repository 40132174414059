import DataGridTable from "components/DataGridTable/DataGridTable";
import React from "react";

import useGetSeasonality from "services/outQuarter/useGetSeasonality";
import ReactECharts from "echarts-for-react";
import OutQuarterFy from "./SeasonalityFy";
import Loader from "components/Loader";

export default function SeasonalityTable(props) {
  const {
    isLoading,
    colDef,
    chart,
    choice,
    data,
    optionsQtr,
    term,
    season,
    decimals,
    setGridApi,
    ...rest
  } = props;

  return (
    <React.Fragment>
      {isLoading == true ? (
        <Loader />
      ) : (
        <React.Fragment>
          {choice === "table" ? (
            <DataGridTable
              {...rest}
              height="400px"
              onGridReady={(e) => setGridApi(e.api)}
              suppressRowTransform={true}
              columnDefs={colDef}
              rowData={data}
              borders={false}
            />
          ) : (
            <React.Fragment>
              {chart === "qtr" ? (
                <ReactECharts
                  style={{ height: "300px", width: "100%" }}
                  option={optionsQtr}
                />
              ) : (
                <OutQuarterFy
                  responsedata={data}
                  term={term}
                  season={season}
                  decimals={decimals}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
