import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetMtSopPivotData(decimals = 4, term, heirarchy) {
  const path = "/mt_sop/get_mt_sop_pivot";
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) => postApi(path, payload));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const colDef = [];
  const xAxis = [];
  const series = [];

  let highestNumber = NaN;
  let highestNumber1 = NaN;
  let smallNumber = NaN;
  const selected = {};

  if (Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((header) => {
      if (header !== "index" && !heirarchy.includes(header)) {
        colDef.push({
          field: header,
          minWidth: 150,
          cellStyle: getStyles,
          headerTooltip: header,
          valueFormatter: (params) => getFormatValue(params, decimals),
        });
      }
    });
  }

  if (Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((item) => {
      const temp = [];
      if (item.startsWith("MQ'")) {
        temp.push(item);
        const sortedValue = temp.sort();
        const leastNumber = sortedValue[0];
        const firstNumber = sortedValue[sortedValue.length - 1];
        const leastNumber1 = leastNumber.split("'")[1];
        smallNumber = leastNumber1.split(term)[0];

        highestNumber = firstNumber.split("'")[1];
        highestNumber1 = highestNumber.split(term)[0];
      }
    });
    for (let i = parseInt(smallNumber); i <= parseInt(highestNumber1); i++) {
      ["MQ", "JQ", "SQ", "DQ"].forEach((a) => {
        xAxis.push(`${a}'${i} ${term}`);
      });
    }
    const legend = [];
    if (data && Array.isArray(data) && data.length) {
      data.forEach((item) => {
        const seriesData = [];
        if (item["channel"].length === 1) {
          legend.push(item["channel"][0]);
          selected[item["channel"][0]] = false;
          xAxis.forEach((axisItem) => {
            seriesData.push(`${item[axisItem] ?? 0}`);
          });
          series.push({
            name: item["channel"][0],
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: seriesData,
          });
        }
      });
    }
  }
  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      // selected: {
      //   ...selected,
      //   Grand_Total: true,
      // },
      right: "6%",
      top: "5%,",
      bottom: "3%",
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: {
      type: "value",
    },
    series: series,
  };

  return {
    isLoading,
    rowDataValue: data,
    colDefValue: colDef,
    options,
    mutate,
  };
}
