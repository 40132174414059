import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import Section from "components/Section";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import ButtonGroup from "components/ButtonGroup";
import { getDataForCSV } from "utils/constants";
import { useFormikContext } from "formik";

const MtSopTableChart = ({
  children,
  title,
  gridApi,
  value,
  setValue,
  term,
  setTerm,
}) => {
  const { values } = useFormikContext();
  const exportToCSV = (gridApi, name, checked) => {
    const spreadsheets = [
      gridApi.getSheetDataForExcel({
        prependContent: getDataForCSV(values.filters),
        sheetName: checked === "Val" ? "Value" : "Volume",
      }),
    ];

    gridApi.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `${name}.xlsx`,
    });
  };
  return (
    <Grid item md={12}>
      <Section
        title={title}
        actions={
          <React.Fragment sx={{ m: 2 }}>
            <ButtonGroup
              setValue={setTerm}
              value={term}
              options={[
                { label: "Value", value: "Val" },
                { label: "Volume", value: "Vol" },
              ]}
              primaryColor={"#31b2dc"}
              width={84}
            />
            {value === "table" && (
              <Tooltip title="Download table">
                <CloudDownloadIcon
                  sx={{ mr: 1 }}
                  onClick={() => exportToCSV(gridApi, title, value)}
                  style={{
                    fontSize: "34px",
                    marginLeft: "9px",
                    color: "#2036C7",
                  }}
                />
              </Tooltip>
            )}
          </React.Fragment>
        }
      >
        <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
          {term === "Vol" ? "Volume" : "Value"}
        </Typography>
        <ButtonGroup
          setValue={setValue}
          value={value}
          options={[
            { label: "Table", value: "table" },
            { label: "Chart", value: "chart" },
          ]}
          primaryColor={"#31b2dc"}
          width={84}
        />
        {children}
      </Section>
    </Grid>
  );
};
export default MtSopTableChart;
