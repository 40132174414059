import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SelectField from "components/form-fields/SelectField";
import { useFormikContext, Field } from "formik";
import { StyledCard } from "components/Section/Section.styles";
import Accurancy from "./component/Accurancy";
import Weighed from "./component/Weighed";
import Linewise from "./component/Linewise";
import DeleteSummaryFiles from "../Summary/components/DeleteSummaryFiles";
import SummaryUpload from "../Summary/components/SummaryUpload";
import useGetFileUploadedList from "services/DemandForecast/useGetFileUploadedList";

const SalesReport = ({ displayScreen, enableAPIs, decimal }) => {
  switch (displayScreen) {
    case "Accuracy":
      return <Accurancy decimals={decimal} enableAPIs={enableAPIs} />;
    case "Weighed":
      return <Weighed decimals={decimal} enableAPIs={enableAPIs} />;
    default:
      return <Linewise decimals={decimal} enableAPIs={enableAPIs} />;
  }
};

export default function AdoptionDetails({ enableAPIs, tag }) {
  // const [decimal, setDecimal] = useState(0);
  const [displayScreen, setDisplayScreen] = useState("Linewise");
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  
  const { demandForecastUploadedFile, refetch } = useGetFileUploadedList({
    username: localStorage.getItem("username"),
    tag: tag,
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} md={12}>
            <Grid item xs={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={displayScreen}
                  onChange={(event) => setDisplayScreen(event.target.value)}
                >
                  {/* <FormControlLabel
                    value="Accuracy"
                    control={<Radio />}
                    label="Accuracy"
                  /> */}
                  <FormControlLabel
                    value="Linewise"
                    control={<Radio />}
                    label="Linewise"
                  />
                  <FormControlLabel
                    value="Weighed"
                    control={<Radio />}
                    label="Weighed"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item container spacing={2} xs={8} justifyContent="flex-end">
            <Grid item md={2} xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  // onChange={(event) => setDecimal(event.target.value)}
                  onChange={(event) => setFieldValue("decimal",event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid> 
            <Grid item md={3}>
            <Field
              name="demandForecastUploadedFile"
              label={"Uploaded File Name"}
              component={SelectField}
              options={[...demandForecastUploadedFile]}
              fullWidth
              disabled={demandForecastUploadedFile.length===0}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Select Uploaded file">
            <FormControlLabel 
              control={<Checkbox />}
              checked={values.uploadCheck}
              onChange={(e)=>{setFieldValue("uploadCheck",e.target.checked)}} 
              disabled={demandForecastUploadedFile.length===0} 
            />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Delete File"}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDelete(true)}
                disabled={demandForecastUploadedFile.length===0}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Upload File"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                <CloudUploadIcon />
              </Button>
            </Tooltip>
          </Grid>
          </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <DeleteSummaryFiles
        tag={tag}
        open={openDelete}
        setOpen={setOpenDelete}
        demandForecastUploadedFile={demandForecastUploadedFile}
        refetch={refetch}
      />
      <SummaryUpload
        tag={tag}
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetch}
      />
      <Grid item md={12}>
        <React.Fragment>
          <SalesReport
            displayScreen={displayScreen}
            enableAPIs={enableAPIs}
            decimal={values.decimal}
          />
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
