import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'

export default function CustomPlaceholder(props) {
  const theme = useTheme()
  return (
    <Box   style={{ backgroundColor: theme.palette.background.paper, height:"400px",width:"100%",display:'flex',justifyContent:'center', alignItems:'center' }}>
    <h4 style={{textAlign:"center", color:theme.palette.text.primary}}>Please click on apply to view the data with selected filters</h4>
    </Box>
  )
}
