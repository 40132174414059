import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFileName() {
  const path = "mt_sop/getcatname";
  const { data, isLoading, error, isError } = useQuery(
    ["getfileName", path],
    () => getApi(path),
    {
      enabled: true,
    }
  );

  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  return {
    fileName: data?.Mt_Snop_Category ?? [],
    isLoading,
    data,
  };
}
