import { useEffect } from "react";
import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";

export default function useGetForecastFilters() {
  const path = `forecastAdjustment/displayFilters`;
  const {
    data,
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) => postApi(path, payload));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  return {
    isFiltersLoading: isLoading,
    data,
    mutate,
  };
}
