const { BootstrapButton } = require("./DisplayButton.styles");

const DisplayButton = ({ name, buttonOnclick, active = false }) => {
  return (
    <BootstrapButton
      variant="outlined"
      active={active}
      sx={{ mr: 2 }}
      onClick={buttonOnclick}
    >
      {name}
    </BootstrapButton>
  );
};
export default DisplayButton;
