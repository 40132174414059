import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetSnopTableNames() {
  const path = "demand_forecast/get_SNOP_tables";
  const { data, error, isError } = useQuery(["get_SNOP_tables"], () =>
    getApi(path)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  return {
    snopTables: data,
  };
}
