/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SummaryTableWrapper from "../SummaryTableWrapper";
import ReactECharts from "echarts-for-react";

import { useDeepCompareEffect } from "utils/useDeepCompareEffect";

export default function TableChartContent(props) {
  const {
    options,
    optionsfy,
    gridApi,
    setGridApi,
    colDef,
    rowData,
    isLoading,
    selected,
    height,
    quarter,
    ...rest
  } = props;

  const [chartInstance, setchartInstance] = React.useState(null);
 
  const [chartOptions, setChartOptions] = useState();
  const [chartOptionsFy, setChartOptionsFy] = useState();

  useEffect(() => {
    setChartOptionsFy(optionsfy);
    setChartOptions(options);
  }, [options, optionsfy]);

  useDeepCompareEffect(() => {
    if (chartInstance && quarter === "qtr") {
      chartInstance.setOption(chartOptions, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    } else if (chartInstance && quarter === "fy") {
      chartInstance.setOption(chartOptionsFy, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    }
  }, [chartOptions, chartOptionsFy, chartInstance, quarter]);

  

  return (
    <React.Fragment>
      {selected === "table" ? (
        <SummaryTableWrapper
          {...rest}
          setGridApi={setGridApi}
          gridApi={gridApi}
          height={height}
          columnDefs={colDef}
          rowData={rowData}
          isLoading={isLoading}
        />
      ) : (
        <React.Fragment>
          
          <div style={{ width: "100%" }}>
            <ReactECharts
              style={{ height: "500px", width: "100%" }}
              option={options}
              ref={(e) => {
                const instance = e?.getEchartsInstance();
                setchartInstance(instance);
              }}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
