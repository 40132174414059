import { getIn } from "formik";

export const getFieldId = (name) => {
  return `${name || "field"}-${new Date().getTime()}`;
};

export const getError = (field, form) => {
  if (!field.name) {
    return {};
  }

  const error =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return { error: error ?? "", hasError: Boolean(error) };
};

export const getFieldArrayError = (name, form) => {
  if (!name) {
    return {};
  }

  let error = getIn(form.touched, name) && getIn(form.errors, name);

  if (typeof error !== "string") {
    error = "";
  }

  return { error, hasError: Boolean(error) };
};
