import React, { useCallback } from "react";
import { Grid, Button } from "@mui/material";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import { useEffect, useState } from "react";
import { useFormikContext, Field } from "formik";
import useGetWeighted from "services/SalesForecastVersioning/Adoption/useGetWeighed";
import DataGridTable from "components/DataGridTable/DataGridTable";
import Loader from "components/Loader";

import { AdoptionDropdown } from "../formData";
import AutocompleteField from "components/form-fields/AutocompleteField";
import useGetNoTouch from "services/SalesForecastVersioning/Adoption/useGetNoTouch";

export default function Weighed({ decimals, enableAPIs }) {
  const { values } = useFormikContext();
  const { isLoadingWeighted, colDefWeighted, mutate, rowDataWeighted } =
    useGetWeighted(decimals, values.baseWeighted);

  const { isLoadingNoTouch, colDefNoTouch, noTouchMutate, rowDataNoTouch } =
    useGetNoTouch(decimals, values.baseWeighted);

  const onApply = useCallback((values) => {
    mutate({
      base: values.baseWeighted,
      filters: values.filters,
      filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
      input_type: values.uploadCheck ? "upload" : "default",
      username: localStorage.getItem("username"),
    });
    noTouchMutate({
      base: values.baseWeighted,
      filters: values.filters,
      filename: values.uploadCheck
          ? values.demandForecastUploadedFile
          : "Sample",
      input_type: values.uploadCheck ? "upload" : "default",
      username: localStorage.getItem("username"),
    });
  }, []);

  useEffect(() => {
    onApply(values);
  }, [enableAPIs, onApply]);

  const [gridApi, setGridApi] = useState();

  const exportCsv=()=>{
    if(gridApi){
      gridApi.exportDataAsCsv();
    }
  }

  return (
    <Grid item md={12}>
      <Section 
        title={"Weighted"} 
        onSettings={
            <ExportOption
              onClick={()=>exportCsv()}
            />
        }
      >
        <Field
          label="Base row"
          name="baseLinewise"
          options={AdoptionDropdown}
          isOptionEqualToValue={(option, value) => option.value === value}
          component={AutocompleteField}
        />

        <Button
          size="small"
          variant="contained"
          onClick={() => onApply(values)}
          sx={{ mt: 2 }}
        >
          Apply
        </Button>
        {isLoadingWeighted ? (
          <Loader />
        ) : (
          <>
            <DataGridTable
              height="600px"
              suppressRowTransform={true}
              columnDefs={colDefWeighted}
              rowData={rowDataWeighted}
              borders={false}
              onGridReady={(e) => setGridApi(e.api)}
            />
          </>
        )}
        {isLoadingNoTouch ? (
          <Loader />
        ) : (
          <>
            <DataGridTable
              height="600px"
              suppressRowTransform={true}
              columnDefs={colDefNoTouch}
              rowData={rowDataNoTouch}
              borders={false}
            />
          </>
        )}
      </Section>
    </Grid>
  );
}
