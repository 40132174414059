import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetDemandForecast(payload) {
  const xAxis = [];
  const yAxis = {};
  const path = "demand_forecast/query_chart_data";

  const { data, mutate, isLoading, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );

  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  if (data && Array.isArray(data) && data.length) {
    Object.keys(data[0]).forEach((item) => {
      yAxis[item] = [];
    });
  }

  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      xAxis.push(item[payload?.key]);
      Object.keys(item).forEach((key) => {
        yAxis[key].push(item[key]);
      });
    });
  }
  const invalidateData = () => {
    mutate(null);
  };
  return {
    demandForecastLoading: isLoading,
    data,
    xAxis,
    yAxis,
    mutate,
    invalidateData,
  };
}
