/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { Typography } from "@mui/material";
import useGetSummaryData from "services/DemandForecast/useGetSummaryData";
import useGetPivotData from "services/DemandForecast/useGetPivotData";
import useGetSummaryUploadedFile from "services/DemandForecast/useGetSummaryUploadedFile";
import { getHierarchy } from "../../formData";

import TableChartContent from "./TableChartContent";
import Loader from "components/Loader/Loader";

export default function SummaryTable(props) {
  const {
    term,
    height,
    gridApi,
    setGridApi,
    decimals,
    enableAPIs,
    selected,
    gridOptions,
    quarter,
    applyEnable,
  } = props;

  const { values } = useFormikContext();

  const { isLoading, rowData, colDef, mutate, options, optionsfy, lastRow } =
    useGetPivotData(
      decimals,
      values.hierarchy1,
      values.hierarchy2,
      values.hierarchy3,
      values.hierarchy4
    );
  const gridOptions2 = {
    ...gridOptions,
    pinnedBottomRowData: [lastRow],
  };
  useEffect(() => {
    if (values.filename) {
      mutate({
        term: term,
        category: values.filename,
        filters: values.filters,
        nm: values["nm"],
        ratio: values["ratio"],
        heirarchy: getHierarchy(values),
      });
    }
  }, [enableAPIs, mutate, applyEnable]);

  const enable = values.demandForecastUploadedFile !== "Default";
  const {
    rowDataUploaded,
    colDefUploaded,
    summaryUploadeData,
    optionUploaded,
    optionsfyUploaded,
  } = useGetSummaryUploadedFile(
    {
      Term: [term],
      ...values.filters,
      nm: values["nm"],
      ratio: values["ratio"],
      username: localStorage.getItem("name"),
      filename: values.demandForecastUploadedFile,
    },
    decimals,
    enable
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Row Label",
      minWidth: 100,
      maxWidth: 800,
      pinned: "left",
      headerTooltip: "Row Label",
      cellRendererParams: {
        suppressCount: true,
      },
      cellStyle: () => {
        return {
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        };
      },
    };
  }, []);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.channel;
    };
  }, []);

  return (
    <>
      {values.demandForecastUploadedFile === "Default" ? (
        values.filename && values.clickApply === true ? (
          <React.Fragment>
            {isLoading === true && rowData?.length === 0 ? (
              <Loader />
            ) : (
              <TableChartContent
                gridOptions={gridOptions2}
                setGridApi={setGridApi}
                gridApi={gridApi}
                height={height}
                colDef={colDef}
                autoGroupColumnDef={autoGroupColumnDef}
                rowData={rowData}
                treeData={true}
                animateRows={true}
                getDataPath={getDataPath}
                isLoading={isLoading}
                selected={selected}
                options={options}
                optionsfy={optionsfy}
                quarter={quarter}
              />
            )}
          </React.Fragment>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body2" gutterBottom>
              Please select category from filename and click on apply
            </Typography>
          </div>
        )
      ) : (
        <React.Fragment>
          <React.Fragment>
            <TableChartContent
              gridOptions={gridOptions2}
              setGridApi={setGridApi}
              gridApi={gridApi}
              height={height}
              colDef={colDefUploaded}
              autoGroupColumnDef={autoGroupColumnDef}
              isLoading={summaryUploadeData}
              rowData={rowDataUploaded}
              selected={selected}
              options={optionUploaded}
              optionsfy={optionsfyUploaded}
            />
          </React.Fragment>
        </React.Fragment>
      )}
    </>
  );
}
