import { Grid, Button, Tooltip } from "@mui/material";

import React from "react";

import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SummaryUpload from "../SummaryUpload";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Field } from "formik";
import SelectField from "components/form-fields/SelectField";
import useGetFileUploadedList from "services/DemandForecast/useGetFileUploadedList";
import { StyledCard } from "components/Section/Section.styles";
import DeleteSummaryFiles from "../DeleteSummaryFiles";

const SummaryPanel = ({ setDecimals, decimals, tag }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { demandForecastUploadedFile, refetch } = useGetFileUploadedList({
    username: localStorage.getItem("name"),
    tag: tag,
  });

  const handleChange = (event) => {
    setDecimals(event.target.value);
  };

  return (
    <>
      <StyledCard>
        <Grid item container spacing={2} justifyContent="flex-end" md={12}>
          <Grid item md={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
              <Select value={decimals} label="Decimal" onChange={handleChange}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <Field
              name="demandForecastUploadedFile"
              label={"Uploaded File Name"}
              component={SelectField}
              options={[...demandForecastUploadedFile]}
              fullWidth
              disabled={demandForecastUploadedFile.length === 0}
            />
          </Grid>
          <Grid item>
            <Tooltip title={"Delete File"}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDelete(true)}
                disabled={demandForecastUploadedFile.length === 0}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Upload File"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                <CloudUploadIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledCard>
      <DeleteSummaryFiles
        tag={tag}
        open={openDelete}
        setOpen={setOpenDelete}
        demandForecastUploadedFile={demandForecastUploadedFile}
        refetch={refetch}
      />
      <SummaryUpload
        tag={tag}
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetch}
      />
    </>
  );
};

export default SummaryPanel;
