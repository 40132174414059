/* eslint-disable react-hooks/exhaustive-deps */
import {
  ViewportContainer,
  LeftSideNavbarWrapper,
  RightSideContainer,
} from "components/AppLayout/AppLayout.styles";
import React, { useCallback, useEffect } from "react";
import DemandForecast from ".";
import { Formik, useFormikContext } from "formik";
import { useState } from "react";
import {
  gt_filter_initial,
  initialValues,
  mt_filter_initial,
  ecom,
  gt_filter_initial_segemntation
} from "./formData";

import DemandForecastFilter from "./DemandForecastFilter";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Dialog from "components/Dialog";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";

const Content = () => {

  const [enableAPIs, setEnableAPIs] = useState(false);
  const { values, setFieldValue, setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [saveName, setSaveName] = useState("");
  const [skipFilterClear, setSkipFilterClear] = useState(false);
  const queryClient = useQueryClient();
  const [showLoader, setShowLoader] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onApply = () => {
    setEnableAPIs(!enableAPIs);
    values.clickApply = true;
    if(values.filters['BP'].length > 0 || values.filters['BP_Description'].length > 0){
    values.applyNonAggClicked=true
    }
  };
  const onFilterReset = useCallback(
    (filename) => {
     
      if(filename === "GT_Personal_Wash"){
        setFieldValue("timePeriod", "QTR_BM");
        setFieldValue("filters", gt_filter_initial_segemntation)
      
      }
      else if (
        filename &&
        (filename.startsWith("GT") || filename.startsWith("OH"))
      ) {
        setFieldValue("timePeriod", "QTR_BM");
        setFieldValue("filters", gt_filter_initial);
      } else if (filename && filename.startsWith("MT")) {
        setFieldValue("timePeriod", "MOC");
        setFieldValue("filters", mt_filter_initial);
      }
      // } else if (filename && filename.startsWith("Ecom_Sm")) {
      //   setFieldValue("timePeriod", "QTR_BM");
      //   setFieldValue("filters", ecom_sm);
      // } 
      else if (filename && filename.startsWith("Ecom")) {
        
        setFieldValue("timePeriod", "BM");
        setFieldValue("filters", ecom);
      } 
      // else if (filename && filename.startsWith("ecom")) {
      //   setFieldValue("timePeriod", "QTR_BM");
      //   setFieldValue("filters", ecom);
      // }
    },
    [setFieldValue]
  );
 
  const onReset = () => {
    onFilterReset();
    setValues(initialValues);
   
    
   
  };

  useEffect(() => {
    if (!skipFilterClear) {
      onFilterReset(values.fileName);
    }
  }, [onFilterReset, values.fileName]);

  useEffect(() => {
    if (skipFilterClear) {
      setTimeout(() => {
        setSkipFilterClear(false);
      }, 1000);
    }
  }, [skipFilterClear, values.fileName]);

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  const onSelectFilterName = useCallback(async (name) => {
    const path = "/savedjson";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: name,
      pageName: "demand forecast",
    };
    try {
      const data = await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setSkipFilterClear(true);
      setValues(data);
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
  }, []);

  const onSaveFilter = async (saveName) => {
    const path = "/saveUserDetails";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: saveName,
      savedJson: JSON.stringify({ ...values, savedName: saveName }),
      pageName: "demand forecast",
    };

    try {
      await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
    setOpen(false);
    setSaveName("");
  };

  useEffect(() => {
    if (values.savedName) {
      onSelectFilterName(values.savedName);
    }
  }, [onSelectFilterName, values.savedName]);

  // useEffect(()=>{
  //   setDialogOpen(true)
  // },[])

  const onSave = () => {
    setOpen(true);
  };
  return (
    <ViewportContainer>
      {values.openDrawer === true ? (
        <LeftSideNavbarWrapper>
          <DemandForecastFilter
            onApply={onApply}
            onReset={onReset}
            onSave={onSave}
            enableAPIs={enableAPIs}
          />
        </LeftSideNavbarWrapper>
      ) : (
        <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor: "pointer",
          }}
        />
      )}
      <RightSideContainer>
        <Box sx={{ margin: 3 }}>
          <DemandForecast onApply={onApply} enableAPIs={enableAPIs} />
        </Box>
      </RightSideContainer>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        header={
          <Typography variant="title" color={"primary"}>
            Save
          </Typography>
        }
        maxWidth="md"
        footer={
          <Box sx={{ padding: 2 }}>
            <Button
              variant="contained"
              disabled={saveName.length === 0}
              onClick={() => onSaveFilter(saveName)}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Grid sx={{ width: 700 }} container spacing={2}>
          <Grid item md={12}>
            <Typography variant="body2">Enter Name to Save</Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              placeholder="Please enter name to save (100 characters maximum)"
              varaint="outlined"
              onChange={(e) => setSaveName(e.target.value)}
            />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        header={
          <Typography variant="title" color={"primary"}>
            Do you want to run scenario simulation?
          </Typography>
        }
        maxWidth="md"
        footer={
          <Box sx={{ padding: 2 }}>
            <Button
              variant="contained"
              onClick={() => setDialogOpen(false)}
            >
              Agree
            </Button>
          </Box>
        }
      >
        <Grid sx={{ width: 700 }} container spacing={2}>
            <Typography>This starts the cluster in the background which helps in creating the scenarios faster.</Typography>
        </Grid>
      </Dialog>
    </ViewportContainer>
  );
};
export default function DemandForecastDashboard({display}) {
  const initialFormValues = window.sessionStorage.getItem("initialValues")
    ? JSON.parse(window.sessionStorage.getItem("initialValues"))
    : initialValues;
  return (
    <Formik initialValues={initialFormValues}>
      <Content display={display}/>
    </Formik>
  );
}
