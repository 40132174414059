import { alpha } from "@mui/material";
import styled from "@emotion/styled";

export const Root = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};
  border-radius: ${({ theme }) => `${theme.spacing(1)}`};
  padding: ${({ theme }) => `${theme.spacing(2.25, 1.25)}`};
  width: 100%;
  height: 100%;
  background-color: ${({ theme, uploadedFile }) =>
    uploadedFile ? `${alpha(theme.palette.primary.main, 0.05)}` : ``};
  ::after {
    border-radius: ${({ theme }) => `${theme.spacing(0.75)}`};
    border: ${({ theme, draggingFile }) =>
      draggingFile
        ? `5px solid ${alpha(theme.palette.primary.main, 0.5)}`
        : `1px dashed ${theme.palette.grey[400]}`};
  }
`;
