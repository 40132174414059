import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetBPandCluster() {
  const path = "/scenario_simulation/getBasepackAndCluster";
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const bpDetails = [];
  const clusterDetails = [];
  const customerDetails = [];
  if (
    data &&
    Array.isArray(data?.BP_Description) &&
    data?.BP_Description.length
  ) {
    data?.BP_Description.forEach((item) => {
      bpDetails.push(item);
    });
    data?.Cluster_Description.forEach((item) => {
      clusterDetails.push(item);
    });
    data?.Anaplan_Customer?.forEach((item) => {
      customerDetails.push(item);
    });
  }

  return {
    IsLoadingCreate: isLoading,
    mutateGetBPandCluster: mutate,
    bpDetails,
    clusterDetails,
    customerDetails,
    isError,
    isSuccess
  };
}
