import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import useGetForecastData from "services/ForecastAdjustments/useGetForecastData";
import {
  ViewportContainer,
  LeftSideNavbarWrapper,
  RightSideContainer,
} from "components/AppLayout/AppLayout.styles";
import ForecastAdjustmentFilters from "./components/ForecastAdjustmentFilters/ForecastAdjustmentFilters";
import ForecastAdjustmentTable from "./components/ForecastAdjustmentTable/ForecastAdjustmentTable";
import { Box } from "@mui/system";
import { initialValues } from "./formData";
import { useEffect } from "react";

const ForecastAdjustmentContent = () => {
  const [enableApis, setEnableApis] = useState(false);
  const { mutate, data, isTableLoading } = useGetForecastData();
  const { values, setValues, setFieldValue } = useFormikContext();
  const [editedRows, setEditedRows] = useState(() => new Set());
  const [gridApi, setGridApi] = useState();
  const addItem = (item) => {
    setEditedRows((prev) => new Set(prev).add(item));
  };

  const removeItem = (item) => {
    setEditedRows((prev) => {
      const next = new Set(prev);
      next.delete(item);
      return next;
    });
  };
  const onApply = () => {
    setEnableApis(!enableApis);
  };

  useEffect(() => {
    setFieldValue("filters", initialValues.filters);
  }, [setFieldValue, values.filename]);

  useEffect(() => {
    if (values.filename !== "") {
      mutate({
        categoryName: values.filename,
        forecastLevel: values.mode,
        filters: { "Level of Forecast": [values.mode], ...values.filters },
        username: localStorage.getItem("username"),
      });
    }
  }, [values.mode, enableApis]);

  const onReset = () => {
    setValues(initialValues);
    setEditedRows(() => new Set());
    setFieldValue("filterData", []);
    editedRows.forEach((id) => {
      gridApi.getRowNode(id).data["Planner_Update_Forecast_RPM"] = "";
      removeItem(id);
    });
  };

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  return (
    <ViewportContainer>
      {values.openDrawer === true ? (
        <LeftSideNavbarWrapper>
          <ForecastAdjustmentFilters
            onApply={onApply}
            onReset={onReset}
            enableApis={enableApis}
          />
        </LeftSideNavbarWrapper>
      ) : (
        <KeyboardDoubleArrowRightIcon
          onClick={handleDrawerOpen}
          style={{
            fontSize: "xx-large",
            paddingLeft: "4px",
            marginTop: "4px",
            color: "blue",
            cursor: "pointer",
          }}
        />
      )}

      <RightSideContainer>
        <Box sx={{ margin: 3 }}>
          <ForecastAdjustmentTable
            data={data}
            isTableLoading={isTableLoading}
            editedRows={editedRows}
            addItem={addItem}
            removeItem={removeItem}
            gridApi={gridApi}
            setGridApi={setGridApi}
          />
        </Box>
      </RightSideContainer>
    </ViewportContainer>
  );
};

const ForecastAdjustment = () => {
  return (
    <Formik initialValues={initialValues}>
      <ForecastAdjustmentContent />
    </Formik>
  );
};

export default ForecastAdjustment;
