import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function ExportOption(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.checked == "table"  && (
        <React.Fragment>
          <IconButton
            sx={{ marginLeft: 2 }}
            onClick={handleClick}
            aria-label="settings"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={props.onClick}>
              {props.checked === "chart" ? "Save Image" : "Export to CSV"}
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </>
  );
}
