import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { useEffect } from "react";
import useNotify from "utils/useNotify";
export default function useGetSavedName() {
  const path = "/savedname";
  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );

  const { showError } = useNotify();
  const savedName = [];
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      savedName.push({ label: element, value: element });
    });
  }
  return {
    names: savedName,
    isLoadingSave: isLoading,
    mutateSave: mutate,
  };
}
