import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
// import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { getFormatValue, getStyles } from "utils/constants";
import PublicIcon from "@mui/icons-material/Public";
// import AlarmIcon from "@mui/icons-material/Alarm";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
export const initialValues = {
  volumeCagr: [],
  filters: {
    Basepack: [],
    Branch: [],
    Description: [],
    "Modified Brand": [],
    "Brand Variant": [],
    UOM: [],
    Channel: [],
    Sales_Category: [],
    "Sub Category": [],
    "Format 1": [],
    "Format 2": [],
    COTC_Cell: [],
    Cluster: [],
    Pack_Format: [],
    Status: [],
    Supply_Format: [],
  },
  openDrawer: true,
  filename: "",
  clickApply: false,
  cagrVolume: [],
  cagrSeasonality: [],
  measure:"Vol",
  decimal:0,
  demandForecastUploadedFile:[],
};
export const filtersData = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "Basepack",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Format",
        name: "Pack_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Segment",
      //   name: "Segment",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Brand",
      //   name: "Brand",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Holiday",
      //   name: "Holiday",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  // {
  //   title: "Time",
  //   icon: AlarmIcon,
  //   filters: [
  //     {
  //       label: "Year",
  //       name: "Year",
  //       component: MultiSelectDropdown,
  //     },
  //     {
  //       label: "BM Year",
  //       name: "Year_BM",
  //       component: MultiSelectDropdown,
  //     },
  //   ],
  // },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Category",
      //   name: "Category",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Sub Category",
        name: "Sub Category",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Sales Business",
      //   name: "Sales_Business",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Sales Business Group",
      //   name: "Sales_Business_Group",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format 1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format 2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Supply Format",
        name: "Supply_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "Status",
        name: "Status",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const colDef = (decimals) => [
  {
    headerName: "Year",
    field: "index",
    pinned: true,
  },
  {
    headerName: "Sales/Plan",
    children: [
      {
        field: "MQ",
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        field: "JQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        headerName: "SQ",
        field: "SQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        field: "DQ",
        headerName: "DQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        field: "FH",
        headerName: "FH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        field: "SH",
        headerName: "SH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
      {
        field: "FY",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => getFormatValue(params, decimals),
      },
    ],
  },
  {
    headerName: "LKL Growth",
    children: [
      {
        headerName: "MQ",
        field: "MQLKL",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "JQ",
        field: "JQLKL",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQLKL",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "DQ",
        field: "DQLKL",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FHLKL",
        headerName: "FH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SHLKL",
        headerName: "SH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FYLKL",
        headerName: "FY",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
];

export const colDefCagr2 = (decimals) => [
  {
    headerName: "Year",
    field: "index",
    pinned: true,
  },
  {
    headerName: "L2Y CAGR",
    children: [
      {
        field: "MQ_CAGR_2",
        headerName: "MQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQ_CAGR_2",
        headerName: "JQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQ_CAGR_2",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQ_CAGR_2",
        headerName: "DQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FH_CAGR2",
        headerName: "FH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SH_CAGR2",
        headerName: "SH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "2Year_CAGR",
        headerName: "FY",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
  {
    headerName: "Contribution",
    children: [
      {
        field: "MQCON",
        headerName: "MQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQCON",
        headerName: "JQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQCON",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQCON",
        headerName: "DQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FHCON",
        headerName: "FH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SHCON",
        headerName: "SH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
];
export const colDefCagr3 = (decimals) => [
  {
    headerName: "Year",
    field: "index",
    pinned: true,
  },
  {
    headerName: "L3Y CAGR",
    children: [
      {
        field: "MQ_CAGR_3",
        headerName: "MQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQ_CAGR_3",
        headerName: "JQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQ_CAGR_3",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQ_CAGR_3",
        headerName: "DQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FH_CAGR3",
        headerName: "FH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SH_CAGR3",
        headerName: "SH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "3Year_CAGR",
        headerName: "FY",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
  {
    headerName: "Contribution",
    children: [
      {
        field: "MQCON",
        headerName: "MQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQCON",
        headerName: "JQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQCON",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQCON",
        headerName: "DQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FHCON",
        headerName: "FH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SHCON",
        headerName: "SH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
];
export const colDefCagr4 = (decimals) => [
  {
    headerName: "Year",
    field: "index",
    pinned: true,
  },
  {
    headerName: "L4Y CAGR",

    children: [
      {
        field: "MQ_CAGR_4",
        headerName: "MQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQ_CAGR_4",
        headerName: "JQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQ_CAGR_4",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQ_CAGR_4",
        headerName: "DQ",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FH_CAGR4",
        headerName: "FH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SH_CAGR4",
        headerName: "SH",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "4Year_CAGR",
        headerName: "FY",
        width: 140,
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
  {
    headerName: "Contribution",
    children: [
      {
        field: "MQCON",
        headerName: "MQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "JQCON",
        headerName: "JQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        headerName: "SQ",
        field: "SQCON",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "DQCON",
        headerName: "DQ",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "FHCON",
        headerName: "FH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
      {
        field: "SHCON",
        headerName: "SH",
        cellStyle: getStyles,
        valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
      },
    ],
  },
];
