import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { alpha } from "@mui/system";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";

import useNotify from "utils/useNotify";
export default function useGetFeatures() {
  const path = "demand_forecast/feature_importance";
  const { data, isLoading, mutate, error, isError } = useMutation(
    (payload) => postApi(path, payload),
    {
      enabled: true,
    }
  );
  const featureImportance = [];
  const yAxis = [];
  const { showError } = useNotify();
  const theme = useTheme();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  if (data && Array.isArray(data) && data.length) {
    data.forEach((item, index) => {
      if (item["variable"] !== "Cannibalisation") {
        console.log("data", item);
        yAxis.push(item["variable"]);
        featureImportance.push({
          value: item.feature_importance.toFixed(2),
          itemStyle: {
            color:
              theme.palette.mode === "light"
                ? alpha("#2036C7", (data.length - index) / 10)
                : alpha("#3346D3", (data.length - index) / 2),
          },
        });
      }
    });
  }
  return {
    yAxis: yAxis.reverse(),
    featureImportance: featureImportance.reverse(),
    isLoading,
    mutate,
    data,
  };
}
