import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { useEffect } from "react";
import useNotify from "utils/useNotify";

export default function useGetCompareResultNonAgg(mode, bp, cluster, scale, demandDriver) {
  const path = "/scenario_simulation/getCompareResult_non_agg";
  const selectedKeys = [];
  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );
  var newData = [];

  data &&
    data.forEach(function (obj) {
      var newObj = {};

      Object.keys(obj).forEach(function (key) {
        var newKey = key.replace(/(Accenture|ACC)/g, "Falcon");
        newObj[newKey] = obj[key];
      });

      newData.push(newObj);
    });

  const xAxis = [];
  const legendData = [];
  const yAxis = {};
  const colDef = [
    {
      field: mode,
      width: 120,
      headerName: mode,
      pinned: "left",
    },
    {
      field: "Key",
      width: 160,
    },
  ];
  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  if (newData && Array.isArray(newData) && newData.length) {
    Object.keys(newData[0]).forEach((item) => {
      yAxis[item] = [];
      scale.forEach((scaleItem) => {
        if (item.includes(scaleItem.value)) {
          selectedKeys.push(item);
        }
      });
      demandDriver.forEach((demandItem) => {
        if (item.includes(demandItem.value)) {
          selectedKeys.push(item);
        }
      });
      if (item !== "Key" && item !== "MOC" && !item.includes("Final_Status")) {
        colDef.push({
          field: item,
          align: "center",
          headerTooltip: item,
        });
      }
    });
  }
  if (newData && Array.isArray(newData)) {
    newData.forEach((item) => {
      xAxis.push(item[mode]);
      Object.keys(item).forEach((key) => {
        if (selectedKeys.includes(key)) {
          legendData.push(key);
          yAxis[key].push(item[key]);
        }
      });
    });
  }

  return {
    scenarioResultNonAgg: newData,
    isLoadingNonAgg: isLoading,
    mutateScenarioCompareResultNonAgg: mutate,
    dataNonAgg: data,
    xAxisNonAgg: xAxis,
    yAxisNonAgg: yAxis,
    colDefNonAgg: colDef,
    legendDataNonAgg: legendData,
  };
}
