import {
  ViewportContainer,
  LeftSideNavbarWrapper,
  RightSideContainer,
} from "components/AppLayout/AppLayout.styles";

import React, { useState, useCallback, useEffect } from "react";
import Summary from "./Summary";
import { Box, Grid, Button, Typography, TextField } from "@mui/material";
import Dialog from "components/Dialog";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";
import SummaryFilter from "./components/SummaryFilters";
import { Formik, useFormikContext } from "formik";
import { initialValues } from "./formData";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SummaryPanel from "./components/SummaryPanel";
import Synopsis from "./Synopsis";
import { getDataForCSV } from "utils/constants";

const Content = () => {
  const [enableAPIs, setEnableAPIs] = useState(false);
  const [decimals, setDecimals] = useState(0);
  const [open, setOpen] = useState(false);
  const [saveName, setSaveName] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [skipFilterClear, setSkipFilterClear] = useState(false);
  const queryClient = useQueryClient();
  const { setValues, values, setFieldValue } = useFormikContext();
  const onApply = () => {
    setEnableAPIs(!enableAPIs);
    values.clickApply = true;
  };
  console.log("values",values)

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  const onReset = () => {
    // setValues(initialValues);
    // window.sessionStorage.setItem(
    //   "summaryInitialValues",
    //   JSON.stringify(initialValues)
    // );
    setFieldValue("summaryFilterData",[])
    setEnableAPIs(!enableAPIs);
  };

  const onSave = () => {
    // window.sessionStorage.setItem(
    //   "summaryInitialValues",
    //   JSON.stringify(values)
    // );
    setOpen(true);
  };

  // useEffect(() => {
  //   if (skipFilterClear) {
  //     setTimeout(() => {
  //       setSkipFilterClear(false);
  //     }, 1000);
  //   }
  // }, [skipFilterClear, values.fileName]);

  const onSelectFilterName = useCallback(async (name) => {
    const path = "/savedjson";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: name,
      pageName: "channel_brand",
    };
    try {
      const data = await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setSkipFilterClear(true);
      setValues(data);
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
  }, []);

  const onSaveFilter = async (saveName) => {
    const path = "/saveUserDetails";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: saveName,
      savedJson: JSON.stringify({ ...values, savedName: saveName }),
      pageName: "channel_brand",
    };

    try {
      await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
    setOpen(false);
    setSaveName("");
  };

  useEffect(() => {
    if (values.savedName) {
      onSelectFilterName(values.savedName);
    }
  }, [onSelectFilterName, values.savedName]);

  const exportToCSV = (gridApi1, gridApi2, gridApi3, name) => {
    const spreadsheets = [
      gridApi1.getSheetDataForExcel({
        prependContent: getDataForCSV(values.filters),
        sheetName: "volume",
      }),
      gridApi2.getSheetDataForExcel({
        prependContent: getDataForCSV(values.filters),
        sheetName: "value",
      }),
      gridApi3.getSheetDataForExcel({
        prependContent: getDataForCSV(values.filters),
        sheetName: "Eaches",
      }),
    ];

    gridApi1.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `${name}.xlsx`,
    });
  };

  return (
    <React.Fragment>
      <ViewportContainer>
        {values.openDrawer === true ? (
          <LeftSideNavbarWrapper>
            <SummaryFilter
              onApply={onApply}
              onReset={onReset}
              onSave={onSave}
              enableAPIs={enableAPIs}
              setEnableAPIs={setEnableAPIs}
            />
          </LeftSideNavbarWrapper>
        ) : (
          <KeyboardDoubleArrowRightIcon
            onClick={handleDrawerOpen}
            style={{
              fontSize: "xx-large",
              paddingLeft: "4px",
              marginTop: "4px",
              color: "blue",
              cursor:"pointer",
            }}
          />
        )}
        <RightSideContainer>
          <Box sx={{ margin: 3 }}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <SummaryPanel
                  setDecimals={setDecimals}
                  decimals={decimals}
                  tag={"summary"}
                />
              </Grid>
              <Summary
                enableAPIs={enableAPIs}
                decimals={decimals}
                exportToCSV={exportToCSV}
              />
            </Grid>
          </Box>
        </RightSideContainer>
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        header={
          <Typography variant="title" color={"primary"}>
            Save
          </Typography>
        }
        maxWidth="md"
        footer={
          <Box sx={{ padding: 2 }}>
            <Button
              variant="contained"
              disabled={saveName.length === 0}
              onClick={() => onSaveFilter(saveName)}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Grid sx={{ width: 700 }} container spacing={2}>
          <Grid item md={12}>
            <Typography variant="body2">Enter Name to Save</Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              placeholder="Please enter name to save (100 characters maximum)"
              varaint="outlined"
              onChange={(e) => setSaveName(e.target.value)}
            />
          </Grid>
        </Grid>
      </Dialog>
      </ViewportContainer>
    </React.Fragment>
  );
};

export default function SummaryDashboard() {
  const initialFormValues = window.sessionStorage.getItem(
    "summaryInitialValues"
  )
    ? JSON.parse(window.sessionStorage.getItem("summaryInitialValues"))
    : initialValues;
  return (
    <Formik initialValues={initialFormValues}>
      <Content />
    </Formik>
  );
}
