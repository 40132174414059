import {
  ViewportContainer,
  LeftSideNavbarWrapper,
  RightSideContainer,
} from "components/AppLayout/AppLayout.styles";
import React, { useState, useCallback, useEffect } from "react";
import MtSop from "./MtSop";
import { postApi } from "services/apiCalls";
import MtSopFilter from "./components/MtSopFilter";
import { initialValues } from "./formData";
import { Formik, useFormikContext } from "formik";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Grid, Button, Typography, TextField } from "@mui/material";
import { useQueryClient } from "react-query";
import Dialog from "components/Dialog";
const Content = () => {
  const [enableAPIs, setEnableAPIs] = useState(false);
  const [saveName, setSaveName] = useState("");
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState();
  const [skipFilterClear, setSkipFilterClear] = useState(false);
  const { setValues, values, setFieldValue } = useFormikContext();

  const onApply = () => {
    setEnableAPIs(!enableAPIs);
    values.clickApply = true;
  };

  const queryClient = useQueryClient();

  const onSelectFilterName = useCallback(async (name) => {
    const path = "/savedjson";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: name,
      pageName: "mtsop",
    };
    try {
      const data = await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setSkipFilterClear(true);
      setValues(data);
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
  }, []);

  const onSaveFilter = async (saveName) => {
    const path = "/saveUserDetails";
    const payload = {
      username: localStorage.getItem("username"),
      savedName: saveName,
      savedJson: JSON.stringify({ ...values, savedName: saveName }),
      pageName: "mtsop",
    };

    try {
      await queryClient
        .fetchQuery(["savedFilter", payload], () => postApi(path, payload))
        .finally(() => {
          setShowLoader(false);
        });
      setShowLoader(false);
    } catch (error) {
      console.log("err  ", error);
    }
    setOpen(false);
    setSaveName("");
  };

  const onReset = () => {
    setValues(initialValues);
  };

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  const onSave = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (values.savedName) {
      onSelectFilterName(values.savedName);
    }
  }, [onSelectFilterName, values.savedName]);

  return (
    <React.Fragment>
      <ViewportContainer>
        {values.openDrawer === true ? (
          <LeftSideNavbarWrapper>
            <MtSopFilter
              onApply={onApply}
              onReset={onReset}
              onSave={onSave}
              enableAPIs={enableAPIs}
              setEnableAPIs={setEnableAPIs}
            />
          </LeftSideNavbarWrapper>
        ) : (
          <KeyboardDoubleArrowRightIcon
            onClick={handleDrawerOpen}
            style={{
              fontSize: "xx-large",
              paddingLeft: "4px",
              marginTop: "4px",
              color: "blue",
              cursor: "pointer",
            }}
          />
        )}
        <RightSideContainer>
          <Box sx={{ margin: 3 }}>
            <MtSop enableAPIs={enableAPIs} />
          </Box>
        </RightSideContainer>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          header={
            <Typography variant="title" color={"primary"}>
              Save
            </Typography>
          }
          maxWidth="md"
          footer={
            <Box sx={{ padding: 2 }}>
              <Button
                variant="contained"
                disabled={saveName.length === 0}
                onClick={() => onSaveFilter(saveName)}
              >
                Save
              </Button>
            </Box>
          }
        >
          <Grid sx={{ width: 700 }} container spacing={2}>
            <Grid item md={12}>
              <Typography variant="body2">Enter Name to Save</Typography>
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                placeholder="Please enter name to save (100 characters maximum)"
                varaint="outlined"
                onChange={(e) => setSaveName(e.target.value)}
              />
            </Grid>
          </Grid>
        </Dialog>
      </ViewportContainer>
    </React.Fragment>
  );
};

export default function MtSopDashboard() {
  const initialFormValues = window.sessionStorage.getItem("MtsopInitialValues")
    ? JSON.parse(window.sessionStorage.getItem("MtsopInitialValues"))
    : initialValues;
  return (
    <Formik initialValues={initialFormValues}>
      <Content />
    </Formik>
  );
}
