import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetWeighted(decimal = 4, base) {
  const path = `/sales_and_forecast/adoption_weighted`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const colDefWeighted = [
    {
      field: `${base}`,
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: `${base}`,
    },
    {
      field: "2-5%",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "2-5%",
      valueGetter: (params) => params.data["2-5%"] ?? 0,
      valueFormatter: (params) => getFormatValue(params, decimal),
    },

    {
      field: "5-10%",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "5-10%",
      valueGetter: (params) => params.data["5-10%"] ?? 0,
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: ">10%",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: ">10%",
      valueGetter: (params) => params.data[">10%"] ?? 0,
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: "No Touch",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "No Touch",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      field: "Grand_Total",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "Grand_Total",
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
    {
      headerName: "Falcon Adoption",
      flex: 1,
      cellStyle: getStyles,
      headerTooltip: "Falcon Adoption",
      valueGetter: (params) =>
        (params.data["No Touch"] ?? 0) +
        (params.data["2-5%"] ?? 0) +
        (params.data["5-10%"] ?? 0),
      valueFormatter: (params) => getFormatValue(params, decimal),
    },
  ];

  return {
    isLoadingWeighted: isLoading,
    colDefWeighted,
    mutate,
    rowDataWeighted: data,
  };
}
