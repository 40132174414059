import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import Section from "components/Section";
import { MtSopTable } from "./MtSopTable";
import SummaryPanel from "views/Summary/components/SummaryPanel";
import { useFormikContext } from "formik";
import { hireachyDropDown } from "./formData";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";

import MtSopTableChart from "./components/MtSopTableChart";

export default function MtSop({ enableAPIs }) {
  const [gridApi1, setGridApi1] = useState();
  const [decimals, setDecimals] = useState(0);
  const [checked, setChecked] = useState("Val");
  const { values } = useFormikContext();
  const [displayOption, setDisplayOption] = useState("table");
  const [fetch, setFetch] = useState(false);
  const onApply = () => {
    setFetch(!fetch);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <SummaryPanel
          setDecimals={setDecimals}
          decimals={decimals}
          tag={"MT_SOP"}
        />
      </Grid>
      {values.filename && values.clickApply === true ? (
        <React.Fragment>
          <MtSopTableChart
            title={"Business Group"}
            setTerm={setChecked}
            term={checked}
            setValue={setDisplayOption}
            value={displayOption}
            gridApi={gridApi1}
          >
            <div style={{ marginTop: "16px" }}>
              <Grid item md={12}>
                <HierarchyFieldArray
                  values={values}
                  options={hireachyDropDown}
                  fieldName={"hierarchyBusinessGroup"}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={onApply}
                  sx={{ mt: 2 }}
                >
                  Apply
                </Button>
              </Grid>
            </div>
            <MtSopTable
              height="470px"
              term={checked}
              gridApi={gridApi1}
              setGridApi={setGridApi1}
              enableAPIs={enableAPIs}
              decimals={decimals}
              displayOption={displayOption}
              fetch={fetch}
            />
          </MtSopTableChart>
        </React.Fragment>
      ) : (
        <Grid item md={12}>
          <Section>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="body2" gutterBottom>
                Please select the filename
              </Typography>
            </div>
          </Section>
        </Grid>
      )}
    </Grid>
  );
}
