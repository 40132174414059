import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";
import { color } from "views/DemandForecast/mappings";
import { colDefDefSeasonality } from "views/OutQuarter/hooks/colDefDefSeasonality";

export default function useGetSeasonality(season, term, decimals = 4,filename) {
  const path = "/demand_forecast/getoutquater_season";
  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );
  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  let colDef = [];
  const xAxis = [];
  const series = [];
  if (Array.isArray(data) && data.length) {
    ["MQ", "JQ", "SQ", "DQ"].forEach((a, index) => {
      series.push({
        name: a,
        type: "bar",
        barGap: 1.3,
        emphasis: {
          focus: "series",
        },
        color: color[index + 4],
        data: data.map((i) => i[a]),
        label: {
          normal: {
            formatter: function (params) {
              var val = params.value.toFixed(decimals);
              return val;
            },
            show: true,
            position: "top",
            rotate: 90,
          },
        },
      });
    });
  }
  if (Array.isArray(data) && data.length) {
    colDef = colDefDefSeasonality(decimals, season,filename);

    data?.forEach((i) => {
      xAxis.push(i.index);
    });
  }
  const optionsQtr = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value) => value.toFixed(decimals),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          name: season + "_" + "seasonality" + "_" + term + "Qtr",
        },
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",

      containLabel: true,
    },
    // label: {
    //   rotate: 90,
    // },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: {
      type: "value",
    },
    series: series,
  };

  return {
    data,
    isLoading,
    mutate,
    colDef,
    optionsQtr,
  };
}
