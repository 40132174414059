export const years = [
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
];
export const Quarters = [
  { label: "MQ", value: "MQ" },
  { label: "JQ", value: "JQ" },
  { label: "SQ", value: "SQ" },
  { label: "DQ", value: "DQ" },
];
export const months = [
  { label: "BM 1", value: "BM 1" },
  { label: "BM 2", value: "BM 2" },
];
export const TDP = [
  { label: "TDP1", value: "TDP1" },
  { label: "TDP2", value: "TDP2" },
  { label: "TDP3", value: "TDP3" },
];
export const Versions = [
  { label: "Version1", value: "Version1" },
  { label: "Version2", value: "Version2" },
  { label: "Version3", value: "Version3" },
];
const defaultStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const checkValues = (filters, values) => {
  let flag = false;
  filters.forEach((item) => {
    if (
      values.filters[item.name] &&
      Array.isArray(values.filters[item.name]) &&
      values.filters[item.name].length
    ) {
      flag = true;
    }
  });
  return flag;
};

export const getStyles = (params, key = "channel") => {
  if ( params?.data && params.data[key] &&( 
params.data[key] === "Grand_Total" ||
    params.data[key] === "Total" ||
    params.data[key] === "Sub-Total"
  )) {
    return {
      background: "#2036c7",
      color: "#fff",
      ...defaultStyles,
    };
  }
  if (params.value < 0.0 || params.value < "0.00%") {
    return { color: "red", ...defaultStyles };
  } else if (params.value > 0 || params.value > "([0-9]*.?[0-9]*)s*%") {
    return { color: "#138808", ...defaultStyles };
  } else if (typeof params.value == "string") {
    return { color: "#000", ...defaultStyles };
  } else {
    return defaultStyles;
  }
};

export const getRowStyle = (params, key) => {
  if (params?.data && params.data[key] && (params.data[key] === "Grand_Total" || params.data[key] === "Total")) {
    return {
      fontWeight: "bold",
      fontStyle: "italic",
      background: "#2036c7",
      color: "#fff",
    };
  }
  if (params?.data && params.data[key] && params.data[key] === "Sub-Total") {
    return {
      fontWeight: "500",
      fontStyle: "italic",
      background: "#2036c7",
      color: "#fff",
    };
  }
};

export const getFormatValue = (params, decimals) => {
  if (isNaN(parseFloat(params.value))) {
    return params.value;
  }
  if (typeof params.value === "string" && params.value.endsWith("%")) {
    return `${parseFloat(params.value).toFixed(decimals)}%`;
  }
  return Number(params.value).toFixed(decimals);
};

export const getSelected = (values, data) => {
  const keys = Object.keys(values);
  const selected = {};

  keys.forEach((key) => {
    if (Array.isArray(values[key]) && values[key].length && data && data[key]) {
      selected[key] = data[key];
    }
  });
  return selected;
};

const getValues = (data) => {
  const cells = [];
  data.forEach((value) => {
    cells.push({
      data: { value: value, type: typeof value },
    });
  });
  return cells;
};

export const getDataForCSV = (values) => {
  const data = [
    {
      cells: [
        {
          data: { value: "Filters", type: "String" },
        },
      ],
    },
  ];
  const keys = Object.keys(values);

  keys.forEach((key) => {
    if (Array.isArray(values[key]) && values[key].length) {
      data.push({
        cells: [
          {
            data: { value: key, type: "String" },
          },
          ...getValues(values[key]),
        ],
      });
    }
  });
  if (data.length === 1) {
    data.push({
      cells: [
        {
          data: { value: "No Filters Added", type: "String" },
        },
      ],
    });
  }
  return [
    ...data,
    {
      cells: [],
    },
    {
      cells: [],
    },
  ];
};

export const getHierarchy = (values) => {
  return [
    values.hierarchy1,
    values.hierarchy2,
    values.hierarchy3,
    values.hierarchy4,
  ].filter((f) => f);
};
