import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetPerformanceSegmentData(decimal = 4) {
  const path = `/sales_and _forecast/performance_summary_product`;
  const {
    data = [],
    mutate,
    isLoadingSegment,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    isLoadingSegment,
    mutateSegment: mutate,
    rowDataSegment: data,
  };
}
