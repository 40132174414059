import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetUploadSummaryFile() {
  const path = `demand_forecast/upload_summary`;

  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );

  const { showError } = useNotify();
  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);
  return {
    isSuccess,
    data,
    mutate,
    isError,
    isLoading,
  };
}
