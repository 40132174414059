import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PublicIcon from "@mui/icons-material/Public";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export const initialValues_FaReport = {
  volumeCagr: [],
  filters: {
    BP: [],
    Description: [],
    Category: [],
    QTR: [],
    MOC: [],
    Year: [],
    Sales_Business: [],
    Sales_Business_Group: [],
    "HUL BUSINESS": [],
    Cluster_Code: [],
    Cluster: [],
    Brand_Variant: [],
    Modified_Brand: [],
    SubCategory: [],
    Sales_Category: [],
    Seasonal_Tagging: [],
    UOM: [],
    Channel: [],
    Format1: [],
    Format2: [],
    Top_Packs: [],
    Scope_Flag: [],
  },
  openDrawer: true,
  decimal:0,
  demandForecastUploadedFile:[],
  filename: "",
  hierarchy: [{ hierarchy: "Channel" }],
};
export const filtersData = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Cluster Coder",
        name: "Cluster_Code",
        component: MultiSelectDropdown,
      },
      {
        label: "Region",
        name: "Region",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: HourglassEmptyIcon,
    filters: [
      {
        label: "QTR",
        name: "QTR",
        component: MultiSelectDropdown,
      },
      {
        label: "MOC",
        name: "MOC",
        component: MultiSelectDropdown,
      },
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "SubCategory",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
      {
        label: "HUL BUSINESS",
        name: "HUL BUSINESS",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top_Packs",
        component: MultiSelectDropdown,
      },

      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const hireachyDropDown = [
  {
    label: "Channel",
    value: "Channel",
  },
  {
    label: "BasePack",
    value: "BP",
  },
  {
    label: "Description",
    value: "Description",
  },
  {
    label: "Region",
    value: "Region",
  },
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "QTR",
    value: "QTR",
  },
  {
    label: "MOC",
    value: "MOC",
  },
  {
    label: "Year",
    value: "Year",
  },
  {
    label: "Brand",
    value: "Brand",
  },
  {
    label: "Modified_Brand",
    value: "Modified_Brand",
  },
  {
    label: "Brand_Variant",
    value: "Brand_Variant",
  },
  {
    label: "Pack_Cluster",
    value: "Pack_Cluster",
  },
  {
    label: "Cluster_Code",
    value: "Cluster_Code",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "UOM",
    value: "UOM",
  },
  {
    label: "Segment",
    value: "Segment",
  },
  {
    label: "Sales_Business_Group",
    value: "Sales_Business_Group",
  },
  {
    label: "Sales_Category",
    value: "Sales_Category",
  },
  {
    label: "Sales_Business",
    value: "Sales_Business",
  },
  {
    label: "Sub_Category",
    value: "Sub_Category",
  },
  {
    label: "Cluster",
    value: "Cluster",
  },
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "Format1",
    value: "Format1",
  },
  {
    label: "Format2",
    value: "Format2",
  },
  {
    label: "Top_Packs",
    value: "Top_Packs",
  },
  {
    label: "HUL BUSINESS",
    value: "HUL BUSINESS",
  },
  {
    label: "Seasonal_Tagging",
    value: "Seasonal_Tagging",
  },
];
