/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Section from "components/Section";
import ExportOption from "components/ExportOption/ExportOption";
import ButtonGroup from "components/ButtonGroup";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Checkbox
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SelectField from "components/form-fields/SelectField";
import useGetfaTDP from "services/SalesForecastVersioning/useGetfaTDP";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";
import { useFormikContext, Field } from "formik";

import { StyledCard } from "components/Section/Section.styles";
import FAReportTable from "./FaReportTable";
import { hireachyDropDown } from "../formData";
// import { hireachyDropDown } from "./formData";
import DeleteSummaryFiles from "../../Summary/components/DeleteSummaryFiles";
import SummaryUpload from "../../Summary/components/SummaryUpload";
import useGetFileUploadedList from "services/DemandForecast/useGetFileUploadedList";

export default function FaReport({ enableAPIs, tag }) {
  const [checked, setChecked] = useState("table");
  const [displayScreen, setDisplayScreen] = useState("MOC");
  const { values, setFieldValue } = useFormikContext();
  const [gridApi1, setGridApi1] = useState();
  // const [decimal, setDecimal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  
  const { demandForecastUploadedFile, refetch } = useGetFileUploadedList({
    username: localStorage.getItem("username"),
    tag: tag,
  });

  const { isLoading, colDef, mutate, data } = useGetfaTDP(values.decimal);

  const onApply = useCallback(
    (values) => {
      mutate({
        heirarchy: values.hierarchy?.map((f) => f.hierarchy),
        filters: values.filters,
        filename: values.uploadCheck? values.demandForecastUploadedFile :"Sample",
        term: displayScreen,
        input_type:values.uploadCheck?"upload":"default",
        username: localStorage.getItem("username"),
      });
    },
    [displayScreen, mutate]
  );

  useEffect(() => {
    onApply(values);
  }, [onApply, enableAPIs]);

  const exportCsv=()=>{
    if(gridApi1){
      gridApi1.exportDataAsCsv();
    }
  }

  const filteredOptions = hireachyDropDown.filter(
    (option) => !values.hierarchy?.some((h) => h.hierarchy === option.value)
  );
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} md={12} justifyContent="flex-end">
            <Grid item md={2} xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  // onChange={(event) => setDecimal(event.target.value)}
                  onChange={(event) => setFieldValue("decimal",event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
            <Field
              name="demandForecastUploadedFile"
              label={"Uploaded File Name"}
              component={SelectField}
              options={[...demandForecastUploadedFile]}
              fullWidth
              disabled={demandForecastUploadedFile.length===0}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Select Uploaded file">
            <FormControlLabel 
              control={<Checkbox />}
              checked={values.uploadCheck}
              onChange={(e)=>{setFieldValue("uploadCheck",e.target.checked)}} 
              disabled={demandForecastUploadedFile.length===0} 
            />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Delete File"}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDelete(true)}
                disabled={demandForecastUploadedFile.length===0}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Upload File"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                <CloudUploadIcon />
              </Button>
            </Tooltip>
          </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <DeleteSummaryFiles
        tag={tag}
        open={openDelete}
        setOpen={setOpenDelete}
        demandForecastUploadedFile={demandForecastUploadedFile}
        refetch={refetch}
      />
      <SummaryUpload
        tag={tag}
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetch}
      />
      <Grid item md={12}>
        <Section
          title={"V1 Report"}
          onSettings={
            <ExportOption
              onClick={()=>exportCsv()}
            />
          }
        >
          <Grid container>
            <Grid item md={12}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={displayScreen}
                  onChange={(event) => setDisplayScreen(event.target.value)}
                >
                  <FormControlLabel
                    value="MOC"
                    control={<Radio />}
                    label="MOC"
                  />
                  <FormControlLabel
                    value="TDP"
                    control={<Radio />}
                    label="TDP"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <HierarchyFieldArray
                  values={values}
                  options={filteredOptions}
                  fieldName={"hierarchy"}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => onApply(values)}
                  sx={{ mt: 2 }}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item md={12}>
                <FAReportTable
                  height={378}
                  gridApi={gridApi1}
                  setGridApi={setGridApi1}
                  colDef={colDef}
                  isLoading={isLoading}
                  data={data}
                />
              </Grid>
            </Grid>
          </Grid>
        </Section>
      </Grid>
    </Grid>
  );
}
