import { useQuery } from "react-query";
import { getApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFileName() {
  const path = "filter_list_test/getfilename";

  const { data, isLoading, isError, error } = useQuery(
    ["getchart"],
    () => getApi(path),
    {
      enabled: true,
    }
  );
  const fileName = [];
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      fileName.push({ label: element, value: element });
    });
  }

  return {
    fileName,
    isLoading,
    data,
    isError,
    error,
  };
}
