export const axisOptions = (channel) => {
  if (channel === "MT Retail") {
    return [
      { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      { label: `Baseline in RPM`, value: "Baseline in RPM" },
      { label: `CCF`, value: "CCF" },
      { label: `Covid Corrected`, value: "COVID_Corrected_Sales" },
      { label: `CP Corrected RPM`, value: "CP_Corrected_Sales_RPM" },
      { label: `CP Corrected Units`, value: "CP_Corrected_Sales_Units" },
      { label: `Child Pack`, value: "Child_Pack" },
      { label: "Dispatch Quantity", value: "Dispatch.Quantity" },
      { label: `Dispatch Quantity Cases`, value: "Dispatch Quantity in Cases" },
      { label: `Dispatch Quantity RPM`, value: "Dispatch Quantity in RPM" },
      { label: `Dispatch Quantity Value`, value: "Dispatch Quantity in Value" },
      { label: `Eco`, value: "Eco" },
      {
        label: `Falcon Forecast in Eaches (millions)`,
        value: "Accenture_Forecast",
      },
      { label: `ACC Forecast in RPM`, value: "ACC Forecast in RPM" },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Forecast in Value",
      },
      { label: `Grammage`, value: "GRM_updated" },
      // { label: `HulForecast in Eaches (millions)`, value: "HUL_Forecast" },
      { label: `HulForecast in RPM`, value: "HUL Forecast in RPM" },
      {
        label: `HulForecast in Value (crores)`,
        value: "HUL Forecast in Value",
      },
      { label: "MRP", value: "MRP_updated" },
      { label: `Order Quantity`, value: "Order.Quantity" },
      { label: `Order Quantity cases`, value: "Order Quantity in Cases" },
      { label: `Order Quantity RPM`, value: "Order Quantity in RPM" },
      { label: `Order Quantity Value`, value: "Order Quantity in Value" },
      { label: `Promo Value`, value: "Promo_Value" },
      { label: `Promo Flag`, value: "Promo_Flag" },
      { label: `Pogo value`, value: "POGO_Value" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: `Sales in Eaches CV (millions)`, value: "Sales in Eaches CV" },
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in RpmCv`, value: "Sales in RPM CV" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Value CV (crores)", value: "Sales in Value CV" },
      { label: "TDP WorkingDays", value: "TDPWorking.Days" },
      {
        label: "Visiblity Assest DescriptionCount",
        value: "Visiblity Assest Description Count",
      },

      { label: "Promotions Count", value: "promotions_count" },
      { label: `TTS Amount`, value: "Max_Give_Amount" },
      { label: `TTS Percentage`, value: "max_give_percent " },
    ];
  } else if (channel === "GT") {
    return [
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Cases", value: "Sales in Cases" },

      { label: `HUL Forecast in RPM`, value: "HUL Forecast in RPM" },
      // { label: `HUL_Forecast Eaches (millions)`, value: "HUL_Forecast" },
      {
        label: "HUL Forecast in Value (crores)",
        value: "HUL Forecast in Value",
      },
      { label: "HUL Forecast in Cases", value: "HUL Forecast in Cases" },

      { label: `Falcon Forecast in RPM`, value: "Falcon Forecast in RPM" },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Falcon_Forecast",
      // },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Falcon Forecast in Value",
      },
      { label: "Falcon Forecast in Cases", value: "Falcon Forecast in Cases" },

      { label: "CP_Corrected_Sales", value: "CP_Corrected_Sales" },
      { label: "COVID Corrected Sales", value: "COVID Corrected Sales" },

      { label: `CP Corrected`, value: "CP Corrected RPM" },
      { label: "MRP", value: "MRP_updated" },
      { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      { label: `Baseline in RPM`, value: "Baseline in RPM" },

      { label: "Sales in Eaches CV (millions)", value: "Sales in Eaches CV" },
      { label: `Sales in Values CV (crores)`, value: "Sales in Vales CV" },
      { label: `Sales in RPM CV`, value: "Sales in RPM CV" },

      // { label: `Order Quantity`, value: "Order_Quantity" },
      { label: "TDP WorkingDays", value: "TDPWorking.Days" },
      { label: `TTS Amount`, value: "Max_Give_Amount" },
      { label: `TTS Percentage`, value: "Max_Give_Percentage" },
      { label: `CCF`, value: "CCF" },
      { label: `Eco`, value: "Eco" },
      { label: `Grammage`, value: "GRM_updated" },
      { label: `Promo Value`, value: "Promo_Value" },
      { label: `Sales Secondary Unit`, value: "Sales Secondary Units" },
      { label: `Secondary Fitted Rpm`, value: "Secondary_Fitted_RPM" },
      { label: `Give All Amount`, value: "GIVE_ALL_AMOUNT" },
      { label: `Give Retail Amount`, value: "GIVE_RETAIL_AMOUNT" },
      { label: `Give Shakti Amount`, value: "GIVE_SHAKTI_AMOUNT" },
      { label: `Give Wholesale Amount`, value: "GIVE_WHOLESALE_AMOUNT" },
      { label: `Give All Percentage`, value: "GIVE_ALL_PERCENTAGE" },
      { label: `Give Retail Percentage`, value: "GIVE_RETAIL_PERCENTAGE" },
      { label: `Give Shakti Percentage`, value: "GIVE_SHAKTI_PERCENTAGE" },
      {
        label: `Give Wholesale Percentage`,
        value: "GIVE_WHOLESALE_PERCENTAGE",
      },
    ];
  }
  //ecom in the UI
  else if (channel === "MT_old") {
    return [
      { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      { label: `Baseline in RPM`, value: "Baseline in RPM" },
      // { label: `Big Billion Day`, value: "Big_Billion_Day" },
      // { label: `Beauty Event`, value: "Beauty_Event" },
      { label: `CCF`, value: "CCF" },
      // { label: `Cat Event`, value: "Cat_Evenntt" },
      // { label: `Eco`, value: "Eco" },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Accenture_Forecast",
      // },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Falcon Forecast",
      // },
      { label: `Falcon Forecast in RPM`, value: "ACC Forecast in RPM" },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Forecast in Value",
      },
      { label: `Grammage`, value: "GRM_updated" },
      // {
      //   label: `HulForecast in Eaches (millions)`,
      //   value: "HUL_Forecast",
      // },
      { label: `HulForecast in RPM`, value: "HUL Forecast in RPM" },
      {
        label: `HulForecast in Value (crores)`,
        value: "HUL Forecast in Value",
      },
      { label: `Forecast in Value (crores)`, value: "Forecast in Value" },
      // { label: `Last Republic Day Sale`, value: "Last_Republic_Day_Sale" },
      // { label: `Last Beauty Event Sale`, value: "Last_Beauty_Event_Sale" },
      // { label: `Last BigBillion Day Sale`, value: "Last_Big_Billion_Day_Sale" },
      // {
      //   label: `Last Independence Day Sale`,
      //   value: "Last_Independence_Day_Sale",
      // },
      // { label: `Last Orange Day Sale`, value: "Last_Orange_Day_Sale" },
      // { label: `Last Public Day Sale`, value: "Last_Public_Holiday_Sale" },
      // { label: `Last Prime Day Sale`, value: "Last_Prime_Day_Sale" },
      { label: `Promo Flag`, value: "Promo_Flag" },
      { label: `Promo Value`, value: "Promo_Value" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: `Sales in Eaches CV (millions)`, value: "Sales in Eaches CV" },
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in RpmCv`, value: "Sales in RPM CV" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Value CV (crores)", value: "Sales in Value CV" },
      // { label: `TTS Amount`, value: "tts_amount" },
      // { label: `TTS Percentage`, value: "tts_percentage" },
      // { label: `Covid Peak`, value: "covid_peak" },
    ];
  }
  else if (channel === "MT") {
    return [
      // { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      // { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      // { label: `Baseline in RPM`, value: "Baseline in RPM" },
      // { label: `CCF`, value: "CCF" },
      { label: `CCF modified`, value: "CCF_modified" },
      { label: `Falcon Forecast in RPM`, value: "Falcon Forecast in RPM" },
      { label: `Falcon Forecast in Cases`, value: "Falcon Forecast in Cases" },
      { label: `Falcon Forecast in Value (crores)`, value: "Falcon Forecast in Value"},
      // { label: `Grammage`, value: "GRM_updated" },
      { label: `HulForecast in RPM`, value: "HUL Forecast in RPM" },
      { label: `HulForecast in Value (crores)`, value: "HUL Forecast in Value"},
      { label: `HulForecast in Cases (crores)`, value: "HUL Forecast in Cases"},
      // { label: `Promo Flag Go`, value: "Promo_Flag_GO" },
      // { label: `BxGx_Promotion_GO`, value: "BxGx_Promotion_GO" },
      // { label: `POGO_Value`, value: "POGO_Value" },
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Cases (crores)", value: "Sales in Cases" },
      { label: `Falcon Forecast in Eaches (millions)`, value: "Falcon_Forecast" },
      { label: `Sales in Eaches`, value: "Sales in Eaches" },
      { label: `HUL_Forecast`, value: "HUL_Forecast" },
      // { label: `MRP_updated`, value: "MRP_updated" },
      { label: `GSV.Rate`, value: "GSV.Rate" },
      { label: `TUR.Rate`, value: "TUR.Rate" },
      { label: `TDPWorking_Days_modified`, value: "TDPWorking_Days_modified" },
      { label: `Promo_Price_per_GRM_GO_modified`, value: "Promo_Price_per_GRM_GO_modified" },
      { label: `MRP_updated_modified`, value: "MRP_updated_modified" },
      { label: `GRM_updated_modified`, value: "GRM_updated_modified" },
      { label: `apparentTemperatureMin_modified`, value: "apparentTemperatureMin_modified" },
      { label: `Pre_Promo_ppg_GO_modified`, value: "Pre_Promo_ppg_GO_modified" },
      { label: `Pre_Promo_Value_GO_modified`, value: "Pre_Promo_Value_GO_modified" },
      { label: `POS_modified`, value: "POS_modified" },
      { label: `NUM_OF_STORES_BB_modified`, value: "NUM_OF_STORES_BB_modified" },
      { label: `VISIBILITY_AMT_BB_modified`, value: "VISIBILITY_AMT_BB_modified" },
      { label: `NUM_OF_STORES_SK_modified`, value: "NUM_OF_STORES_SK_modified" },
      { label: `VISIBILITY_AMT_SK_modified`, value: "VISIBILITY_AMT_SK_modified" },
      { label: `COUNT_HUL_OUTLET_CODE_modified`, value: "COUNT_HUL_OUTLET_CODE_modified" },
      { label: `No_Of_Promo_Cluster_modified`, value: "No_Of_Promo_Cluster_modified" },
      { label: `Percent_Promo_Sales_modified`, value: "Percent_Promo_Sales_modified" },
      { label: `Big_Day1_modified`, value: "Big_Day1_modified" },
      { label: `Big_Day8_modified`, value: "Big_Day8_modified" },
      { label: `Post_1Big_Day1_modified`, value: "Post_1Big_Day1_modified" },
      { label: `Post_1Big_Day8_modified`, value: "Post_1Big_Day8_modified" },
      { label: `Pre_1Big_Day1_modified`, value: "Pre_1Big_Day1_modified" },
      { label: `Pre_1Big_Day8_modified`, value: "Pre_1Big_Day8_modified" },
      { label: `Discount_Percentage_GO_modified`, value: "Discount_Percentage_GO_modified" },
      { label: `Volume_extra_GO_modified`, value: "Volume_extra_GO_modified" },
      { label: `BxGx_Promotion_GO_modified`, value: "BxGx_Promotion_GO_modified" },
      { label: `Promo_Flag_GO_modified`, value: "Promo_Flag_GO_modified" },


    ];
  } else if (channel === "Ecom") {
    return [
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Cases", value: "Sales in Cases" },

      { label: `HUL Forecast in RPM`, value: "HUL Forecast in RPM" },
      // { label: `HUL_Forecast Eaches (millions)`, value: "HUL_Forecast" },
      {
        label: "HUL Forecast in Value (crores)",
        value: "HUL Forecast in Value",
      },
      { label: "HUL Forecast in Cases", value: "HUL Forecast in Cases" },

      { label: `Falcon Forecast in RPM`, value: "Falcon Forecast in RPM" },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Falcon_Forecast",
      // },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Falcon Forecast in Value",
      },
      { label: "Falcon Forecast in Cases", value: "Falcon Forecast in Cases" },

      { label: "MRP", value: "MRP" },

      { label: `CCF`, value: "CCF" },

      { label: `Grammage`, value: "Grammage" },
      { label: "disc_perc_max_Cat_Event", value: "disc_perc_max_Cat_Event" },
      { label: "disc_perc_max_Marquee", value: "disc_perc_max_Marquee" },
      { label: "disc_perc_max_Monthly", value: "disc_perc_max_Monthly" },
      { label: "disc_perc_max_Regular", value: "disc_perc_max_Regular" },
      { label: "disc_perc_min_Cat_Event", value: "disc_perc_min_Cat_Event" },
      { label: "disc_perc_min_Marquee", value: "disc_perc_min_Marquee" },
      { label: "disc_perc_min_Monthly", value: "disc_perc_min_Monthly" },
      { label: "disc_perc_min_Regular", value: "disc_perc_min_Regular" },
      { label: "ecp_max_Cat_Event", value: "ecp_max_Cat_Event" },
      { label: "ecp_max_Marquee", value: "ecp_max_Marquee" },
      { label: "ecp_max_Monthly", value: "ecp_max_Monthly" },
      { label: "ecp_max_Regular", value: "ecp_max_Regular" },
      { label: "ecp_min_Cat_Event", value: "ecp_min_Cat_Event" },
      { label: "ecp_min_Marquee", value: "ecp_min_Marquee" },
      { label: "ecp_min_Monthly", value: "ecp_min_Monthly" },
      { label: "ecp_min_Regular", value: "ecp_min_Regular" },
      { label: "flag_Cat_Event", value: "flag_Cat_Event" },
      { label: "flag_Marquee", value: "flag_Marquee" },
      { label: "flag_Monthly", value: "flag_Monthly" },
      { label: "flag_Regular", value: "flag_Regular" },
      { label: "ppg_max_Cat_Event", value: "ppg_max_Cat_Event" },
      { label: "ppg_max_Marquee", value: "ppg_max_Marquee" },
      { label: "ppg_max_Monthly", value: "ppg_max_Monthly" },
      { label: "ppg_max_Regular", value: "ppg_max_Regular" },
      { label: "ppg_min_Cat_Event", value: "ppg_min_Cat_Event" },
      { label: "ppg_min_Marquee", value: "ppg_min_Marquee" },
      { label: "ppg_min_Monthly", value: "ppg_min_Monthly" },
      { label: "ppg_min_Regular", value: "ppg_min_Regular" },
      { label: "month", value: "month" },
      { label: "Beauty_Event", value: "Beauty_Event" },
      { label: "Big_Billion_Day", value: "Big_Billion_Day" },
      { label: "Extra_Discount", value: "Extra_Discount" },
      { label: "Independence_Day_Sale", value: "Independence_Day_Sale" },
      { label: "Orange_Day_Sale", value: "Orange_Day_Sale" },
      { label: "Prime_Day_Sale", value: "Prime_Day_Sale" },
      { label: "Public_Holiday_Sale", value: "Public_Holiday_Sale" },
      { label: "Republic_Day_Sale", value: "Republic_Day_Sale" },
      { label: "Offtake_Sales", value: "Offtake_Sales" },
    ];
  } else if (channel === "ecom") {
    return [
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Cases", value: "Sales in Cases" },

      { label: `HUL Forecast in RPM`, value: "HUL Forecast in RPM" },
      // { label: `HUL_Forecast Eaches (millions)`, value: "HUL_Forecast" },
      {
        label: "HUL Forecast in Value (crores)",
        value: "HUL Forecast in Value",
      },
      { label: "HUL Forecast in Cases", value: "HUL Forecast in Cases" },

      { label: `Falcon Forecast in RPM`, value: "Falcon Forecast in RPM" },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Falcon_Forecast",
      // },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Falcon Forecast in Value",
      },
      { label: "Falcon Forecast in Cases", value: "Falcon Forecast in Cases" },

      { label: "CP_Corrected_Sales", value: "CP_Corrected_Sales" },
      { label: "COVID Corrected Sales", value: "COVID Corrected Sales" },

      { label: `CP Corrected`, value: "CP Corrected RPM" },
      { label: "MRP", value: "MRP_updated" },
      { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      { label: `Baseline in RPM`, value: "Baseline in RPM" },

      { label: "Sales in Eaches CV (millions)", value: "Sales in Eaches CV" },
      { label: `Sales in Values CV (crores)`, value: "Sales in Vales CV" },
      { label: `Sales in RPM CV`, value: "Sales in RPM CV" },

      // { label: `Order Quantity`, value: "Order_Quantity" },
      { label: "TDP WorkingDays", value: "TDPWorking.Days" },
      { label: `TTS Amount`, value: "Max_Give_Amount" },
      { label: `TTS Percentage`, value: "Max_Give_Percentage" },
      { label: `CCF`, value: "CCF" },
      { label: `Eco`, value: "Eco" },
      { label: `Grammage`, value: "GRM_updated" },
      { label: `Promo Value`, value: "Promo_Value" },
      { label: `Sales Secondary Unit`, value: "Sales Secondary Units" },
      { label: `Secondary Fitted Rpm`, value: "Secondary_Fitted_RPM" },
      { label: `Give All Amount`, value: "GIVE_ALL_AMOUNT" },
      { label: `Give Retail Amount`, value: "GIVE_RETAIL_AMOUNT" },
      { label: `Give Shakti Amount`, value: "GIVE_SHAKTI_AMOUNT" },
      { label: `Give Wholesale Amount`, value: "GIVE_WHOLESALE_AMOUNT" },
      { label: `Give All Percentage`, value: "GIVE_ALL_PERCENTAGE" },
      { label: `Give Retail Percentage`, value: "GIVE_RETAIL_PERCENTAGE" },
      { label: `Give Shakti Percentage`, value: "GIVE_SHAKTI_PERCENTAGE" },
      {
        label: `Give Wholesale Percentage`,
        value: "GIVE_WHOLESALE_PERCENTAGE",
      },
    ];
  } else if (channel === "ECOMM") {
    return [
      { label: `Sales in Rpm`, value: "Sales in RPM" },
      { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
      { label: "Sales in Value (crores)", value: "Sales in Value" },
      { label: "Sales in Cases", value: "Sales in Cases" },

      { label: `HUL Forecast in RPM`, value: "HUL Forecast in RPM" },
      // { label: `HUL_Forecast Eaches (millions)`, value: "HUL_Forecast" },
      {
        label: "HUL Forecast in Value (crores)",
        value: "HUL Forecast in Value",
      },
      { label: "HUL Forecast in Cases", value: "HUL Forecast in Cases" },

      { label: `Falcon Forecast in RPM`, value: "Falcon Forecast in RPM" },
      // {
      //   label: `Falcon Forecast in Eaches (millions)`,
      //   value: "Falcon_Forecast",
      // },
      {
        label: `Falcon Forecast in Value (crores)`,
        value: "Falcon Forecast in Value",
      },
      { label: "Falcon Forecast in Cases", value: "Falcon Forecast in Cases" },

      { label: "CP_Corrected_Sales", value: "CP_Corrected_Sales" },
      { label: "COVID Corrected Sales", value: "COVID Corrected Sales" },

      { label: `CP Corrected`, value: "CP Corrected RPM" },
      { label: "MRP", value: "MRP_updated" },
      { label: "Baseline in Eaches (millions)", value: "Baseline in Eaches" },
      { label: `Baseline in Value (crores)`, value: "Baseline in Value" },
      { label: `Baseline in RPM`, value: "Baseline in RPM" },

      { label: "Sales in Eaches CV (millions)", value: "Sales in Eaches CV" },
      { label: `Sales in Values CV (crores)`, value: "Sales in Vales CV" },
      { label: `Sales in RPM CV`, value: "Sales in RPM CV" },

      // { label: `Order Quantity`, value: "Order_Quantity" },
      { label: "TDP WorkingDays", value: "TDPWorking.Days" },
      { label: `TTS Amount`, value: "Max_Give_Amount" },
      { label: `TTS Percentage`, value: "Max_Give_Percentage" },
      { label: `CCF`, value: "CCF" },
      { label: `Eco`, value: "Eco" },
      { label: `Grammage`, value: "GRM_updated" },
      { label: `Promo Value`, value: "Promo_Value" },
      { label: `Sales Secondary Unit`, value: "Sales Secondary Units" },
      { label: `Secondary Fitted Rpm`, value: "Secondary_Fitted_RPM" },
      { label: `Give All Amount`, value: "GIVE_ALL_AMOUNT" },
      { label: `Give Retail Amount`, value: "GIVE_RETAIL_AMOUNT" },
      { label: `Give Shakti Amount`, value: "GIVE_SHAKTI_AMOUNT" },
      { label: `Give Wholesale Amount`, value: "GIVE_WHOLESALE_AMOUNT" },
      { label: `Give All Percentage`, value: "GIVE_ALL_PERCENTAGE" },
      { label: `Give Retail Percentage`, value: "GIVE_RETAIL_PERCENTAGE" },
      { label: `Give Shakti Percentage`, value: "GIVE_SHAKTI_PERCENTAGE" },
      {
        label: `Give Wholesale Percentage`,
        value: "GIVE_WHOLESALE_PERCENTAGE",
      },
    ];
  }
};
