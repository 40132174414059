import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledCard = styled.div`
  position: relative;
  flex-direction: column;
  background: ${({ theme }) => `${theme.palette.background.paper}`};
  box-shadow: ${({ theme }) => `${theme.shadows[1]}`};
  height: ${({ height }) => `${height}px`};
  min-height: ${({ minHeight }) => `${minHeight}px`};
  border-radius: ${({ theme }) => `${theme.spacing(1)}`};
  padding: ${({ theme }) => `${theme.spacing(3)}`};
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#E5EEFF",
  borderColor: "#E5EEFF",
  color: "#000",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#B9CFFF",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "#B9CFFF",
    textTransform: "bold",
  },
  "&:focus": {
    borderColor: "#B9CFFF",
    backgroundColor: "#B9CFFF",
    textTransform: "bold",
  },
});
