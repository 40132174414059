import { AccountCircleOutlined } from "@mui/icons-material";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import PublicIcon from "@mui/icons-material/Public";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export const getHierarchy = (values) => {
  const hierarchy = values.hierarchyBusinessGroup?.map((f) => f.hierarchy);
  return hierarchy;
};

export const initialValues = {
  filename: "",
  savedName: "",
  filters: {
    Basepack: [],
    Description: [],
    Modified_Brand: [],
    Brand_Varient: [],
    Brand: [],
    Seasonality: [],
    Segment: [],
    Launch: [],
    Pricelist_HUL123: [],
    Segmentation: [],
    Cluster: [],
    Customer_Tag: [],
    Modified_Customer: [],
    APO_Customer: [],
    TGT_Customer: [],
    Business_Group: [],
    Planner_Category: [],
    Category: [],
    Pack_Format: [],
    Key_Packs: [],
    Niche_Packs: [],
    Sub_Format: [],
    Schwan_Pack: [],
    Super_Hero_Packs: [],
    Import_Format: [],
    SNOP_Tagging: [],
    SNOP_Tagging_1: [],
    SNOP_Tagging_2: [],
    Ecomm_Tag: [],
    Tagg1: [],
    Tagg2: [],
  },
  openDrawer: true,
  nm: "",
  ratio: "",
  decimal:0,
  demandForecastUploadedFile: "Default",
  clickApply: false,
  hierarchyBusinessGroup: [{ hierarchy: "Modified_Customer" }],
};

export const filterData = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "Basepack",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Varient",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Seasonality",
        name: "Seasonality",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Launch",
        name: "Launch",
        component: MultiSelectDropdown,
      },
      {
        label: "Pricelist HUL123",
        name: "Pricelist_HUL123",
        component: MultiSelectDropdown,
      },
      {
        label: "Segmentation",
        name: "Segmentation",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Customer",
    icon: AccountCircleOutlined,
    filters: [
      {
        label: "Customer Tag",
        name: "Customer_Tag",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Customer",
        name: "Modified_Customer",
        component: MultiSelectDropdown,
      },
      {
        label: "APO Customer",
        name: "APO_Customer",
        component: MultiSelectDropdown,
      },
      {
        label: "TGT Customer",
        name: "TGT_Customer",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Business Group",
        name: "Business_Group",
        component: MultiSelectDropdown,
      },
      {
        label: "Planner Category",
        name: "Planner_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Pack Format",
        name: "Pack_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "Key Packs",
        name: "Key_Packs",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Format",
        name: "Sub_Format",
        component: MultiSelectDropdown,
      },
      {
        label: "Niche Packs",
        name: "Niche_Packs",
        component: MultiSelectDropdown,
      },
      {
        label: "Super Hero Packs",
        name: "Super_Hero_Packs",
        component: MultiSelectDropdown,
      },
      {
        label: "Schwan Pack",
        name: "Schwan_Pack",
        component: MultiSelectDropdown,
      },
      {
        label: "Import Format",
        name: "Import_Format",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "S&OP Tagging",
        name: "SNOP_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "S&OP Tagging_1",
        name: "SNOP_Tagging1",
        component: MultiSelectDropdown,
      },
      {
        label: "S&OP Tagging2",
        name: "SNOP_Tagging2",
        component: MultiSelectDropdown,
      },
      {
        label: "Ecomm Tagg",
        name: "Ecomm_Tagg",
        component: MultiSelectDropdown,
      },
      {
        label: "Tagg1",
        name: "Tagg1",
        component: MultiSelectDropdown,
      },
      {
        label: "Tagg2",
        name: "Tagg2",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const hireachyDropDown = [
  { label: "Basepack", value: "Basepack" },
  { label: "Description", value: "Description" },
  { label: "Modified_Brand", value: "Modified_Brand" },
  { label: "Brand_Varient", value: "Brand_Varient" },
  { label: "Brand", value: "Brand" },
  { label: "Seasonality", value: "Seasonality" },
  { label: "Segment", value: "Segment" },
  { label: "Launch", value: "Launch" },
  { label: "Pricelist_HUL123", value: "Pricelist_HUL123" },
  { label: "Segmentation", value: "Segmentation" },
  { label: "Cluster", value: "Cluster" },
  { label: "Customer_Tag", value: "Customer_Tag" },
  { label: "Modified_Customer", value: "Modified_Customer" },
  { label: "APO_Customer", value: "APO_Customer" },
  { label: "TGT_Customer", value: "TGT_Customer" },
  { label: "Business_Group", value: "Business_Group" },
  { label: "Planner_Category", value: "Planner_Category" },
  { label: "Category", value: "Category" },
  { label: "Pack_Format", value: "Pack_Format" },
  { label: "Key_Packs", value: "Key_Packs" },
  { label: "Niche_Packs", value: "Niche_Packs" },
  { label: "Sub_Format", value: "Sub_Format" },
  { label: "Schwan_Pack", value: "Schwan_Pack" },
  { label: "Super_Hero_Packs", value: "Super_Hero_Packs" },
  { label: "Import_Format", value: "Import_Format" },
  { label: "SNOP_Tagging", value: "SNOP_Tagging" },
  { label: "SNOP_Tagging_1", value: "SNOP_Tagging_1" },
  { label: "SNOP_Tagging_2", value: "SNOP_Tagging_2" },
  { label: "Ecomm_Tag", value: "Ecomm_Tag" },
  { label: "Tagg1", value: "Tagg1" },
  { label: "Tagg2", value: "Tagg2" },
];
