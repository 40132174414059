import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import { getFormatValue, getStyles } from "utils/constants";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

const styles = {
  cellStyle: getStyles,
  minWidth: 150,
};
const colDef = (decimals) => [
  {
    field: "Acc FA Cases",
    headerName: "Falcon FA Cases",
    headerTooltip: "Falcon FA Cases",
    valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
    ...styles,
  },
  {
    field: "V1 FA Cases",
    headerTooltip: "V1 FA Cases",
    ...styles,
    valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
  },
  {
    field: "Acc FA-V1 FA",
    headerName: "Falcon FA-V1 FA",
    headerTooltip: "Falcon FA-V1 FA",
    ...styles,
    valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
  },
  {
    field: "Acc Val Bias",
    headerName: "Falcon Val Bias",
    headerTooltip: "Falcon Val Bias",
    ...styles,
    valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
  },
  {
    field: "V1 Val Bias",
    headerTooltip: "V1 Val Bias",
    ...styles,
    valueFormatter: (params) => `${getFormatValue(params, decimals)}%`,
  },
];

export default function useGetfaTDP(decimal = 4) {
  const path = `/sales_and _forecast/fa_tdp_pivot`;
  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    isLoading,
    colDef: colDef(decimal),
    mutate,
    data,
  };
}
