import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetClusterCode() {
  const path = "/scenario_simlation/getClusterCode";
  const {
    data = [],
    mutate,
    isLoading,

    isError,
    error,
  } = useMutation((formData) => postApi(path, formData));
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    IsLoadingCreate: isLoading,
    mutateGetCluster: mutate,
    clusterDetails: data,
    isError,
  };
}
