import { StyledCard, IconDiv } from "./Section.styles";

import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@emotion/react";

const Section = ({
  title,
  height,
  minHeight,
  onSettings,
  showIndicator = false,
  children,
  actions,
  showBackIcon = false,
  onBack,
}) => {
  const theme = useTheme();
  return (
    <StyledCard height={height} minHeight={minHeight}>
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={4}>
            {title && (
              <Stack direction="row">
                {showBackIcon && (
                  <ArrowBackIcon color="success" onClick={onBack} />
                )}
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                >
                  {title}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={1}>
            {actions}
          </Grid>
          <Grid item md={1}>
            <IconDiv>
              {showIndicator && (
                <IconDiv>
                  <ArrowUpwardOutlinedIcon color="success" />
                  <ArrowDownwardOutlinedIcon color="error" />
                </IconDiv>
              )}
              {onSettings}
            </IconDiv>
          </Grid>
          <Grid item md={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default Section;
