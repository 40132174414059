import { useMutation } from "react-query";
import { useEffect } from "react";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";

export default function useGetFeatureTables() {
  const path = "demand_forecast/get_Feature_importance_tables";

  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const featureTables = [];
  if (data && Array.isArray(data) && data.length) {
    data.forEach((item) => {
      featureTables.push({ label: item, value: item });
    });
  }

  return {
    featureTables,
    featureTablesLoading: isLoading,
    featureTablesMutate: mutate
  };
}
