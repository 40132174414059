import { Grid, IconButton, Box } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import React from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Dialog = (props) => {
  const {
    open,
    maxWidth = "sm",
    onClose,
    header,
    footer,
    children,
    scroll = "paper",
    fullWidth = false,
    ...rest
  } = props;

  return (
    <MuiDialog
      {...rest}
      scroll={scroll}
      disableEscapeKeyDown
      aria-labelledby="customized-MuiDialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <MuiDialogTitle id="customized-MuiDialog-title">
        <Grid container alignItems="center">
          {header}
          <Box component="div" sx={{ flex: 1 }} />
          <IconButton size="small" onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Grid>
      </MuiDialogTitle>
      <MuiDialogContent dividers>{children}</MuiDialogContent>
      <MuiDialogActions>{footer}</MuiDialogActions>
    </MuiDialog>
  );
};

export default Dialog;
