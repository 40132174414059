import { useMutation } from "react-query";
import { getApi, postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useCreateScenario() {
  const path = `/scenario_simulation/create_simulation`;
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    (formData) => postApi(path, formData)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    IsLoadingCreate: isLoading,
    mutateCreateScenario: mutate,
    data,
    isError,
    isSuccess
  };
}
