import React from "react";
import ReactEditableTable from "./ReactEditableTable"
import {Card, CardContent,Typography, Button, Grid, Tooltip } from "@mui/material";
import {useFormikContext} from "formik";
import InfoIcon from '@mui/icons-material/Info';


export default function Test({date, createScenario}){
    const {values} = useFormikContext();
    
    return (
        <>
        <Grid container spacing={2}>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Scenario Name: {values.createscenarioName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Category Name: {values.createscenarioCategoryName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
        <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                 Features: {values.features.join(", ")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12}>
          <div style={{display:"flex",alignContent:"right"}}>
          <Tooltip title="To paste the changed values-click on cltr c and drag and select the file and cltr v">
        <InfoIcon />
        </Tooltip>
        </div>
          <ReactEditableTable date={date} createScenario={createScenario}/>
        </Grid>
      </Grid>
        {/* <Button className="btn-back">Back</Button>
        <Button className='btn-next'>Finish</Button> */}
        </>
    )
}