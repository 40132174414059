import React from "react";

import RecentScenarioSimulation from "./RecentScenarioSimulation";
import CreateScenarioSimaultion from "./CreateScenarioSimaultion";
import HistoryComparison from "./HistoryComparison";
import { Field, useFormikContext } from "formik";

import History from "./History";
import ComparsionPage from "./ComparsionPage";

export default function ScenarioSimultaionDashboard() {
  const { values, setFieldValue } = useFormikContext();
  return (
    <React.Fragment>
      {values.pageDetails === "dashboard" && <RecentScenarioSimulation />}
      {values.pageDetails === "createPage" && <CreateScenarioSimaultion />}
      {values.pageDetails === "historyPage" && <History />}
      {values.pageDetails === "comparePage" && <ComparsionPage />}
      {values.pageDetails === "historyComparePage" && (
        <HistoryComparison
          data={values.propsHistoryData}
          enableApi={values.propsHistoryApi}
        />
      )}
      {/* {values.pageDetails === "test" && <Test />} */}
    </React.Fragment>
  );
}
