import { Grid, Box, Typography, Tooltip, Button } from "@mui/material";
import Section from "components/Section";
import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useState } from "react";
import SummaryTable from "./components/SummaryTable";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "components/ButtonGroup";
import { getRowStyle } from "utils/constants";
import { useFormikContext } from "formik";

import { hireachyDropDown } from "./formData";
import HierarchyFieldArray from "components/HierarchyFieldArray/HierarchyFieldArray";

const Summary = ({ enableAPIs, decimals, exportToCSV }) => {
  const { values } = useFormikContext();

  const [value, setValue] = React.useState("qtr");
  const [gridApi1, setGridApi1] = useState();
  const [gridApi2, setGridApi2] = useState();
  const [gridApi3, setGridApi3] = useState();
  const [applyEnable, setApplyEnable] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [checked, setChecked] = useState("table");

  const clickApply = () => {setApplyEnable(!applyEnable)};

  const [gridOptions1] = useState({
    getRowStyle: (params) => getRowStyle(params, "Channel_Split"),
    alignedGrids: [],
  });
  const [gridOptions2] = useState({
    getRowStyle: (params) => getRowStyle(params, "Channel_Split"),
    alignedGrids: [],
  });
  const [gridOptions3] = useState({
    getRowStyle: (params) => getRowStyle(params, "Channel_Split"),
    alignedGrids: [],
  });

  gridOptions1.alignedGrids.push(gridOptions2);
  gridOptions1.alignedGrids.push(gridOptions3);

  gridOptions2.alignedGrids.push(gridOptions1);
  gridOptions2.alignedGrids.push(gridOptions3);

  gridOptions3.alignedGrids.push(gridOptions1);
  gridOptions3.alignedGrids.push(gridOptions2);

  const filteredOptions = hireachyDropDown.filter(
    (option) => !values.hierarchy?.some((h) => h.hierarchy === option.value)
  );

  return (
    <Grid item md={12}>
      <Section
        title={"SOP Analysis"}
        actions={
          <React.Fragment sx={{ m: 2 }}>
            <ButtonGroup
              setValue={setChecked}
              value={checked}
              options={[
                { label: "Table", value: "table" },
                { label: "Chart", value: "chart" },
              ]}
              primaryColor={"#31b2dc"}
              width={84}
            />
            {checked === "table" && (
              <Tooltip title="Download table">
                <CloudDownloadIcon
                  sx={{ mr: 1 }}
                  onClick={() =>
                    exportToCSV(
                      gridApi1,
                      gridApi2,
                      gridApi3,
                      "Channel Analysis"
                    )
                  }
                  style={{
                    fontSize: "34px",
                    marginLeft: "9px",
                    color: "#2036C7",
                  }}
                />
              </Tooltip>
            )}
          </React.Fragment>
        }
      >
        {values.filename && values.clickApply === true &&(<div style={{marginTop:"16px"}}>
            <Grid item md={12}>
              <HierarchyFieldArray
                values={values}
                // options={hireachyDropDown}
                options={filteredOptions}
                fieldName={"hierarchyChannel"}
              />
              <Button
                size="small"
                variant="contained"
                onClick={clickApply}
                sx={{ mt: 2 }}
              >
                Apply
              </Button>
            </Grid>
            </div>)}
        {checked === "chart" && (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="qtr" control={<Radio />} label="QTR" />
              <FormControlLabel value="fy" control={<Radio />} label="FY" />
            </RadioGroup>
          </FormControl>
        )}
        <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
          Volume
        </Typography>

        <SummaryTable
          term="VOL"
          height={378}
          gridApi={gridApi1}
          setGridApi={setGridApi1}
          decimals={decimals}
          enableAPIs={enableAPIs}
          gridOptions={gridOptions1}
          selected={checked}
          quarter={value}
          setApplyEnable={setApplyEnable}
          applyEnable={applyEnable}
        />

        <Box sx={{ mt: 2 }}>
          <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
            Value
          </Typography>

          <SummaryTable
            term="VALUE"
            height={378}
            gridApi={gridApi2}
            setGridApi={setGridApi2}
            decimals={decimals}
            gridOptions={gridOptions2}
            enableAPIs={enableAPIs}
            selected={checked}
            quarter={value}
            setApplyEnable={setApplyEnable}
          applyEnable={applyEnable}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography style={{ fontStyle: "20px", fontSize: "bold" }}>
            Eaches
          </Typography>

          <SummaryTable
            term="EACH"
            height={378}
            gridApi={gridApi3}
            gridOptions={gridOptions3}
            setGridApi={setGridApi3}
            decimals={decimals}
            enableAPIs={enableAPIs}
            selected={checked}
            quarter={value}
            setApplyEnable={setApplyEnable}
          applyEnable={applyEnable}
          />
        </Box>
      </Section>
    </Grid>
  );
};

export default Summary;
