import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import AppLayout from "components/AppLayout/AppLayout";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getColorPalette } from "styles/theme";

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function App({ pca, username, account }) {
  const history = useNavigate();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  if (username !== undefined) {
    localStorage.setItem("username", username);
  }
  const [mode, setMode] = React.useState('light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(() => createTheme(getColorPalette(mode)), [mode]);

  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AppLayout />
      </ThemeProvider>
      </ColorModeContext.Provider>
      </MsalProvider>
    </ClientSideNavigation>
  );
}
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}
