import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetSummaryFilters(payload) {
  const path = "/demand_forecast/get_snop_filters";

  const { data, isLoading, mutate, error, isError } = useMutation((payload) =>
    postApi(path, payload)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  return {
    data,
    mutate,
    isLoading,
  };
}
