import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";
export default function useDeleteScenario() {
  const path = "/scenario_simulation/delete_files";

  const { data, isLoading, mutate, isSuccess, isError, error } = useMutation(
    (payload) => postApi(path, payload)
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [isError]);

  return {
    isSuccess,
    data,
    isError,
    mutateDelete: mutate,
    isLoadingDelete: isLoading,
  };
}
