import styled from "@emotion/styled";
import { Button, Fab } from "@mui/material";

export const LoginSideWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex: 0 60%;
  background-repeat: no-repeat;
  background-color: #fafafa;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center;
`;
export const LoginWrapper = styled.div`
  display: flex !important;
  height: 100vh;
  overflow: hidden;
`;
export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 40%;
  justify-content: center;
  align-items: center;
`;

export const FlaotButton = styled(Fab)`
  background-color: #225cf1;
  width:650px,
  height:52px,
  borderRadius:8px
 
`;
export const signButton = styled(Button)`
  position: absolute;
  width: 242px;
  height: 30px;
  left: 1256px;
  top: 554px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;
