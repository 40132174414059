import { useCallback, React } from "react";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@mui/icons-material/Publish";
import { FormHelperText, Typography } from "@mui/material";
import { Root } from "./DragAndUpload.styles";

const DragAndUpload = ({
  label,
  supportedFileFormat,
  setUploadedFile,
  uploadedFile,
  maxSizeOfFileToUpload,
  error,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setUploadedFile(file);
      });
    },
    [setUploadedFile]
  );

  const onDropRejected = useCallback(() => {
   
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: maxSizeOfFileToUpload,
    accept: ".csv",
  });

  return (
    <Root
      uploadedFile={uploadedFile}
      draggingFile={isDragActive}
      {...getRootProps()}
    >
      {uploadedFile ? (
        <Typography color="primary">{uploadedFile?.name}</Typography>
      ) : (
        <>
          <PublishIcon />
          <Typography component="label" variant="subtitle1">
            {label}
          </Typography>
          <input style={{ display: "none" }} {...getInputProps()} />
          <Typography variant="caption">
            {`Drag and Drop or Select *.${supportedFileFormat}  here`}
          </Typography>
        </>
      )}
      <br />

      <Typography variant="caption">
        {error && <FormHelperText error>Error : {error}</FormHelperText>}
      </Typography>
      {Boolean(maxSizeOfFileToUpload) && (
        <Typography variant="subtitle2">
          {maxSizeOfFileToUpload}MB max file size
        </Typography>
      )}
    </Root>
  );
};

export default DragAndUpload;
