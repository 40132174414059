import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { StyledCard } from "components/Section/Section.styles";
import React, { useMemo, useState } from "react";
import Loader from "components/Loader";
import SummaryTableWrapper from "views/Summary/components/SummaryTableWrapper";
import { colDef, outputColdef } from "views/ForecastAdjustment/formData";
import usePutUpdatedForecast from "services/ForecastAdjustments/usePutUpdatedForecast";
import { useFormikContext } from "formik";
import { useQueryClient } from "react-query";
import { postApi } from "services/apiCalls";
import Section from "components/Section/Section";
import DisplayButton from "components/DisplayButton/DisplayButton";

const ForecastAdjustmentTable = ({
  data,
  isTableLoading,
  editedRows,
  addItem,
  removeItem,
  gridApi,
  setGridApi,
}) => {
  const [decimal, setDecimal] = useState(2);

  const { values, setFieldValue } = useFormikContext();
  const { updateForecast } = usePutUpdatedForecast();

  const queryClient = useQueryClient();

  const update = () => {
    updateForecast({
      username: localStorage.getItem("username"),
      category: values.category,
      data: getData(editedRows, gridApi),
    });
  };

  const getData = (editedRows) => {
    const data = [];
    editedRows.forEach((id) => {
      const editedRow = gridApi.getRowNode(id).data;
      data.push({
        key: editedRow.Key_Match,
        forecastValue: editedRow.Planner_Update_Forecast_RPM,
      });
    });
    return data;
  };

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: outputColdef(decimal, values.mode),
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: async (params) => {
        const data = params.node.data;
        const path = "/forecastAdjustment/resultTable";
        const payload = {
          categoryName: values.filename,
          plannerForecast: parseFloat(data.Planner_Update_Forecast_RPM),
          actualForecast: data["Forecast at given level"],
          time: data["Time"],
          key: data["Key"],
          mode: values.mode,
        };
        try {
          const data = await queryClient
            .fetchQuery(["outputMacros", payload], () => postApi(path, payload))
            .finally(() => {});
          params.successCallback(data);
          console.log({ data });
        } catch (error) {
          console.log("err  ", error);
        }
      },
    };
  }, [decimal, queryClient, values.filename, values.mode]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} justifyContent="flex-end">
            <Grid item md={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={decimal}
                  label="Decimal"
                  onChange={(event) => setDecimal(event.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>

      <Grid item md={12}>
        <React.Fragment>
          {isTableLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {values.filename !== "" ? (
                <Section title={"Forecast Adjustment"}>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      Select below filters:
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={12} sx={{ mt: 1 }}>
                        <DisplayButton
                          name={"QTR BM National"}
                          active={values.mode === "4.QTR_BM_National"}
                          buttonOnclick={() => {
                            setFieldValue("mode", "4.QTR_BM_National");
                          }}
                        />
                        <DisplayButton
                          name={"Book Month Cluster"}
                          active={values.mode === "1.Book_Month_Cluster"}
                          buttonOnclick={() => {
                            setFieldValue("mode", "1.Book_Month_Cluster");
                          }}
                        />
                        <DisplayButton
                          name={"Book Month National"}
                          active={values.mode === "3.Book_Month_National"}
                          buttonOnclick={() => {
                            setFieldValue("mode", "3.Book_Month_National");
                          }}
                        />
                        <DisplayButton
                          name={"QTR BM Cluster"}
                          active={values.mode === "2.QTR_BM_Cluster"}
                          buttonOnclick={() => {
                            setFieldValue("mode", "2.QTR_BM_Cluster");
                          }}
                        />

                        <Button
                          variant="contained"
                          onClick={update}
                          style={{
                            height: "32px",
                            marginBottom: "2px",
                            marginRight: "10px",
                            float: "right",
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            editedRows.forEach((id) => {
                              gridApi.getRowNode(id).data[
                                "Planner_Update_Forecast_RPM"
                              ] = "";
                              removeItem(id);
                            });
                          }}
                          style={{
                            height: "32px",
                            marginBottom: "2px",
                            marginRight: "10px",
                            float: "right",
                          }}
                        >
                          Reset Table
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <SummaryTableWrapper
                    height={400}
                    gridApi={gridApi}
                    setGridApi={setGridApi}
                    columnDefs={colDef(decimal)}
                    rowData={data ?? []}
                    masterDetail={true}
                    enableRangeSelection={true}
          copyHeadersToClipboard={true}
                    detailCellRendererParams={detailCellRendererParams}
                    onCellValueChanged={(params) => {
                      if (params.newValue) {
                        addItem(params.node.id);
                      } else {
                        removeItem(params.node.id);
                      }
                    }}
                  />
                </Section>
              ) : (
                <Grid item md={12}>
                  <Section>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="body2" gutterBottom>
                        Please select the filename
                      </Typography>
                    </div>
                  </Section>
                </Grid>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

export default ForecastAdjustmentTable;
