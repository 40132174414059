import { Grid, Button, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useState, useEffect } from "react";
import Dialog from "components/Dialog";
import Autocomplete from "components/Autocomplete";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useDeletefiles from "services/DemandForecast/useDeletefiles";
import { useAccount } from "@azure/msal-react";

const DeleteSummaryFiles = ({
  open,
  setOpen,
  refetch,
  demandForecastUploadedFile = [],
  tag,
}) => {
  const [files, setFiles] = useState(null);

  const { mutate, isLoading, isSuccess } = useDeletefiles();

  const [activeStep, setActiveStep] = useState(0);
  const localAccountId = localStorage.getItem("localAccountId");
  const accountInfo = useAccount({ localAccountId });

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch, isLoading]);

  const steps = ["Select Files", "Preview", "Confirmation"];

  const getModalContent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <Autocomplete
            value={files ?? []}
            multiple
            onChange={(e, value) => {
              setFiles(value);
            }}
            limitTags={10}
            options={demandForecastUploadedFile?.map((f) => {
              return { label: f, value: f };
            })}
          />
        );
      case 1:
        return (
          <>
            <Typography variant="subtitle2" padding={(0, 2)}>
              List of files selected
            </Typography>
            <List dense={true}>
            {files && files.map((item, index) => (
                <ListItem key={`${item.label}${index}`}>
                  <ListItemIcon>
                    <TextSnippetRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </>
        );
      default:
        if (isLoading) {
          return (
            <Typography variant="body1" padding={(0, 2)}>
              Loading ...
            </Typography>
          );
        }
        return (
          <>
            {" "}
            {isSuccess ? (
              <Typography variant="body1" padding={(0, 2)}>
                File(s) Deleted Successfully
              </Typography>
            ) : (
              <Typography variant="body1" color={"error"} padding={(0, 2)}>
                Failed to delete the file(s)
              </Typography>
            )}
          </>
        );
    }
  };

  const getActions = (stage) => {
    switch (stage) {
      case 0:
        return (
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
          >
            Next
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              mutate({
                username: localStorage.getItem("username"),
                files: files.map((f) => f.value),
                tag: tag,
              });
              setActiveStep(activeStep + 1);
            }}
          >
            Delete
          </Button>
        );
      default:
        return (
          <Button
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              refetch();
              setOpen(false);
              setFiles([]);
              setActiveStep(0);
            }}
          >
            Close
          </Button>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        refetch();
        setOpen(false);
        setFiles([]);
        setActiveStep(0);
      }}
      header={
        <Typography variant="title" color={"primary"}>
          Delete Files
        </Typography>
      }
      maxWidth="sm"
      fullWidth={true}
      footer={
        <Grid container flex={1} justifyContent={"space-between"}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          {getActions(activeStep)}
        </Grid>
      }
    >
      <Grid
        container
        flexDirection={"column"}
        sx={{ paddingTop: 2 }}
        spacing={2}
      >
        <Grid item md={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item md={12}>
          {getModalContent(activeStep)}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteSummaryFiles;
