import React from "react";

import ReactECharts from "echarts-for-react";
import { color } from "views/DemandForecast/mappings";
import MultiSelectField from "components/form-fields/MultiSelectField";
import { Field, useFormikContext } from "formik";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import AutocompleteField from "components/form-fields/AutocompleteField";
import { Grid } from "@mui/material";

export default function OverAllFy({ optionData, term, decimals }) {
  const { values } = useFormikContext();

  const [cagrName, setCagrName] = React.useState([]);
  const xAxis = [];
  const yAxis = [];
  const legend = [];
  const growthLine = [];
  const data = optionData;
  // const names = ["2Year_CAGR", "3Year_CAGR", "4Year_CAGR"];
  const names = [
    { value: "2Year_CAGR", label: "2 year CAGR" },
    { value: "3Year_CAGR", label: "3 year CAGR" },
    { value: "4Year_CAGR", label: "4 year CAGR" },
  ];
  {
    data?.map((i) => {
      if (
        i.index !== "2023 SNOP" &&
        i.index !== "2022 SNOP" &&
        i.index !== "2023 Optimistic" &&
        i.index !== "2022 Optimistic"
      ) {
        xAxis.push(i.index);
        yAxis.push(i.FY);
        growthLine.push(i["FYLKL"]);
      }
    });
  }
  const series = [
    {
      name: "FY",
      data: yAxis,
      type: "bar",
      barGap: 1.3,
      color: color[8],
      width: "10px",
      label: {
        normal: {
          formatter: function (params) {
            var val = params.value.toFixed(decimals);
            return val;
          },
          show: true,
          position: "top",
        },
      },
    },
    {
      name: "Growth",
      data: growthLine,
      type: "line",
      color: color[6],
      yAxisIndex: 1,
    },
  ];
  const optionsfy = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value) => value.toFixed(decimals),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          name: term + "_" + "Summary-Fy",
        },
      },
    },

    legend: {},
    grid: {
      left: "3%",
      right: "4%",

      containLabel: true,
    },
    // label: {
    //   rotate: 90,
    // },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxis,
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#6E7079",
          },
        },
      },
    ],
    series: series,
  };
  const handleChange = (event) => {
    // const {
    //   target: { value },
    // } = event;
    setCagrName(
      // On autofill we get a stringified value.
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  values.cagrVolume?.forEach((value, index) => {
    const cagr = [];
    data.map((i) => {
      return cagr.push(i[value.value]);
    });

    legend.push(value.value);
    series.push({
      name: value.value,
      type: "line",
      data: cagr,
      color: color[index + 2],
      yAxisIndex: 1,
    });
  });
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {term === "Vol" && (
          <Grid item xs={3} sx={{ mt: 2 }}>
            <Field
              label="Select CAGR"
              multiple
              name="cagrVolume"
              options={names}
              component={AutocompleteField}
            />
          </Grid>
        )}
      </Grid>
      <ReactECharts
        style={{ height: "300px", width: "100%" }}
        option={optionsfy}
        // ref={(e) => {
        //   const instance = e?.getEchartsInstance();
        //   setchartInstance(instance);
        // }}
      />
    </React.Fragment>
  );
}
