import { NavBarWrapper, SideBar, Title } from "./NavBar.styles";
import React from "react";
import { Grid, Box, Stack, Button, Typography, Skeleton } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { checkValues } from "utils/constants";
import { Field, useFormikContext } from "formik";
import { useTheme } from "@emotion/react";

const NavBar = ({
  children,
  onSave,
  onReset,
  onApply,
  filterData,
  data,
  isLoading,
}) => {
  const [expanded, setExpanded] = React.useState("panel_-1");
  const { values, setFieldValue } = useFormikContext();

  const handleChangeExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);
  };

  const theme = useTheme();

  return (
    <NavBarWrapper>
      <SideBar>
        <Box sx={{ display: "flex", flexDirection: "column", margin: 2 }}>
          <Stack sx={{ flex: 1, marginBottom: 2 }}>
            <Grid container item flex={1}>
              <Button size="small" onClick={onApply} variant="contained">
                Apply
              </Button>
              &nbsp;
              {onSave && (
                <Button
                  size="small"
                  onClick={onSave}
                  variant="outlined"
                  sx={{
                    color: theme.palette.button.text,
                    borderColor: theme.palette.button.border,
                  }}
                >
                  Save
                </Button>
              )}
              &nbsp;
              <Button size="small" onClick={onReset} variant="contained">
                Reset
              </Button>
              &nbsp;
              <KeyboardDoubleArrowLeftIcon
                onClick={handleDrawerOpen}
                style={{
                  fontSize: "xx-large",
                  paddingLeft: "4px",
                  marginTop: "4px",
                  color: theme.palette.text.primary,
                  cursor: "pointer",
                  ...(onSave ? {} : { marginLeft: "70px" })
                }}
              />
            </Grid>
          </Stack>

          {children}

          {filterData.map((item, index) => (
            <Stack
              key={`${item.title}${index}`}
              sx={{ flex: 1, marginBottom: 2 }}
            >
              {!isLoading? 
              (<Accordion
                key={index}
                onChange={handleChangeExpanded(`panel_${index}`)}
                disableGutters={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    // color: expanded === `panel_${index}` ? "blue" : "#2036C7",
                    color: theme.palette.accordion.color,
                    fontWeight:
                      expanded === `panel_${index}` ? "normal" : "bold",
                    // backgroundColor: checkValues(item.filters, values)
                    //   ? "lightblue"
                    //   : "#383838",
                    backgroundColor: checkValues(item.filters, values)
                      ? theme.palette.accordion.backgroundColor.selected
                      : theme.palette.accordion.backgroundColor.default,
                  }}
                >
                  <Title>
                    <Grid container alignItems="center">
                      <item.icon sx={{ color: "#a16ef5" }} fontSize="small" />
                      &nbsp; &nbsp;
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {item.title}{" "}
                      </Typography>
                    </Grid>
                  </Title>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    color: expanded === `panel_${index}` ? "blue" : "#2036C7",
                    fontWeight:
                      expanded === `panel_${index}` ? "bold" : "normal",
                  }}
                >
                  {item.filters.map((filter, index) => (
                    <Field
                      name={`filters.${filter.name}`}
                      key={`${filter.name}${index}`}
                      label={filter.label}
                      variant="standard"
                      component={filter.component}
                      disableUnderline
                      options={(data && data[filter.name]) ?? []}
                      fullWidth
                      displayDropdown={isLoading}
                    />
                  ))}
                </AccordionDetails>
                {/* </Stack> */}
              </Accordion>) : 
              (<Skeleton height={50}/>)}
            </Stack>
          ))}
        </Box>
      </SideBar>
    </NavBarWrapper>
  );
};

export default NavBar;
