import { LeftSideNavbarWrapper, RightSideContainer, ViewportContainer } from 'components/AppLayout/AppLayout.styles'
import React,{useState} from 'react'
import Box from "@mui/material/Box";
import OutQuarter from './OutQuarter';
import { Formik ,useFormikContext} from "formik";
import { initialValues } from './formData';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import OutQuarterFilter from './OutQuarterFilter';

const Content=()=>{
  const [enableAPIs, setEnableAPIs] = useState(false);
  const { setValues, values, setFieldValue } = useFormikContext();
  const handleDrawerOpen = () => {
    setFieldValue("openDrawer", !values.openDrawer);

  };
  const onApply = () => {
    setEnableAPIs(!enableAPIs);
    values.clickApply = true
  };
  const onReset = () => {
    setValues(initialValues);
    window.sessionStorage.setItem(
      "outQuarterInitialValues",
      JSON.stringify(initialValues)
    );
    setEnableAPIs(!enableAPIs);
    setFieldValue("filterData",[])
  };
  const onSave = () => {
    window.sessionStorage.setItem("outQuarterInitialValues", JSON.stringify(values));
  };
  return(
    <ViewportContainer>
      {values.openDrawer === true?(
        <LeftSideNavbarWrapper>
          <OutQuarterFilter
           onApply={onApply}
           onReset={onReset}
           onSave={onSave}
           enableAPIs={enableAPIs}
           setEnableAPIs={setEnableAPIs}
          />
        </LeftSideNavbarWrapper>
      ):(
        <KeyboardDoubleArrowRightIcon
        onClick={handleDrawerOpen}
        style={{
          fontSize: "xx-large",
          paddingLeft: "4px",
          marginTop: "4px",
          color: "blue",
          cursor:"pointer",
        }}
      />
      )}
       <RightSideContainer>
        <Box sx={{ margin: 3 }}>
         <OutQuarter enableAPIs={enableAPIs}/>
        </Box>
      </RightSideContainer>
    </ViewportContainer>
  )
}
export default function OutQuarterDashboard() {
  const initialFormValues = window.sessionStorage.getItem("outQuarterInitialValues")
    ? JSON.parse(window.sessionStorage.getItem("outQuarterInitialValues"))
    : initialValues;
  return (
    <Formik initialValues={initialFormValues}>
    <Content/>
    </Formik>
  )
}
